import React from 'react'
import { useNavigate } from 'react-router-dom'
import UserWrap from '../../components/UserWrap/UserWrap'


const Exchange  = () => {

  const navigate = useNavigate()

  return (
      <main className="main exchange">
        <div className="exchange__order">
          <h2 className="exchange__order-title">Exchange Order</h2>
          <form action="" className="exchange__order-form form">
            <div className="order-form__body">
              <div className="order-form__block">
                <h3 className="order-form__title">General Information</h3>
                <div className="order-form__items">
                  <div className="order-form__item form__item">
                    <div className="order-form__input form__input input">
                      <input type="text" name="" value="" placeholder="Country" className="input__control"/>
                    </div>
                  </div>
                  <div className="order-form__item form__item">
                    <div className="order-form__input form__input input">
                      <input type="text" name="" value="" placeholder="Traders & Companies" className="input__control"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-form__block">
                <h3 className="order-form__title">Token Information</h3>
                <div className="order-form__items">
                  <div className="order-form__item form__item">
                    <button type="button" className="form__control form-control ">
                      <p className="form-control__text">Asset</p>
                      <svg className="form-control__arrow">
                        <use xlinkHref="img/sprite.svg#arrow"></use>
                      </svg>
                    </button>
                  </div>
                  <div className="order-form__item form__item">
                    <button type="button" className="form__control form-control ">
                      <p className="form-control__text">Network</p>
                      <svg className="form-control__arrow">
                        <use xlinkHref="img/sprite.svg#arrow"></use>
                      </svg>
                    </button>
                  </div>
                  <div className="order-form__item form__item">
                    <div className="order-form__input form__input input">
                      <input type="text" name="" value="" placeholder="Amount" className="input__control"/>
                    </div>
                    <span className="form__item-text-bottom">Balance: 1 000 000 BRT</span>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" className="order-form__button button">Create Order</button>
          </form>
        </div>
      </main>
  )
}

export default Exchange 