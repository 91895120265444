/* eslint-disable jsx-a11y/anchor-is-valid */
const HeroContent = () => {
  return (
    <div className="hero__body">
      <div className="hero__logo">
        <img src="./img/logo.svg" alt="logo" />
      </div>
      <div className="hero__content">
        <h1 className="hero__title">
          <span className="hero__title-amount">237,453,783</span> users trust us
        </h1>
        <p className="hero__text">
          Join this project to discover a unique and creative way to make money.
          As a club member, you have full control over how quickly and how much
          you want to earn. Meanwhile, we’re committed to continuously enhancing
          the project to provide you with even greater opportunities for
          success.
        </p>
      </div>
      <div className="hero__links">
        <a
          href="https://www.binance.com/en"
          target="_blank"
          rel="noreferrer"
          className="hero__link"
        >
          <img src="./img/binance.svg" alt="binance" />
        </a>
        <a
          href="https://coinmarketcap.com/"
          target="_blank"
          rel="noreferrer"
          className="hero__link"
        >
          <img src="./img/coinmarket.svg" alt="coinmarket" />
        </a>
        <a
          href="https://app.uniswap.org/"
          target="_blank"
          rel="noreferrer"
          className="hero__link"
        >
          <img src="./img/uniswap.svg" alt="uniswap" />
        </a>
      </div>
    </div>
  );
};

export default HeroContent;
