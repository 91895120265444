/* eslint-disable array-callback-return */
import parsePhoneNumberFromString from "libphonenumber-js";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { IInputProp } from "./input.types";
import phone_mask_list from "./phone_mask_list.json";
import { toast } from "react-toastify";

const Input: FC<IInputProp> = ({
  input,
  setHideInput,
  setInputValue,
  setPhoneCode,
  setInputError,
  checkPasswordsEquals,
  setActionValue,
}) => {
  const [isDropdownOpened, setIsDropDownOpened] = useState(false);
  const [maxDate, setMaxDate] = useState("");
  const [placeholder, setPlaceHolder] = useState("*** ** **");
  const [phoneAutoSelect, setPhoneAutoSelect] = useState(false);
  const [phoneCodes, setPhoneCodes] = useState<string[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const closeDropdown = () => setIsDropDownOpened(false);

  const dropdownRef = useClickOutside(closeDropdown);

  const openCalnedar = () => {
    if (inputRef.current) {
      if (typeof inputRef.current.showPicker === "function") {
        inputRef.current.showPicker();
      } else {
        inputRef.current.focus();
      }
    }
  };

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const setMask = (e: ChangeEvent<HTMLInputElement>) => {
    if (input.type === "phone") {
      if (e.target.value.startsWith("+")) {
        setPhoneAutoSelect(true);
        let matrix = "+###############";

        phone_mask_list.forEach((item) => {
          let code = item.code.replace(/[\s#]/g, ""),
            phone = e.target.value.replace(/[\s#-)(]/g, "");

          if (phone.includes(code)) {
            console.log(phone, code);
            matrix = item.code;
          }
        });

        let i = 0,
          val = e.target.value.replace(/\D/g, "");

        setInputValue(
          input.id,
          matrix.replace(/(?!\+)./g, function (a) {
            return /[#\d]/.test(a) && i < val.length
              ? val.charAt(i++)
              : i >= val.length
              ? ""
              : a;
          })
        );
      } else {
        setPhoneAutoSelect(false);
        let matrix = "###############";

        phone_mask_list.forEach((item) => {
          let code = item.code.replace(/[\s#]/g, ""),
            phone = e.target.value.replace(/[\s#-)(]/g, "");

          if (input.phoneCode === code) {
            console.log(phone, code);
            matrix = item.code.replace(code, "");
            setPlaceHolder(item.code.replace(code, "").replace(/#/g, "*"));
          }
        });

        let i = 0,
          val = e.target.value.replace(/\D/g, "");
        setInputValue(
          input.id,
          matrix.replace(/(?!\+)./g, function (a) {
            return /[#\d]/.test(a) && i < val.length
              ? val.charAt(i++)
              : i >= val.length
              ? ""
              : a;
          })
        );
      }
    }
  };

  const onPhoneCodeDropdown = (v: string) => {
    if (input.type === "phone") {
      setPhoneCode && setPhoneCode(input.id, v);
      setIsDropDownOpened(false);
      const mask = {
        ...phone_mask_list.find((e) => e.code.replace(/[\s#]/g, "") === v),
      };
      if (mask && mask.code) {
        setPlaceHolder(
          mask.code.replace(/\+\d[\d\s]*/g, "").replace(/#/g, "*")
        );
      }
    }
  };

  useEffect(() => {
    const currentDate = new Date();

    const maxDatee = new Date(
      currentDate.setFullYear(currentDate.getFullYear() - 18)
    );

    setMaxDate(maxDatee.toISOString().split("T")[0]);
    setPhoneCodes(
      phone_mask_list.sort().map((v) => v.code.replace(/[\s#]/g, ""))
    );
  }, []);

  const onInputBlur = (e: ChangeEvent<HTMLInputElement>) => {
    switch (input.type) {
      case "text":
        if (e.target.value === "") {
          setInputError && setInputError(input.id, "Can't be empty");
          break;
        } else {
          setInputError && setInputError(input.id, "");
        }

        if (input.regex) {
          if (input.regex.test(e.target.value)) {
            setInputError && setInputError(input.id, "");
          } else {
            setInputError && setInputError(input.id, "Invalid value");
          }
        }
        break;
      case "password":
        if (e.target.value === "") {
          setInputError && setInputError(input.id, "Can't be empty");
          break;
        } else {
          setInputError && setInputError(input.id, "");
        }

        if (input.repeat) {
          if (checkPasswordsEquals && checkPasswordsEquals()) {
            setInputError && setInputError(input.id, "");
          } else {
            setInputError && setInputError(input.id, "Passwords're not equals");
          }
        }
        break;
      case "date":
        if (e.target.value === "") {
          setInputError && setInputError(input.id, "Can't be empty");
          break;
        } else {
          setInputError && setInputError(input.id, "");
        }
        break;
      case "phone":
        if (e.target.value === "") {
          setInputError && setInputError(input.id, "Can't be empty");
          break;
        } else {
          setInputError && setInputError(input.id, "");
        }

        let number = "";

        if (phoneAutoSelect) {
          number = e.target.value;
        } else {
          number = input.phoneCode + e.target.value;
        }
        const parsedNumber = parsePhoneNumberFromString(number);
        if (parsedNumber && parsedNumber.isValid()) {
          setInputError && setInputError(input.id, "");
        } else {
          setInputError && setInputError(input.id, "Invalid number");
        }
        break;
      case "multitext":
        console.log(e.target.getAttribute("multitext-id"));
        input.texts.map((v) => {
          if (v.id === e.target.getAttribute("multitext-id")) {
            if (v.value === "") {
              setInputError && setInputError(v.id, "Can't be empty");
              return;
            } else {
              setInputError && setInputError(v.id, "");
            }

            if (v.regex) {
              if (v.regex.test(e.target.value)) {
                setInputError && setInputError(v.id, "");
              } else {
                setInputError && setInputError(v.id, "Invalid value");
              }
            }
          }
        });
        break;
    }
  };

  return (
    <>
      {input.type !== "dropdown" &&
        input.type !== "phone" &&
        input.type !== "multitext" &&
        input.type !== "checkbox" &&
        input.type !== "settingsText" &&
        input.type !== "settingsDropdown" &&
        input.type !== "copy" && (
          <div
            className={`register__form-item ${input.error && "error"}`}
            style={{
              marginBottom: input.error ? "6px" : "0px",
            }}
          >
            <span className="error-text">{input.error}</span>
            <div className="form__input input">
              {input.svg && input.svg !== "exchange" && (
                <svg className={`input__icon input__icon--${input.svg}`}>
                  <use xlinkHref={`img/sprite.svg#${input.svg}`}></use>
                </svg>
              )}
              <input
                ref={inputRef}
                type={
                  input.type === "password" && !input.show
                    ? "password"
                    : input.type === "date"
                    ? "date"
                    : "text"
                }
                value={input.value}
                step={input.type === "number" ? 0.0000001 : undefined}
                onChange={(e) => setInputValue(input.id, e.target.value)}
                onBlur={onInputBlur}
                placeholder={input.placeholder}
                className="input__control"
                max={input.type === "date" ? maxDate : undefined}
              />
              {input.svg && input.svg === "exchange" && (
                <svg className={`input__icon input__icon--${input.svg}`}>
                  <use xlinkHref={`img/sprite.svg#${input.svg}`}></use>
                </svg>
              )}
              {input.type === "right-button"
                ? input.rightButton && input.rightButton
                : null}
              {input.type === "password" && (
                <button
                  onClick={() =>
                    setHideInput && setHideInput(input.id, !input.show)
                  }
                  type="button"
                  className="input__icon-btn"
                >
                  <svg
                    style={{ display: input.show ? "block" : "none" }}
                    className="input__icon input__icon--eye"
                  >
                    <use xlinkHref="img/sprite.svg#eye"></use>
                  </svg>
                  <svg
                    style={{ display: input.show ? "none" : "block" }}
                    className="input__icon input__icon--eye-off"
                  >
                    <use xlinkHref="img/sprite.svg#eye-off"></use>
                  </svg>
                </button>
              )}

              {input.type === "date" && (
                <button
                  onClick={openCalnedar}
                  type="button"
                  className="input__icon-btn"
                >
                  <svg className="input__icon input__icon--calendar">
                    <use xlinkHref="img/sprite.svg#calendar"></use>
                  </svg>
                </button>
              )}
            </div>
          </div>
        )}

      {input.type === "dropdown" && (
        <div
          ref={dropdownRef}
          className={`register__form-item ${input.error && "error"}`}
          style={{
            marginBottom: input.error ? "6px" : "0px",
          }}
        >
          <span className="error-text">{input.error}</span>
          <button
            onClick={() => setIsDropDownOpened((prev) => !prev)}
            type="button"
            className="form-control "
          >
            {input.svg && (
              <svg className={`input__icon input__icon--${input.svg}`}>
                <use xlinkHref={`img/sprite.svg#${input.svg}`}></use>
              </svg>
            )}

            <p className="form-control__text">
              {input.value ? input.value : input.placeholder}
            </p>

            <svg className="form-control__arrow">
              <use xlinkHref="img/sprite.svg#arrow"></use>
            </svg>
          </button>
          <ul
            style={{ overflowY: "auto", height: "11.25rem" }}
            className={`dropdown-menu ${isDropdownOpened && "open"}`}
            id="phone-dropdown"
          >
            {input.values.map((v, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setInputValue(input.id, v);
                    setIsDropDownOpened(false);
                    setInputError && setInputError(input.id, "");
                  }}
                  className="dropdown-menu__item"
                >
                  {v}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {input.type === "phone" && (
        <div
          ref={dropdownRef}
          className={`register__form-item ${input.error && "error"}`}
          style={{
            marginBottom: input.error ? "6px" : "0px",
          }}
        >
          <span className="error-text">{input.error}</span>
          <div className="form-control">
            <svg className="input__icon">
              <use xlinkHref="img/sprite.svg#phone"></use>
            </svg>
            <p className="form-control__text form-control__text--phone">
              {!phoneAutoSelect && (
                <span
                  onClick={() => setIsDropDownOpened((prev) => !prev)}
                  className="country-code"
                >
                  {input.phoneCode}{" "}
                </span>
              )}
              <input
                onBlur={onInputBlur}
                onChange={setMask}
                value={input.value}
                type="tel"
                placeholder={placeholder}
                className="form-control__phone"
              />
              {!phoneAutoSelect && (
                <button
                  onClick={() => setIsDropDownOpened((prev) => !prev)}
                  type="button"
                  className="form-control__arrow-btn"
                >
                  <svg className="form-control__arrow">
                    <use xlinkHref="img/sprite.svg#arrow"></use>
                  </svg>
                </button>
              )}
            </p>
          </div>
          <ul
            style={{ overflowY: "auto", height: "11.25rem" }}
            className={`dropdown-menu ${isDropdownOpened && "open"}`}
            id="phone-dropdown"
          >
            {phoneCodes.map((v) => (
              <li
                onClick={() => onPhoneCodeDropdown(v)}
                className="dropdown-menu__item"
              >
                {v}
              </li>
            ))}
          </ul>
        </div>
      )}

      {input.type === "multitext" && (
        <div className="register__form-row">
          {input.texts.map((v) => (
            <div
              className={`register__form-item ${
                v.error && "error"
              } register__form-item--${v.id}`}
            >
              <span className="error-text">{v.error}</span>
              <div className="form__input input">
                <input
                  multitext-id={v.id}
                  onBlur={onInputBlur}
                  onChange={(e) => setInputValue(v.id, e.target.value)}
                  type="text"
                  name="text"
                  value={v.value}
                  placeholder={v.placeholder}
                  className="input__control"
                  style={{
                    marginBottom: v.error ? "6px" : "0px",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {input.type === "checkbox" && (
        <div className="register__checkbox checkbox">
          <input
            id={`formAgreement-${input.id}`}
            type="checkbox"
            checked={input.value}
            onChange={(e) => e.preventDefault()} // Prevent default behavior of the checkbox toggle
            className="checkbox__input"
          />
          <label
            htmlFor={`formAgreement-${input.id}`}
            className="checkbox__label"
            onClick={(e) => {
              const target = e.target as HTMLElement;

              if (target.tagName === "A") {
                e.stopPropagation();
              } else {
                setActionValue && setActionValue(input.id, !input.value);
              }
            }}
          >
            <p className="checkbox__label-text">{input.text}</p>
          </label>
        </div>
      )}

      {input.type === "settingsText" && (
        <div className={`register__form-item ${input.error && "error"}`}>
          <span className="error-text">{input.error}</span>
          <input
            ref={inputRef}
            type={"text"}
            value={input.value}
            onChange={(e) => setInputValue(input.id, e.target.value)}
            onBlur={onInputBlur}
            placeholder={input.placeholder}
            className="form-settings__input"
            style={{
              marginBottom: input.error ? "6px" : "0px",
            }}
          />
        </div>
      )}

      {input.type === "copy" && (
        <div className="register__form-item">
          <div className="form__input input">
            <input
              ref={inputRef}
              type="text"
              value={input.value}
              onBlur={onInputBlur}
              placeholder={input.placeholder}
              className="input__control"
            />
            <button
              type="button"
              className="code__address-copy"
              onClick={() => copyToClipboard(input.value)}
            >
              <svg className="code__address-copy-icon">
                <use xlinkHref="img/sprite.svg#copy"></use>
              </svg>
            </button>
          </div>
        </div>
      )}

      {input.type === "settingsDropdown" && (
        <div
          ref={dropdownRef}
          className={`register__form-item ${input.error && "error"}`}
        >
          <span className="error-text">{input.error}</span>
          <button
            onClick={() => setIsDropDownOpened((prev) => !prev)}
            type="button"
            className="form-control form-settings__input"
            style={{
              marginBottom: input.error ? "6px" : "0px",
            }}
          >
            <p className="form-control__text">
              {input.value ? input.value : input.placeholder}
            </p>

            <svg className="form-settings__input-icon form-settings__input-icon--arrow">
              <use xlinkHref="img/sprite.svg#arrow"></use>
            </svg>
          </button>
          <ul
            style={{ overflowY: "auto", height: "11.25rem" }}
            className={`dropdown-menu form-settings__input ${
              isDropdownOpened && "open"
            }`}
            id="phone-dropdown"
          >
            {input.values.map((v) => {
              return (
                <li
                  onClick={() => {
                    setInputValue(input.id, v);
                    setIsDropDownOpened(false);
                    setInputError && setInputError(input.id, "");
                  }}
                  className="dropdown-menu__item"
                >
                  {v}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default Input;
