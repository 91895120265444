import { FC, useState, useEffect } from "react";
import * as yup from "yup";
import {
  useDeleteCard,
  useGetBillingInfo,
  useGetPaymentMethods,
  useNewCard,
  useUpdateBillingInfo,
} from "../../../services/settings.services";
import NewCard from "../../../components/Modal/NewCard";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IBillingInfoForm, INewCardForm } from "../../../types/types";
import { toast } from "react-toastify";
import Modal from "../../../components/Modal";
import DeleteCardConfirmation from "../../../components/Modal/DeleteCardConfirmation";

const NewCardFormSchema = yup.object().shape({
  cardholder: yup.string().required("Cardholder is a required field!"),
  card_number: yup.string().required("Card number is a required field!"),
  exp_date: yup.string().required("Expiration date is a required field!"),
  cvv: yup.string().required("CVV is a required field!"),
});

const UpdateBillingInfoFormSchema = yup.object().shape({
  full_name: yup.string().required("Full name is a required field!"),
  address: yup.string().required("Address is a required field!"),
  state: yup.string().required("State is a required field!"),
  zip_code: yup.string().required("Zip Code is a required field!"),
});

const getCardType = (cardNumber: string) => {
  if (/^4/.test(cardNumber)) {
    return "visa";
  } else if (/^5[1-5]/.test(cardNumber)) {
    return "mastercard";
  } else if (/^3[47]/.test(cardNumber)) {
    return "american";
  }
  return "credit-card";
};

const Billing: FC = () => {
  const [isDeleteCardModalOpen, setIseDeleteCardModalOpen] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  const { paymentMethods, refetch } = useGetPaymentMethods();
  const { billingInfo } = useGetBillingInfo();

  const { mutate: newCard } = useNewCard(
    () => {
      closeModal();
      reset();
      refetch();
      toast.success("Card was successfully added!");
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const { mutate: deleteCard } = useDeleteCard(
    () => {
      refetch();
      toast.success("Card was successfully deleted!");
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const { mutate: updateBillingInfo, isPending } = useUpdateBillingInfo(
    () => {
      toast.success("Billing information was successfully updated!");
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const useReturnNewCardForm = useForm<INewCardForm>({
    resolver: yupResolver(NewCardFormSchema),
  });

  const useReturnUpdateBillingInfoForm = useForm<IBillingInfoForm>({
    resolver: yupResolver(UpdateBillingInfoFormSchema),
  });

  const {
    handleSubmit,
    control,
    reset: resetBillingInfoForm,
    formState: { isDirty },
  } = useReturnUpdateBillingInfoForm;

  const { reset } = useReturnNewCardForm;

  useEffect(() => {
    if (billingInfo) {
      resetBillingInfoForm({
        full_name: billingInfo.full_name,
        address: billingInfo.address,
        state: billingInfo.state,
        zip_code: billingInfo.zip_code,
      });
    }
  }, [billingInfo, resetBillingInfoForm]);

  const handleSubmitNewCardForm = (data: INewCardForm) => {
    newCard(data);
  };

  const handleSubmitUpdateBillingInfoForm = (data: IBillingInfoForm) => {
    updateBillingInfo(data);
  };

  const closeModal = () => {
    setIsOpen(false);
    reset();
  };

  const handleDeleteCard = (id: number) => {
    setDeleteCardId(id);
    setIseDeleteCardModalOpen(true);
  };

  const handleCancelDeleteCard = () => {
    setIseDeleteCardModalOpen(false);
    setDeleteCardId(undefined);
  };

  const handleDeleteCardConfirm = () => {
    deleteCard(deleteCardId!);
    setIseDeleteCardModalOpen(false);
  };

  const handleCardSelect = (id: number) => {
    setSelectedCardId(id);
  };

  return (
    <div className="settings__body">
      <div className="settings__card">
        <div className="settings__header">
          <h2 className="settings__title">Payment methods</h2>
          <div className="settings__subtitle">
            You can manage your saved payment methods here. Add a new card or
            remove an existing one.
          </div>
        </div>
        <form
          onSubmit={handleSubmit(handleSubmitUpdateBillingInfoForm)}
          className="settings__form form-settings"
        >
          <div className="form-settings__cards">
            <div className="form-settings__card options">
              {paymentMethods?.length === 0 && (
                <div className="form-settings__card-empty">
                  <p>
                    No saved payment methods. Add a card to make future payments
                    easier.
                  </p>
                </div>
              )}
              {paymentMethods &&
                paymentMethods?.length > 0 &&
                paymentMethods?.map((item: any) => (
                  <div
                    key={item?.id}
                    className={`options__row ${
                      selectedCardId === item.id ? "options__row--selected" : ""
                    }`}
                  >
                    <input
                      id={`card_${item.id}`}
                      type="radio"
                      name="card"
                      className="options__input"
                      checked={selectedCardId === item.id}
                      onChange={() => handleCardSelect(item.id)}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor={`card_${item.id}`}
                      className="options__label"
                    >
                      <div className="options__label-card">
                        <div className="options__label-card-img">
                          <img
                            className="options__label-card-logo"
                            src={`./img/card/${getCardType(
                              item?.card_number
                            )}.${
                              getCardType(item?.card_number) === "credit-card"
                                ? "png"
                                : "svg"
                            }`}
                            alt="card-logo"
                          />
                        </div>
                        <div className="options__label-card-type">
                          <span className="options__label-card-num">
                            {getCardType(item?.card_number) === "american"
                              ? "American Express"
                              : getCardType(item?.card_number) === "visa"
                              ? "Visa"
                              : getCardType(item?.card_number) === "mastercard"
                              ? "Mastercard"
                              : "Credit Card"}{" "}
                            **** {item?.card_number.slice(-4)}
                          </span>
                          <span className="options__label-card-date">
                            {" "}
                            Expires on {item?.exp_date}
                          </span>
                        </div>
                      </div>
                    </label>
                    <button
                      onClick={() => handleDeleteCard(item.id)}
                      type="button"
                      className="options__del"
                    >
                      <svg className="options__del-icon">
                        <use xlinkHref="img/sprite.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                ))}
            </div>
            <button
              onClick={() => setIsOpen(true)}
              type="button"
              className="form-settings__cards-add"
            >
              <img
                className="form-settings__cards-add-icon"
                src="./img/add.png"
                alt="add"
              />
              <span className="form-settings__cards-add-text">
                Add a new payment method
              </span>
            </button>
          </div>

          <div className="settings__header">
            <h2 className="settings__title">Basic information</h2>
            <div className="settings__subtitle">
              Update your billing information to ensure seamless transactions.
            </div>
          </div>

          <Controller
            name="full_name"
            control={control}
            render={({ field }) => (
              <div className="form-settings__item">
                <div className="form-settings__label">
                  <svg className="form-settings__label-icon">
                    <use xlinkHref="img/sprite.svg#user"></use>
                  </svg>
                  <span className="form-settings__label-text">Full name</span>
                </div>
                <div className="form-settings__input-wrapper">
                  <input
                    {...field}
                    type="text"
                    className="form-settings__input"
                    placeholder="John Carter"
                    maxLength={50}
                  />
                </div>
              </div>
            )}
          />

          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <div className="form-settings__item">
                <div className="form-settings__label">
                  <svg className="form-settings__label-icon">
                    <use xlinkHref="img/sprite.svg#dashboard"></use>
                  </svg>
                  <span className="form-settings__label-text">Address</span>
                </div>
                <div className="form-settings__input-wrapper">
                  <input
                    {...field}
                    type="text"
                    className="form-settings__input"
                    placeholder="601 4th St #103"
                    maxLength={100}
                  />
                </div>
              </div>
            )}
          />

          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <div className="form-settings__item">
                <div className="form-settings__label">
                  <svg className="form-settings__label-icon">
                    <use xlinkHref="img/sprite.svg#address"></use>
                  </svg>
                  <span className="form-settings__label-text">State</span>
                </div>
                <div className="form-settings__input-wrapper">
                  <input
                    {...field}
                    type="text"
                    className="form-settings__input"
                    placeholder="Los Angeles"
                    maxLength={100}
                  />
                </div>
              </div>
            )}
          />

          <Controller
            name="zip_code"
            control={control}
            render={({ field }) => (
              <div className="form-settings__item">
                <div className="form-settings__label">
                  <svg className="form-settings__label-icon">
                    <use xlinkHref="img/sprite.svg#email"></use>
                  </svg>
                  <span className="form-settings__label-text">Zip code</span>
                </div>
                <div className="form-settings__input-wrapper">
                  <input
                    {...field}
                    type="text"
                    className="form-settings__input"
                    placeholder="90001"
                    maxLength={10}
                  />
                </div>
              </div>
            )}
          />

          <button
            type="submit"
            className="form-settings__button button"
            disabled={!isDirty || isPending}
          >
            {isPending ? <div className="spinner"></div> : "Save"}
          </button>
        </form>
      </div>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Add Card">
        <NewCard
          returnFormInstance={useReturnNewCardForm}
          onSubmit={handleSubmitNewCardForm}
        />
      </Modal>

      <Modal
        isOpen={isDeleteCardModalOpen}
        setIsOpen={setIseDeleteCardModalOpen}
        title="Delete Card"
      >
        <DeleteCardConfirmation
          handleCancel={handleCancelDeleteCard}
          handleConfirm={handleDeleteCardConfirm}
        />
      </Modal>
    </div>
  );
};

export default Billing;
