import React, { useReducer, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import Input from "../../components/Input/Input";
import { IInput } from "../../components/Input/input.types";
import useDebounce from "../../hooks/useDebounce";
import { IBalance, IBalances } from "../../types/types";
import {
  useGetSwapAvailableAssets,
  useGetSwapTokensInfo,
  useSwapTokens,
} from "../../services/swap.services";
import { useGetBalances } from "../../services/dashboard.services";
import moment from "moment";

interface Inputs {
  input: IInput;
  group?: string;
}

interface State {
  step: number;
  inputs: Inputs[];
  assetPayValue: string;
  assetGetValue: string;
  networkPayValue: string;
  curValues: { [index: string]: string | boolean };
  fee: number | null;
  minimalOrderAmount: number | null;
  amountGet: string;
}

type Action =
  | { type: "SET_STEP"; payload: number }
  | { type: "SET_INPUTS"; payload: Inputs[] }
  | { type: "SET_ASSET_PAY_VALUE"; payload: string }
  | { type: "SET_ASSET_GET_VALUE"; payload: string }
  | { type: "SET_NETWORK_PAY_VALUE"; payload: string }
  | { type: "SET_CUR_VALUES"; payload: { [index: string]: string | boolean } }
  | { type: "SET_FEE"; payload: number | null }
  | { type: "SET_MINIMAL_ORDER_AMOUNT"; payload: number | null }
  | { type: "SET_AMOUNT_GET"; payload: string };

const initialInputsState = [
  {
    input: {
      type: "dropdown",
      id: "networkPay",
      svg: "",
      value: "",
      values: [],
      placeholder: "Network",
      error: undefined,
    },
    group: "YouPay",
  },
  {
    input: {
      type: "dropdown",
      id: "assetPay",
      svg: "",
      value: "",
      values: [],
      placeholder: "Asset",
      error: undefined,
    },
    group: "YouPay",
  },
  {
    input: {
      type: "number",
      id: "amountPay",
      svg: "",
      value: "",
      placeholder: "Amount",
      regex: /^\d+$/,
      error: undefined,
    },
    group: "YouPay",
  },
  {
    input: {
      type: "dropdown",
      id: "networkGet",
      svg: "",
      value: "",
      values: [],
      placeholder: "Network",
      error: undefined,
    },
  },
  {
    input: {
      type: "dropdown",
      id: "assetGet",
      svg: "",
      value: "",
      values: [],
      placeholder: "Asset",
      error: undefined,
    },
  },
];

const initialState: State = {
  step: 1,
  inputs: initialInputsState as any,
  assetPayValue: "",
  assetGetValue: "",
  networkPayValue: "",
  curValues: {},
  fee: null,
  minimalOrderAmount: null,
  amountGet: "",
};

function getBalance(data: IBalances, asset: string, network: string) {
  const assetLower = asset.toLowerCase();
  const networkLower = network.toLowerCase();

  if (!data.hasOwnProperty(assetLower) || data[assetLower] === null) {
    return 0;
  }

  if (Array.isArray(data[assetLower])) {
    const networkData = data[assetLower].find(
      (n) => n.symbol.toLowerCase() === networkLower
    );
    return networkData ? networkData.balance : 0;
  }

  return 0;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_STEP":
      return { ...state, step: action.payload };
    case "SET_INPUTS":
      return { ...state, inputs: action.payload };
    case "SET_ASSET_PAY_VALUE":
      return { ...state, assetPayValue: action.payload };
    case "SET_ASSET_GET_VALUE":
      return { ...state, assetGetValue: action.payload };
    case "SET_NETWORK_PAY_VALUE":
      return { ...state, networkPayValue: action.payload };
    case "SET_CUR_VALUES":
      return { ...state, curValues: action.payload };
    case "SET_FEE":
      return { ...state, fee: action.payload };
    case "SET_MINIMAL_ORDER_AMOUNT":
      return { ...state, minimalOrderAmount: action.payload };
    case "SET_AMOUNT_GET":
      return { ...state, amountGet: action.payload };
    default:
      return state;
  }
}

const Swap = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { availableAssets } = useGetSwapAvailableAssets();
  const { balances } = useGetBalances();
  const { mutate: swapTokens, isPending } = useSwapTokens(
    () => {
      toast.success("Success");
      dispatch({ type: "SET_STEP", payload: 3 });
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const prevCurValues = useRef<{ [index: string]: string | boolean } | null>(
    null
  );

  const { swapInfo, refetch } = useGetSwapTokensInfo(state.curValues);

  const debouncedInputs = useDebounce(state.inputs, 1000);

  const handleConfirmSwap = useCallback(async () => {
    if (state.step === 2) {
      swapTokens({
        from_asset: state.curValues.assetPay as string,
        from_network: state.curValues.networkPay as string,
        amount: +state.curValues.amountPay,
        to_asset: state.curValues.assetGet as string,
        to_network: state.curValues.networkGet as string,
      });
    }
  }, [state.curValues, state.step, swapTokens]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (state.step === 1) {
        let hasErrors = false;
        const newInputs = state.inputs.map((v) => {
          if (v.input.type !== "multitext") {
            if (v.input.value === "") {
              v.input.error = "Can't be empty";
              hasErrors = true;
            }
            if (v.input.error) {
              hasErrors = true;
            }
          }
          return v;
        });

        dispatch({ type: "SET_INPUTS", payload: newInputs });

        if (!hasErrors) {
          const amountPay = +state.curValues.amountPay;
          const fee = state.fee || 0;
          const totalAmount = amountPay + fee;

          const balance = getBalance(
            balances!,
            state.networkPayValue,
            state.assetPayValue
          );

          if (totalAmount > balance) {
            toast.error("Insufficient balance to cover the amount and fee");
            return;
          }

          dispatch({ type: "SET_STEP", payload: 2 });
        }
      }
    },
    [
      state.inputs,
      state.curValues,
      state.step,
      state.fee,
      balances,
      state.networkPayValue,
      state.assetPayValue,
    ]
  );

  const setInputValues = useCallback(
    (input_id: string, values: string[]) => {
      dispatch({
        type: "SET_INPUTS",
        payload: state.inputs.map((v) => {
          if (v.input.type === "dropdown" && v.input.id === input_id) {
            v.input.values = values;
          }
          return v;
        }),
      });
    },
    [state.inputs]
  );

  const setInputValue = useCallback(
    (input_id: string, value: string | boolean) => {
      dispatch({
        type: "SET_INPUTS",
        payload: state.inputs.map((v) => {
          if (v.input.type !== "multitext" && v.input.id === input_id) {
            v.input.value = value;
          }
          return v;
        }),
      });

      if (input_id === "networkPay") {
        if (typeof value === "string") {
          dispatch({ type: "SET_NETWORK_PAY_VALUE", payload: value });

          // Reset asset pay and get values
          setInputValue("assetPay", "");
          setInputValue("assetGet", "");
          dispatch({ type: "SET_ASSET_PAY_VALUE", payload: "" });
          dispatch({ type: "SET_ASSET_GET_VALUE", payload: "" });

          // Reset network get value
          setInputValue("networkGet", "");

          // Update available assets for pay
          if (availableAssets && availableAssets[value]) {
            const assets = availableAssets[value].map(
              (item: IBalance) => item.symbol
            );
            setInputValues("assetPay", assets);
          }
        }
      }

      if (input_id === "assetPay") {
        if (typeof value === "string") {
          dispatch({ type: "SET_ASSET_PAY_VALUE", payload: value });

          // Reset asset get value
          setInputValue("assetGet", "");
          dispatch({ type: "SET_ASSET_GET_VALUE", payload: "" });

          // Reset network get value
          setInputValue("networkGet", "");

          if (value === "BTC") {
            // If BTC is selected in You Pay, no options should be available in You Get
            setInputValues("networkGet", []);
            setInputValues("assetGet", []);
          } else if (value === "BRT") {
            // BRT can only be swapped to USDT
            setInputValues("networkGet", ["TRC", "BSC"]);
            setInputValues("assetGet", ["USDT"]);
          } else {
            // All other assets can be swapped to any available asset except itself
            if (availableAssets) {
              const networks = Object.keys(availableAssets);
              setInputValues("networkGet", networks);
            }
          }
        }
      }

      if (input_id === "networkGet") {
        if (typeof value === "string") {
          const payAsset = state.assetPayValue;

          if (payAsset === "BTC") {
            setInputValues("assetGet", []);
          } else if (payAsset === "BRT") {
            setInputValues("assetGet", ["USDT"]);
          } else {
            if (availableAssets && availableAssets[value]) {
              const assets = availableAssets[value]
                .map((item: IBalance) => item.symbol)
                .filter((symbol: string) => symbol !== payAsset);
              setInputValues("assetGet", assets);
            }
          }
        }
      }

      if (input_id === "assetGet") {
        if (typeof value === "string")
          dispatch({ type: "SET_ASSET_GET_VALUE", payload: value });
      }
    },
    [state.inputs, availableAssets, setInputValues, state.assetPayValue]
  );

  const setInputError = useCallback(
    (input_id: string, error: string | boolean) => {
      dispatch({
        type: "SET_INPUTS",
        payload: state.inputs.map((v) => {
          if (v.input.type !== "multitext" && v.input.id === input_id) {
            v.input.error = error.toString();
          }
          return v;
        }),
      });
    },
    [state.inputs]
  );

  const reset = useCallback(() => {
    dispatch({ type: "SET_INPUTS", payload: initialInputsState as any });
    dispatch({ type: "SET_MINIMAL_ORDER_AMOUNT", payload: null });
    dispatch({ type: "SET_FEE", payload: null });
    dispatch({ type: "SET_AMOUNT_GET", payload: "" });
    dispatch({ type: "SET_STEP", payload: 1 });
  }, []);

  useEffect(() => {
    if (availableAssets) {
      setInputValues("networkPay", Object.keys(availableAssets));
    }
  }, [state.step, availableAssets, setInputValues]);

  useEffect(() => {
    let curValues: { [index: string]: string | boolean } = {};
    if (state.step === 1) {
      let hasErrors = false;
      state.inputs.forEach((v) => {
        if (v.input.type !== "multitext") {
          if (v.input.value === "") {
            hasErrors = true;
          }
          if (v.input.error) {
            hasErrors = true;
          }
          curValues[v.input.id] = v.input.value;
        }
      });

      if (!hasErrors) {
        dispatch({ type: "SET_CUR_VALUES", payload: curValues });
      }
    }
  }, [debouncedInputs, state.inputs, state.step]);

  useEffect(() => {
    const { assetPay, networkPay, amountPay, assetGet, networkGet } =
      state.curValues;

    const hasChanged =
      JSON.stringify(prevCurValues.current) !== JSON.stringify(state.curValues);

    if (
      hasChanged &&
      assetPay &&
      networkPay &&
      amountPay &&
      assetGet &&
      networkGet
    ) {
      refetch();
      prevCurValues.current = state.curValues;
    }
  }, [state.curValues, refetch]);

  useEffect(() => {
    if (swapInfo) {
      dispatch({ type: "SET_AMOUNT_GET", payload: swapInfo.swap_amount });
      dispatch({ type: "SET_FEE", payload: swapInfo.fee });
      dispatch({ type: "SET_MINIMAL_ORDER_AMOUNT", payload: swapInfo.min_sum });
    }
  }, [swapInfo]);

  const contentForm = useCallback(() => {
    return (
      <form className="exchange__order-form form" onSubmit={handleSubmit}>
        <div className="order-form__body">
          <div className="order-form__block">
            <h3 className="order-form__title">You pay</h3>
            <div className="order-form__items">
              {state.inputs.map((v, i) => {
                if (v.group === "YouPay") {
                  return (
                    <Input
                      key={i}
                      input={v.input}
                      setInputValue={setInputValue}
                      setInputError={setInputError}
                    />
                  );
                }
                return null;
              })}
              {state.assetPayValue && balances && (
                <span className="form__item-text-bottom">
                  Balance:{" "}
                  {getBalance(
                    balances,
                    state.networkPayValue,
                    state.assetPayValue
                  )}{" "}
                  {state.assetPayValue}
                </span>
              )}
            </div>
          </div>
          <div className="order-form__block">
            <h3 className="order-form__title">You get</h3>
            <div className="order-form__items">
              {state.inputs.map((v, i) => {
                if (v.group !== "YouPay") {
                  return (
                    <Input
                      key={i}
                      input={v.input}
                      setInputValue={setInputValue}
                      setInputError={setInputError}
                    />
                  );
                }
                return null;
              })}
              <div className="register__form-item">
                <div className="form__input input">
                  <input
                    type="text"
                    value={state.amountGet}
                    placeholder="Amount"
                    className="input__control"
                    readOnly
                  />
                </div>
              </div>
              <div className="form__item-text-bottom-wrapper">
                {state.minimalOrderAmount && (
                  <span className="form__item-text-bottom">
                    Minimal amount: {state.minimalOrderAmount}{" "}
                    {state.assetPayValue}
                  </span>
                )}
                {state.fee && (
                  <span className="form__item-text-bottom">
                    Fee: {state.fee} {state.assetPayValue}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          className="order-form__button button"
          type="button"
          onClick={handleSubmit}
        >
          {isPending ? <div className="spinner"></div> : "Create Order"}
        </button>
      </form>
    );
  }, [
    state.inputs,
    state.assetPayValue,
    state.networkPayValue,
    state.amountGet,
    state.minimalOrderAmount,
    state.fee,
    balances,
    handleSubmit,
    setInputValue,
    setInputError,
    isPending,
  ]);

  return (
    <>
      {state.step === 1 ? (
        <main className="main exchange">
          <div className="exchange__order">
            <h2 className="exchange__order-title">Exchange Order</h2>
            {contentForm()}
          </div>
        </main>
      ) : state.step === 2 ? (
        <main className="main ">
          <div className="exchange">
            <div className="exchange__order hidden">
              <h2 className="exchange__order-title">Swap Order</h2>
              {contentForm()}
            </div>
            <div className="exchange__order exchange__order-actions">
              <button
                type="button"
                className=" modal__close js-modal-close"
                aria-label="close modal"
                onClick={reset}
              >
                <svg className="modal__close-icon">
                  <use xlinkHref="img/sprite.svg#close"></use>
                </svg>
              </button>
              <h2 className="exchange__order-title exchange__order-title--actions">
                Order <span>#409489022</span>
              </h2>

              <ul className="exchange__order-details order-details">
                <li className="order-details__item order-details__item--header">
                  <h4 className="order-details__title">You Pay</h4>
                  <span className="order-details__value">
                    {state.curValues.amountPay} {state.assetPayValue}
                  </span>
                </li>
                <li className="order-details__item order-details__item--header">
                  <h4 className="order-details__title">Fee:</h4>
                  <span className="order-details__value">
                    {state.fee} {state.assetPayValue}
                  </span>
                </li>
                <li className="order-details__item order-details__item--header">
                  <h4 className="order-details__title">You Get:</h4>
                  <span className="order-details__value">
                    {state.amountGet} {state.assetGetValue}
                  </span>
                </li>
                <li className="order-details__item">
                  <h4 className="order-details__title">Date/Time:</h4>
                  <span className="order-details__value">
                    {moment(new Date()).format("DD.MM.YYYY hh:mm")}
                  </span>
                </li>
              </ul>
              <button
                type="button"
                className="exchange__order-button button"
                onClick={handleConfirmSwap}
              >
                Swap
              </button>
            </div>
          </div>
        </main>
      ) : (
        <main className="main">
          <div className="exchange">
            <div className="exchange__order hidden">
              <h2 className="exchange__order-title">Swap Order</h2>
              {contentForm()}
            </div>
            <div className="exchange__order exchange__order--done">
              <button
                type="button"
                className=" modal__close js-modal-close"
                aria-label="close modal"
                onClick={reset}
              >
                <svg className="modal__close-icon">
                  <use xlinkHref="img/sprite.svg#close"></use>
                </svg>
              </button>
              <h2 className="exchange__order-title exchange__order-title--done">
                Transaction completed successfully
              </h2>

              <p className="exchange__order-text">
                Your swap has been successfully completed. The transaction has
                been processed and the exchanged assets will be reflected in
                your balance shortly.{" "}
              </p>
              <button
                type="button"
                className="exchange__order-button button"
                onClick={reset}
              >
                Done!
              </button>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Swap;
