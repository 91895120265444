import React from 'react'
import { useNavigate } from 'react-router-dom'
import UserWrap from '../../components/UserWrap/UserWrap'


const Crowdfunding = () => {

  const navigate = useNavigate()

  return (
      <main className="main crowdfunding">
        <ul className="crowdfunding__tariff tariff">
          <li className="tariff__card tariff__card--personal">
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">Personal</h2>
                <p className="tariff__descr">0.8% per month, <br/> earn up to 9,6% a year</p>
                <button type="button" className="tariff__refund">Refund of all Amount</button>
              </div>
              <ul className="tariff__descr-items">
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon"/>
                  <p className="tariff__descr-text">Пользователь вносит (Депозит) некую сумму в BRT.
                  </p>
                </li>
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon tariff__descr-icon--down"/>
                  <p className="tariff__descr-text">Пользователь имеет право вернуть всю сумму на свой баланс в любое
                    время.
                  </p>
                </li>
                <li className="tariff__descr-item">
                  <img src="./img/svg/commission.svg" alt="icon" className="tariff__descr-icon"/>
                  <p className="tariff__descr-text">Комиссия: При возврате суммы взимается комиссия (Gas fee) в размере
                    0,02 BRT.
                  </p>
                </li>
              </ul>
              <button type="button" className="tariff__button button">Buy Personal</button>
            </div>
          </li>
          <li className="tariff__card tariff__card--basic">
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">Basic</h2>
                <p className="tariff__descr">1.2% per month, <br/>
                  earn up to 14,4% a year</p>
              </div>
              <ul className="tariff__descr-items">
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon"/>
                  <p className="tariff__descr-text">Депозит вносится на срок 3 месяца
                    (91 день)
                  </p>
                </li>
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon tariff__descr-icon--down"/>
                  <p className="tariff__descr-text">Депозит не может быть возвращен на баланс Пользователя раньше срока.
                  </p>
                </li>
                <li className="tariff__descr-item">
                  <img src="./img/svg/commission.svg" alt="icon" className="tariff__descr-icon"/>
                  <p className="tariff__descr-text">Процесс возврата: По прошествии 91 дня вся сумма Депозита возвращается
                    на баланс Пользователя автоматически.
                  </p>
                </li>
              </ul>
              <button type="button" className="tariff__button button">Buy Basic</button>
            </div>

          </li>
          <li className="tariff__card tariff__card--premium">
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">Premium</h2>
                <p className="tariff__descr">1.4% per month, <br/>
                  earn up to 16,8% a year</p>
              </div>
              <ul className="tariff__descr-items">
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon"/>
                  <p className="tariff__descr-text">Депозит вносится на срок 6 месяцев
                    (183 дня)
                  </p>
                </li>
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon tariff__descr-icon--down"/>
                  <p className="tariff__descr-text">Депозит не может быть возвращен на баланс Пользователя раньше
                    срока.
                  </p>
                </li>
                <li className="tariff__descr-item">
                  <img src="./img/svg/commission.svg" alt="icon" className="tariff__descr-icon"/>
                  <p className="tariff__descr-text">Процесс возврата: По прошествии 183 дней вся сумма Депозита
                    возвращается на баланс Пользователя автоматически.
                  </p>
                </li>
              </ul>
              <button type="button" className="tariff__button button">Buy Premium </button>
            </div>

          </li>
          <li className="tariff__card tariff__card--vip">
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">VIP</h2>
                <p className="tariff__descr">1.6% per month, <br/>
                  earn up to 19,2% a year</p>
              </div>
              <ul className="tariff__descr-items">
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon"/>
                  <p className="tariff__descr-text">Депозит вносится на срок 6 месяцев
                    (183 дня)
                  </p>
                </li>
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon tariff__descr-icon--down"/>
                  <p className="tariff__descr-text">Депозит не может быть возвращен на баланс Пользователя раньше
                    срока.
                  </p>
                </li>
                <li className="tariff__descr-item">
                  <img src="./img/svg/commission.svg" alt="icon" className="tariff__descr-icon"/>
                  <p className="tariff__descr-text">Процесс возврата: По прошествии 183 дней вся сумма Депозита
                    возвращается на баланс Пользователя автоматически.
                  </p>
                </li>
              </ul>
              <button type="button" className="tariff__button button button--blue">Buy VIP </button>
            </div>

          </li>
        </ul>

      </main>
  )
}

export default Crowdfunding