import { useState } from "react"
import useClickOutside from "../../hooks/useClickOutside"

export interface IFees {
  id: number
  operation_type: string
  min_sum: number
  max_sum: number
  fee: string
  is_fixed: boolean
  checked?: boolean
}

const Filter = ({
  setInputValue,
  value,
  placeholder,
  option
}: {
  setInputValue: (value: string) => void
  value: string
  placeholder: string
  option: string[]
}) => {
  const [isDropdownOpened, setIsDropDownOpened] = useState(false)
  const closeDropdown = () => setIsDropDownOpened(false)
  const dropdownRef = useClickOutside(closeDropdown)

  return (
    <div
      ref={dropdownRef}
      className={`register__form-item filter__form-item`}>
      <button
        onClick={() => setIsDropDownOpened((prev) => !prev)}
        type="button"
        className="table__input-rank input-rank">
        <p className="form-control__text">
          {value ? value : placeholder}
        </p>
        <svg className="input-rank__arrow">
          <use xlinkHref="img/sprite.svg#arrow"></use>
        </svg>
      </button>
      <ul
        style={{ overflowY: "auto", height: "11.25rem" }}
        className={`dropdown-menu filter form-settings__input ${isDropdownOpened && "open"}`}
        id="phone-dropdown">
        {option.map((v, i) => {
          return (
            <li
              key={i}
              onClick={() => {
                setInputValue(v)
                setIsDropDownOpened(false)
              }}
              className="dropdown-menu__item">
              {v}
            </li>
          )
        })}
      </ul>
    </div>     
  )
}

export default Filter
