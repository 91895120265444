import React, { FC, useState } from "react";
import Personal from "./innerPages/Personal";
import Password from "./innerPages/Password";
import Billing from "./innerPages/Billing";
import Notifications from "./innerPages/Notifications";
import Verification from "./innerPages/Verification/Verification";

const Settings: FC = () => {
  const [selectedPage, setSelectedPage] = useState<string>(
    "Personal Information"
  );

  const pageSelectors: Array<{ svg: string; name: string }> = [
    {
      svg: "edit",
      name: "Personal Information",
    },
    {
      svg: "password",
      name: "Password",
    },
    {
      svg: "card",
      name: "Billing",
    },
    {
      svg: "notification",
      name: "Notifications",
    },
    {
      svg: "verification",
      name: "Verification",
    },
  ];

  const pages: { [index: string]: React.ReactNode } = {
    "Personal Information": <Personal />,
    Password: <Password />,
    Billing: <Billing />,
    Notifications: <Notifications />,
    Verification: <Verification />,
  };

  return (
    <main className="main">
      <div className="settings">
        <nav className="settings__navigation navigation-settings">
          <ul className="navigation-settings__list">
            {pageSelectors.map((v) => {
              return (
                <li key={v.name} className="navigation-settings__item">
                  <button
                    onClick={() => setSelectedPage(v.name)}
                    className={`navigation-settings__link ${
                      v.name === selectedPage ? "active" : ""
                    }`}
                  >
                    <svg className="navigation-settings__link-icon">
                      <use xlinkHref={`img/sprite.svg#${v.svg}`}></use>
                    </svg>
                    <p className="navigation-settings__link-text">{v.name}</p>
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>
        {pages[selectedPage]}
      </div>
    </main>
  );
};
export default Settings;
