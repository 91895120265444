/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HeroContent from "../../components/HeroContent/HeroContent";
import Input from "../../components/Input/Input";
import { IInput } from "../../components/Input/input.types";
import PrivacyPolicy from "../../components/Modal/PrivacyPolicy";
import { socials } from "../../const/socials";
import nationalities from "./nationalities.json";

interface RegisterInputs {
  secondStage: boolean;
  input: IInput;
}

const Register: FC = () => {
  const [isSecondStage, setIsSecondStage] = useState(false);
  const [inputs, setInputs] = useState<Array<RegisterInputs>>([
    {
      secondStage: false,
      input: {
        type: "text",
        id: "email",
        svg: "email",
        value: "",
        placeholder: "E-mail",
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        error: "",
      },
    },
    {
      secondStage: false,
      input: {
        type: "password",
        id: "password",
        svg: "password",
        value: "",
        placeholder: "Password",
        repeat: false,
        show: false,
        error: undefined,
      },
    },
    {
      secondStage: false,
      input: {
        type: "password",
        id: "password-repeat",
        svg: "password",
        value: "",
        placeholder: "Repeat Password",
        repeat: true,
        show: false,
        error: undefined,
      },
    },
    {
      secondStage: true,
      input: {
        type: "text",
        id: "full_name",
        svg: "user",
        value: "",
        placeholder: "Full Name",
        regex: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
        error: undefined,
      },
    },
    {
      secondStage: true,
      input: {
        type: "text",
        id: "birthplace",
        svg: "birthplace",
        value: "",
        placeholder: "Birthplace",
        regex: /^[a-zA-Z0-9\s,.'-]{3,}$/,
        error: undefined,
      },
    },
    {
      secondStage: true,
      input: {
        type: "date",
        id: "birthdate",
        svg: "heart",
        value: "",
        placeholder: "Birthday",
        error: undefined,
      },
    },
    {
      secondStage: true,
      input: {
        type: "dropdown",
        id: "nationality",
        svg: "user",
        value: "",
        values: nationalities,
        placeholder: "Nationality",
        error: undefined,
      },
    },
    {
      secondStage: true,
      input: {
        type: "text",
        id: "phone",
        svg: "phone",
        //phoneCode: "+7",
        placeholder: "Phone",
        value: "",
        error: undefined,
      },
    },
    {
      secondStage: true,
      input: {
        type: "text",
        id: "city",
        svg: "address",
        value: "",
        placeholder: "City",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
    },
    {
      secondStage: true,
      input: {
        type: "multitext",
        texts: [
          {
            type: "text",
            id: "street",
            svg: "address",
            value: "",
            placeholder: "Street",
            error: undefined,
            regex:
              /^[a-zA-Z0-9\s,.'-]+(?:\s(?:Street|St|Avenue|Ave|Road|Rd|Boulevard|Blvd|Drive|Dr|Lane|Ln|Court|Ct))?$/,
          },
          {
            type: "text",
            id: "apt",
            svg: "address",
            value: "",
            placeholder: "Apt",
            error: undefined,
            regex: /^[a-zA-Z0-9\s,.'-]+$/,
          },
        ],
      },
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [reOpen, setReOpen] = useState(false);
  const [showAnimateClass, setShowAnimateClass] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const previousActiveElement = useRef<Element | null>(null);
  const [token, setToken] = useState<null | string>(null);

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
    document.documentElement.style.overflow = "";
  };

  const openModal = () => {
    previousActiveElement.current = document.activeElement;

    if (isOpen) {
      setReOpen(true);
      closeModal();
      return;
    }

    if (modalRef.current) {
      modalRef.current.scrollTo(0, 0);
      modalRef.current.style.setProperty("--transition-time", `${500 / 1000}s`);
    }

    disableScroll();
    setIsOpen(true);

    setTimeout(() => {
      setShowAnimateClass(true);
    }, 500);
  };

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.scrollTo(0, 0);
    }

    enableScroll();
    setIsOpen(false);
    setShowAnimateClass(false);

    if (previousActiveElement.current) {
      (previousActiveElement.current as HTMLElement).focus();
    }

    if (reOpen) {
      setReOpen(false);
      openModal();
    }
  };

  const [actions, setActions] = useState<Array<IInput>>([
    {
      type: "checkbox",
      id: "agree",
      text: (
        <>
          I certify that I am 18 years of age or older, and I agree to the{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Terms of Agreement
          </a>
          ,
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
          >
             Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            KYC & AML Policy
          </a>
        </>
      ),
      value: false,
      error: undefined,
    },
  ]);

  const navigate = useNavigate();

  const onButtonClick = async () => {
    let hasErrors = false;
    let curValues: { [index: string]: string | boolean } = {};

    const newInputs = inputs.map((v) => {
      if (v.input.type !== "multitext") {
        if (v.input.value === "" && v.secondStage === isSecondStage) {
          v.input.error = "Can't be empty";
          hasErrors = true;
        }

        if (v.input.error && v.secondStage === isSecondStage) {
          hasErrors = true;
        }
        if (v.input.id !== "password-repeat") {
          if (v.input.id === "city") {
            curValues["address"] = v.input.value + ", ";
          } else if (v.input.id === "phone" && v.input.type === "phone") {
            if (!v.input.value.toString().includes("+")) {
              curValues[v.input.id] = v.input.phoneCode + " " + v.input.value;
            } else {
              curValues[v.input.id] = v.input.value;
            }
          } else {
            curValues[v.input.id] = v.input.value;
          }
        }
      } else {
        v.input.texts = v.input.texts.map((i) => {
          if (i.value === "" && v.secondStage === isSecondStage) {
            i.error = "Can't be empty";
            hasErrors = true;
          }

          if (i.error && v.secondStage === isSecondStage) {
            hasErrors = true;
          }
          curValues["address"] += i.value + ", ";
          return i;
        });
      }
      return v;
    });

    curValues["address"] = curValues["address"].toString().slice(0, -2);

    setInputs(newInputs);

    if (isSecondStage && actions[0].type === "checkbox" && !actions[0].value) {
      toast.error(
        "Please confirm our Terms of Agreement, Privacy Policy and KYC & AML Policy"
      );
      hasErrors = true;
    } else if (isSecondStage && !token) {
      toast.error("Recaptcha error");
      hasErrors = true;
    }

    if (!hasErrors) {
      if (!isSecondStage) {
        setIsSecondStage(true);
      } else {
        if (process.env.REACT_APP_API_URL) {
          const req = await fetch(process.env.REACT_APP_API_URL + "/users", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(curValues),
          });

          const res = await req.json();

          if (req.status === 200 || req.status === 201) {
            toast.success("Account has been successfully created");
            setTimeout(() => navigate("/login"), 2000);
          } else {
            if (Array.isArray(res["message"])) {
              toast.error(res["message"]?.[0]);
            } else {
              toast.error(res["message"]);
            }
          }
        }
      }
    }
  };

  const checkPasswordsEquals: () => boolean = () => {
    const passwordInput = inputs.find(
      (e) => e.input.type === "password" && !e.input.repeat
    );
    const repeatPasswordInput = inputs.find(
      (e) => e.input.type === "password" && e.input.repeat
    );
    if (!passwordInput || !repeatPasswordInput) return false;
    if (
      passwordInput.input.type !== "password" ||
      repeatPasswordInput.input.type !== "password"
    )
      return false;
    return passwordInput.input.value === repeatPasswordInput.input.value;
  };

  const setPasswordHide = (input_id: string, show: boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.input.type === "password" && v.input.id === input_id) {
          v.input.show = show;
        }
        return v;
      })
    );
  };

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.input.type !== "multitext") {
          if (v.input.id === input_id) {
            v.input.value = value;
            if (v.input.type === "date") {
              if (v.input.value === "") {
                v.input.error = "Can't be empty";
              } else {
                v.input.error = "";
              }
            }
          }
        } else {
          v.input.texts = v.input.texts.map((i) => {
            if (i.id === input_id) {
              i.value = value.toString();
            }
            return i;
          });
        }
        return v;
      })
    );
  };

  const setActionValue = (action_id: string, value: string | boolean) => {
    setActions((action) =>
      action.map((v) => {
        if (
          v.type === "checkbox" &&
          typeof value === "boolean" &&
          v.id === action_id
        ) {
          v.value = value;
        }
        return v;
      })
    );
  };

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.input.type !== "multitext") {
          if (v.input.id === input_id) {
            v.input.error = error.toString();
          }
        } else {
          v.input.texts = v.input.texts.map((i) => {
            if (i.id === input_id) {
              i.error = error.toString();
            }
            return i;
          });
        }
        return v;
      })
    );
  };

  const setPhoneCode = (input_id: string, value: string) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.input.type === "phone")
          if (v.input.id === input_id) {
            v.input.phoneCode = value;
          }

        return v;
      })
    );
  };

  const onChange = (token: string | null) => {
    setToken(token);
  };

  return (
    <div className="register">
      <div className="register__hero">
        <div className="hero">
          <div></div>
          <div className="hero__decor">
            <picture>
              <source srcSet="./img/background/decor.webp" type="image/webp" />
              <img
                loading="lazy"
                src="./img/background/decor.png"
                className="hero__image"
                width=""
                height=""
                alt=""
              />
            </picture>
          </div>

          <HeroContent />
        </div>
      </div>
      <main className="register__main">
        <div className="register__logo">
          <img src="./img/logo.svg" alt="logo" />
        </div>

        <div className="register__body">
          <h1 className="register__title">Registration</h1>
          <form className="register__form">
            <div className="register__form-items">
              {inputs.map((v) => {
                if (
                  v.secondStage === isSecondStage &&
                  v.input.type !== "checkbox"
                ) {
                  return (
                    <Input
                      input={v.input}
                      setHideInput={setPasswordHide}
                      setInputValue={setInputValue}
                      setPhoneCode={setPhoneCode}
                      setInputError={setInputError}
                      checkPasswordsEquals={checkPasswordsEquals}
                      setActionValue={setActionValue}
                    />
                  );
                }
              })}
            </div>
            {isSecondStage && (
              <div className="register__actions">
                {/*<div className="register__upload">
                  <p className="register__upload-text">
                    Please upload your passport or ID card (both sides) and your
                    driver's license (both sides)
                  </p>
                  <div className="register__upload-actions">
                    <button type="button" className="register__upload-attach">
                      Attach
                    </button>
                    <div className="register__upload-items">
                      <button type="button" className="register__upload-item">
                        <input
                          type="file"
                          name="image"
                          className="register__upload-item-file"
                        />
                        <svg className="register__upload-item-icon">
                          <use xlinkHref="img/sprite.svg#clip"></use>
                        </svg>
                        <p className="register__upload-item-text done">
                          Passport or national ID
                        </p>
                      </button>
                      <button type="button" className="register__upload-item">
                        <input
                          type="file"
                          name="image"
                          className="register__upload-item-file"
                        />
                        <svg className="register__upload-item-icon">
                          <use xlinkHref="img/sprite.svg#clip"></use>
                        </svg>
                        <p className="register__upload-item-text done">
                          Driver license
                        </p>
                      </button>
                    </div>
                  </div>
                </div>*/}
                {actions.map((v) => {
                  if (v.type === "checkbox") {
                    return (
                      <Input
                        input={v}
                        setHideInput={setPasswordHide}
                        setInputValue={setInputValue}
                        setPhoneCode={setPhoneCode}
                        setInputError={setInputError}
                        checkPasswordsEquals={checkPasswordsEquals}
                        setActionValue={setActionValue}
                      />
                    );
                  }
                })}
                <div className="register__form-recaptcha">
                  <ReCAPTCHA
                    sitekey="6LdRG7cqAAAAABCwVJyzxQt9kqddhoOCcUFRjgge"
                    onChange={onChange}
                  />
                </div>
              </div>
            )}
            <button
              onClick={() => onButtonClick()}
              type="button"
              className="register__button button"
            >
              {isSecondStage ? "Finish Registration" : "Next"}
            </button>
          </form>
        </div>
        <div className="register__socials">
          <ul className="socials">
            {socials.map((icon) => (
              <li className="socials__item" key={icon.id}>
                <a
                  href={icon.href}
                  rel="noreferrer"
                  target="_blank"
                  className="socials__link"
                >
                  <svg className="socials__icon">
                    <use xlinkHref={`img/sprite.svg#${icon.id}`}></use>
                  </svg>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </main>
      <PrivacyPolicy
        modalRef={modalRef}
        isOpen={isOpen}
        closeModal={closeModal}
        showAnimateClass={showAnimateClass}
      />
    </div>
  );
};

export default Register;
