import { useMutation, useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";
import { IPartnerForm } from "../types/types";

const createPartner = async (data: IPartnerForm) => {
  return authApi.post("/partners", data).then((res) => res.data);
};

const getPartners = async () => {
  return authApi.get("/partners").then((res) => res.data);
};

const updatePartner = async (id: string, data: IPartnerForm) => {
  return authApi.patch(`/partners/${id}`, data).then((res) => res.data);
};

const deletePartner = async (id: string) => {
  return authApi.delete(`/partners/${id}`).then((res) => res.data);
};

export const useCreatePartners = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["NewPartner"],
    mutationFn: (data: IPartnerForm) => createPartner(data),
    onSuccess,
    onError,
  });
};

export const useGetPartners = () => {
  const {
    data: partners,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["PartnersList"],
    queryFn: getPartners,
  });

  return {
    partners,
    isLoading,
    isError,
    refetch,
  };
};

export const useUpdatePartner = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdatePartner"],
    mutationFn: ({ id, data }: { id: string; data: IPartnerForm }) =>
      updatePartner(id, data),
    onSuccess,
    onError,
  });
};

export const useDeletePartner = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["DeletePartner"],
    mutationFn: (id: string) => deletePartner(id),
    onSuccess,
    onError,
  });
};
