import React, { useState } from "react";
import * as yup from "yup";
import Modal from "../../components/Modal";
import { useGetBrt } from "../../services/dashboard.services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICrowdfundingForm } from "../../types/types";
import {
  useGetUserCrowdfundingPlans,
  useJoinToCrowdfundingPlan,
  useRefundPersonalPlanAmount,
} from "../../services/crowdfunding.services";
import { toast } from "react-toastify";
import CrowdfundingPlan from "../../components/Modal/CrowdfundingPlan";
import CrowdfundingRefund from "../../components/Modal/CrowdfundingRefund";
import CrowdfundingDeposits from "../../components/Modal/CrowdfundingDeposits";

const plans = [
  {
    id: 1,
    title: "Personal",
    monthlyRate: "0.8%",
    annualRate: "9.6%",
    description: [
      "Deposit any amount in BRT and enjoy complete flexibility.",
      "Withdraw your full deposit at any time without restrictions.",
      "Low fee: Only a small gas fee of 0.02 BRT applies when you withdraw your funds.",
    ],
    buttonText: "Get Started",
    className: "tariff__card--personal",
    refund: true,
  },
  {
    id: 2,
    title: "Basic",
    monthlyRate: "1.2%",
    annualRate: "14.4%",
    description: [
      "Lock your deposit for 3 months (91 days) and enjoy higher returns.",
      "Your deposit is securely held until the end of the term.",
      "Automatic refund: After 91 days, your full deposit is returned to your balance effortlessly.",
    ],
    buttonText: "Choose Basic",
    className: "tariff__card--basic",
    refund: false,
  },
  {
    id: 3,
    title: "Premium",
    monthlyRate: "1.4%",
    annualRate: "16.8%",
    description: [
      "Maximize your earnings with a 6-month (183 days) deposit plan.",
      "Your funds are locked for the term, ensuring steady growth.",
      "Hassle-free returns: After 183 days, your deposit is automatically refunded to your account.",
    ],
    buttonText: "Go Premium",
    className: "tariff__card--premium",
    refund: false,
  },
  {
    id: 4,
    title: "VIP",
    monthlyRate: "1.6%",
    annualRate: "19.2%",
    description: [
      "Exclusive VIP plan with the highest returns for a 6-month (183 days) term.",
      "Your deposit is secured and grows at an unbeatable rate.",
      "Seamless refunds: After 183 days, your full deposit is automatically returned to your balance.",
    ],
    buttonText: "Join VIP",
    className: "tariff__card--vip",
    refund: false,
    buttonClass: "button--blue",
  },
];

const JoinToCrowdfundingPlanFormSchema = yup.object().shape({
  amount: yup.number().required("Amount is a required field!"),
  type: yup.string().required(""),
  payout_frequency: yup.string().required(""),
});

interface IModalStructure {
  isOpen: boolean;
  interestRate: number;
  term: string;
  depositType: string;
  buttonText: string;
}

const planModals = {
  personal: {
    isOpen: false,
    interestRate: 0.8,
    term: "Custom",
    depositType: "Personal Deposit",
    buttonText: "Buy Personal",
  },
  basic: {
    isOpen: false,
    interestRate: 1.2,
    term: "91 Days",
    depositType: "Basic Deposit",
    buttonText: "Buy Basic",
  },
  premium: {
    isOpen: false,
    interestRate: 1.4,
    term: "183 Days",
    depositType: "Premium Deposit",
    buttonText: "Buy Premium",
  },
  vip: {
    isOpen: false,
    interestRate: 1.6,
    term: "183 Days",
    depositType: "VIP Deposit",
    buttonText: "Buy VIP",
  },
};

const depositModals = {
  personal: false,
  basic: false,
  premium: false,
  vip: false,
};

const Crowdfunding: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<{
    personal: IModalStructure;
    basic: IModalStructure;
    premium: IModalStructure;
    vip: IModalStructure;
  }>(planModals);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState<{
    personal: boolean;
    basic: boolean;
    premium: boolean;
    vip: boolean;
  }>(depositModals);
  const [isOpenRefundModal, setIsOpenRefundModal] = useState<boolean>(false);
  const { brt } = useGetBrt();

  const useReturnForm = useForm({
    resolver: yupResolver(JoinToCrowdfundingPlanFormSchema),
    defaultValues: {
      payout_frequency: "Monthly",
    },
  });

  const { setValue } = useReturnForm;

  const { userPlans, refetch } = useGetUserCrowdfundingPlans();

  const { mutate: refundPersonalPlan } = useRefundPersonalPlanAmount(
    () => {
      refetch();
      toast.success("The funds were returned");
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const { mutate: joinToCrowdfundingPlan } = useJoinToCrowdfundingPlan(
    () => {
      toast.success("Order completed!");
      setIsModalOpen((prev) => {
        return {
          personal: {
            ...prev.personal,
            isOpen: false,
          },
          basic: {
            ...prev.basic,
            isOpen: false,
          },
          premium: {
            ...prev.premium,
            isOpen: false,
          },
          vip: {
            ...prev.vip,
            isOpen: false,
          },
        };
      });
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const handleSubmitJoiningForm = (data: ICrowdfundingForm) => {
    joinToCrowdfundingPlan({
      ...data,
      payout_frequency: data.payout_frequency.toUpperCase(),
    });
  };

  const handleRefundSelectedPlans = async (ids: string[]) => {
    for (const id of ids) {
      await refundPersonalPlan(id);
    }
    setIsOpenRefundModal(false);
  };

  return (
    <main className="main crowdfunding">
      <ul className="crowdfunding__tariff tariff">
        {plans?.map((plan) => (
          <li key={plan.id} className={`tariff__card ${plan.className}`}>
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">{plan.title}</h2>
                <p className="tariff__descr">
                  {plan.monthlyRate} per month, <br /> earn up to{" "}
                  {plan.annualRate} a year
                </p>
                {userPlans?.filter((item: any) => item.type === "PERSONAL")
                  .length > 0 &&
                  plan.id === 1 && (
                    <button
                      onClick={() => setIsOpenRefundModal(true)}
                      type="button"
                      className="tariff__refund"
                    >
                      Refund of all Amount
                    </button>
                  )}
              </div>
              <ul className="tariff__descr-items">
                {plan.description.map((desc, index) => (
                  <li key={index} className="tariff__descr-item">
                    <img
                      src="./img/svg/arrow-tariff.svg"
                      alt="icon"
                      className={`tariff__descr-icon ${
                        index === 1 ? "tariff__descr-icon--down" : ""
                      }`}
                    />
                    <p className="tariff__descr-text">{desc}</p>
                  </li>
                ))}
              </ul>
              <button
                type="button"
                className={`tariff__button button ${plan.buttonClass || ""}`}
                onClick={() => {
                  switch (plan.id) {
                    case 1: {
                      setIsModalOpen((prev) => {
                        return {
                          ...prev,
                          personal: {
                            ...prev.personal,
                            isOpen: true,
                          },
                        };
                      });
                      setValue("type", "PERSONAL");
                      break;
                    }
                    case 2: {
                      setIsModalOpen((prev) => {
                        return {
                          ...prev,
                          basic: {
                            ...prev.basic,
                            isOpen: true,
                          },
                        };
                      });
                      setValue("type", "BASIC");
                      break;
                    }
                    case 3: {
                      setIsModalOpen((prev) => {
                        return {
                          ...prev,
                          premium: {
                            ...prev.premium,
                            isOpen: true,
                          },
                        };
                      });
                      setValue("type", "PREMIUM");
                      break;
                    }
                    case 4: {
                      setIsModalOpen((prev) => {
                        return {
                          ...prev,
                          vip: {
                            ...prev.vip,
                            isOpen: true,
                          },
                        };
                      });
                      setValue("type", "VIP");
                      break;
                    }
                  }
                }}
              >
                {plan.buttonText}
              </button>
              <button
                onClick={() => {
                  switch (plan.id) {
                    case 1: {
                      setIsDepositModalOpen((prev) => {
                        return {
                          ...prev,
                          personal: true,
                        };
                      });
                      break;
                    }
                    case 2: {
                      setIsDepositModalOpen((prev) => {
                        return {
                          ...prev,
                          basic: true,
                        };
                      });
                      break;
                    }
                    case 3: {
                      setIsDepositModalOpen((prev) => {
                        return {
                          ...prev,
                          premium: true,
                        };
                      });
                      break;
                    }
                    case 4: {
                      setIsDepositModalOpen((prev) => {
                        return {
                          ...prev,
                          vip: true,
                        };
                      });
                      break;
                    }
                  }
                }}
                className="button--text"
              >
                My deposits
              </button>
            </div>
          </li>
        ))}
      </ul>

      <Modal
        isOpen={isOpenRefundModal}
        setIsOpen={setIsOpenRefundModal}
        title="Deposit List"
        subtitle="Select one or more deposits to refund"
      >
        <CrowdfundingRefund
          personalPlans={userPlans?.filter(
            (item: any) => item.type === "PERSONAL"
          )}
          onRefund={handleRefundSelectedPlans}
          onClose={() => setIsOpenRefundModal(false)}
        />
      </Modal>

      {Object.keys(depositModals).map((item, index) => {
        return (
          <Modal
            key={index}
            isOpen={
              isDepositModalOpen[
                item as "basic" | "premium" | "vip" | "personal"
              ]
            }
            setIsOpen={
              ((value: boolean) => {
                setIsDepositModalOpen((prev) => {
                  return {
                    ...prev,
                    [item]: value,
                  };
                });
              }) as any
            }
            title="Deposit List"
          >
            <CrowdfundingDeposits
              personalPlans={userPlans?.filter(
                (userItem: any) => userItem.type === item.toUpperCase()
              )}
            />
          </Modal>
        );
      })}

      {Object.keys(planModals).map((item) => {
        return (
          <Modal
            key={item}
            isOpen={
              isModalOpen[item as "basic" | "premium" | "vip" | "personal"]
                .isOpen
            }
            setIsOpen={
              ((value: boolean) => {
                setIsModalOpen((prev) => {
                  return {
                    ...prev,
                    [item]: {
                      ...prev[item as "basic" | "premium" | "vip" | "personal"],
                      isOpen: value,
                    },
                  };
                });
              }) as any
            }
            title="Deposit Order"
            subtitle="#409489022"
          >
            <CrowdfundingPlan
              brt={brt!}
              returnFormInstance={useReturnForm}
              onSubmit={handleSubmitJoiningForm}
              interestRate={
                planModals[item as "basic" | "premium" | "vip" | "personal"]
                  .interestRate
              }
              term={
                planModals[item as "basic" | "premium" | "vip" | "personal"]
                  .term
              }
              depositType={
                planModals[item as "basic" | "premium" | "vip" | "personal"]
                  .depositType
              }
              buttonText={
                planModals[item as "basic" | "premium" | "vip" | "personal"]
                  .buttonText
              }
            />
          </Modal>
        );
      })}
    </main>
  );
};

export default Crowdfunding;
