import React from "react";

interface IProps {
  handleConfirm: () => void;
  handleCancel: () => void;
}

const DeleteCardConfirmation: React.FC<IProps> = ({
  handleCancel,
  handleConfirm,
}) => {
  return (
    <div className="settings-action">
      <div className="settings-action__text">
        <p
          style={{
            maxWidth: "22.25rem",
          }}
        >
          By clicking 'Confirm' you agree to the permanent deletion of your
          card, as well as all data associated with it, including transactions
        </p>
      </div>
      <div className="settings-action__buttons">
        <button
          onClick={handleCancel}
          type="button"
          className="terms__button button button--red"
        >
          Cancel
        </button>
        <button
          onClick={handleConfirm}
          type="button"
          className="terms__button button"
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default DeleteCardConfirmation;
