import { useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";
import { INotification } from "../types/types";

const getNotificationList = async () => {
  return authApi
    .get("/notifications")
    .then((res) => res.data as INotification[]);
};

export const useGetNotificationList = () => {
  const {
    data: notifications,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Notifications"],
    queryFn: getNotificationList,
  });

  return {
    notifications,
    isLoading,
    isError,
    refetch,
  };
};
