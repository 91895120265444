// AuthContext.tsx
import Cookies from "js-cookie";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginArguments } from "../types/types";
import { authApi } from "../api/api";
import axios from "axios";

interface AuthContextState {
  user: User | null;
  login: (curValues: LoginArguments) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  is2FA: boolean;
}

interface User {
  id: string;
  full_name: string;
  email: string;
  role: string;
  is_2fa_enabled: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextState | undefined>(
  undefined
);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [is2FA, setIs2FA] = useState(false);
  const navigate = useNavigate();

  // Verify and refresh tokens
  const verifyToken = async (token: string): Promise<any> => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.access_token && data.refresh_token) {
        Cookies.set("access_token", data.access_token);
        Cookies.set("refresh_token", data.refresh_token);
        authApi.defaults.headers.Authorization = `Bearer ${data.access_token}`;
        return data;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  // Check authentication status
  const checkAuth = useCallback(async () => {
    const refreshToken = Cookies.get("refresh_token");
    if (!refreshToken) {
      setUser(null);
      setLoading(false);
      return false;
    }

    try {
      const userData = await verifyToken(refreshToken);
      if (userData) {
        setUser(userData);
        setLoading(false);
        return true;
      }
      throw new Error("Invalid token");
    } catch (error) {
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      setUser(null);
      setLoading(false);
      return false;
    }
  }, []);

  // Login function
  const login = async (curValues: LoginArguments): Promise<void> => {
    try {
      const { data } = await authApi.post("/auth/login", curValues);

      if (data.access_token && data.refresh_token) {
        // Set initial tokens
        Cookies.set("access_token", data.access_token);
        Cookies.set("refresh_token", data.refresh_token);

        setUser(data);
        toast.success("Successfully signed in");
        navigate("/");
      }
    } catch (error: any) {
      if (error.response?.data?.message === "Need 2FA code") {
        setIs2FA(true);
      } else {
        toast.error(error.response?.data?.message || "Login failed");
      }
    }
  };

  // Logout function
  const logout = async (): Promise<void> => {
    try {
      // Optional: Call logout endpoint if exists
      // await authApi.post("/auth/logout");
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      setUser(null);
      setIs2FA(false);
      toast.success("Logged out successfully");
      navigate("/login");
    }
  };

  // Check auth on mount
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, is2FA }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
