/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HeroContent from "../../components/HeroContent/HeroContent";
import Input from "../../components/Input/Input";
import { IInput } from "../../components/Input/input.types";
import { socials } from "../../const/socials";

const ResetPassword = () => {
  const [is2FA, setIs2FA] = useState(false);
  const [inputs, setInputs] = useState<Array<IInput>>([
    {
      type: "text",
      id: "email",
      svg: "email",
      value: "",
      placeholder: "E-mail",
      regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      error: "",
    },
  ]);

  const navigate = useNavigate();

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.type !== "multitext") {
          if (v.id === input_id) {
            v.value = value;
            if (v.type === "date") {
              if (v.value === "") {
                v.error = "Can't be empty";
              } else {
                v.error = "";
              }
            }
          }
        }
        return v;
      })
    );
  };

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.type !== "multitext") {
          if (v.id === input_id) {
            v.error = error.toString();
          }
        }
        return v;
      })
    );
  };

  const onButtonClick = async () => {
    let hasErrors = false;
    let curValues: { [index: string]: string | boolean } = {};

    const newInputs = inputs.map((v) => {
      if (v.type !== "multitext") {
        if (v.value === "") {
          v.error = "Can't be empty";
          hasErrors = true;
        }

        if (v.error) {
          hasErrors = true;
        }
        curValues[v.id] = v.value;
      }
      return v;
    });

    setInputs(newInputs);

    if (!hasErrors) {
      if (process.env.REACT_APP_API_URL) {
        const req = await fetch(
          process.env.REACT_APP_API_URL + "/auth/request_reset_password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(curValues),
          }
        );
        const res = await req.json();
        if (req.status !== 201) {
          toast.error(res["message"]);
          if (res["message"] === "Need 2FA code") {
            setIs2FA(true);
          }
        } else {
          toast.success(
            "Password reset link was sended to your e-mail address"
          );
        }
      }
    }
  };

  useEffect(() => {
    if (is2FA) {
      setInputs((prev) => [
        ...prev,
        {
          type: "password",
          id: "code",
          svg: "password",
          value: "",
          placeholder: "2FA",
          repeat: false,
          show: false,
          error: undefined,
        },
      ]);
    }
  }, [is2FA]);

  return (
    <>
      <div className="register">
        <div className="register__hero">
          <div className="hero">
            <div></div>
            <div className="hero__decor">
              <picture>
                <source
                  srcSet="./img/background/decor.webp"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  src="./img/background/decor.png"
                  className="hero__image"
                  width=""
                  height=""
                  alt=""
                />
              </picture>
            </div>

            <HeroContent />
          </div>
        </div>
        <main className="register__main">
          <div className="register__logo">
            <img src="./img/logo.svg" alt="logo" />
          </div>
          <div className="register__body">
            <h1 className="register__title">Reset Paswword</h1>
            <form className="register__form">
              <div className="register__form-items">
                {inputs.map((v) => (
                  <Input
                    input={v}
                    setInputValue={setInputValue}
                    setInputError={setInputError}
                  />
                ))}
              </div>

              <button
                onClick={onButtonClick}
                type="button"
                className="register__button button"
              >
                Reset
              </button>
              <div className="register__form-links">
                <a
                  onClick={() => navigate("/login")}
                  className="register__form-link"
                >
                  Sign In
                </a>
                <a
                  onClick={() => navigate("/register")}
                  className="register__form-link"
                >
                  Sign Up
                </a>
              </div>
            </form>
          </div>
          <div className="register__socials">
            <ul className="socials">
              {socials.map((item) => (
                <li className="socials__item">
                  <a
                    href={item.href}
                    rel="noreferrer"
                    target="_blank"
                    className="socials__link"
                  >
                    <svg className="socials__icon">
                      <use xlinkHref={`img/sprite.svg#${item.id}`}></use>
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </main>
      </div>
    </>
  );
};

export default ResetPassword;
