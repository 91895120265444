import { useMutation, useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";

export const getUserCounts = async (startDate: number, endDate: number) => {
  const response = await authApi.get(
    `/users/count?min_tms=${startDate}&max_tms=${endDate}`
  );
  return response.data;
};

export const getActiveUserCounts = async (
  startDate: number,
  endDate: number
) => {
  const response = await authApi.get(
    `/users/count/active?min_tms=${startDate}&max_tms=${endDate}`
  );
  return response.data;
};

const lockUser = async (data: { lock: boolean; user_id: number }) => {
  const response = await authApi.post(`/users/lock-user/`, data);
  return response.data;
};

const deleteUser = async (id: number) => {
  const response = await authApi.delete(`/users/${id}`);
  return response.data;
};

interface UserParams {
  sel_month: string;
  only_admins: string;
  only_users: string;
  only_super_admins: string;
  search?: string;
}

const getUsers = async (params: UserParams) => {
  const searchParams = new URLSearchParams({
    sel_month: params.sel_month,
    only_admins: params.only_admins,
    only_users: params.only_users,
    only_super_admins: params.only_super_admins,
    ...(params.search && { search: params.search }),
  });

  const response = await authApi.get(`/users?${searchParams}`);
  return response.data;
};

export const useGetUserCounts = (startDate: number, endDate: number) => {
  return useQuery({
    queryKey: ["userCounts", startDate, endDate],
    queryFn: () => getUserCounts(startDate, endDate),
  });
};

export const useGetActiveUserCounts = (startDate: number, endDate: number) => {
  return useQuery({
    queryKey: ["activeUserCounts", startDate, endDate],
    queryFn: () => getActiveUserCounts(startDate, endDate),
  });
};

export const useGetUsers = (params: UserParams) => {
  return useQuery({
    queryKey: ["users", params],
    queryFn: () => getUsers(params),
  });
};

export const useLockUser = (
  onSuccess: () => void,
  onError: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["lockUser"],
    mutationFn: (data: { lock: boolean; user_id: number }) => lockUser(data),
    onSuccess: onSuccess,
    onError: onError,
  });
};

export const useDeleteUser = (
  onSuccess: () => void,
  onError: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["deleteUser"],
    mutationFn: (id: number) => deleteUser(id),
  });
};
