import React, { useState, FC } from "react";
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";
import { IInput } from "../../components/Input/input.types";
import Input from "../../components/Input/Input";
import nationalities from './nationalities.json'

interface RegisterInputs {
  secondStage: boolean,
  input: IInput
}

const Register: FC = () => {
  const [isSecondStage, setIsSecondStage] = useState(false)
  const [inputs, setInputs] = useState<Array<RegisterInputs>>([
    {
      secondStage: false,
      input: {
        type: 'text',
        id: "email",
        svg: "email",
        value: "",
        placeholder: "E-mail",
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        error: ""
      }
    },
    {
      secondStage: false,
      input: {
        type: 'password',
        id: "password",
        svg: "password",
        value: "",
        placeholder: "Password",
        repeat: false,
        show: false,
        error: undefined
      }
    },
    {
      secondStage: false,
      input: {
        type: 'password',
        id: "password-repeat",
        svg: "password",
        value: "",
        placeholder: "Repeat Password",
        repeat: true,
        show: false,
        error: undefined
      }
    },
    {
      secondStage: true,
      input: {
        type: 'text',
        id: "full_name",
        svg: "user",
        value: "",
        placeholder: "Full Name",
        regex: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
        error: undefined
      }
    },
    {
      secondStage: true,
      input: {
        type: 'text',
        id: "birthplace",
        svg: "birthplace",
        value: "",
        placeholder: "Birthplace",
        regex: /^[a-zA-Z0-9\s,.'-]{3,}$/,
        error: undefined
      }
    },
    {
      secondStage: true,
      input: {
        type: 'date',
        id: "birthdate",
        svg: "heart",
        value: "",
        placeholder: "Birthday",
        error: undefined
      }
    },
    {
      secondStage: true,
      input: {
        type: 'dropdown',
        id: "nationality",
        svg: "user",
        value: "",
        values: nationalities,
        placeholder: "Nationality",
        error: undefined
      }
    },
    {
      secondStage: true,
      input: {
        type: 'phone',
        id: "phone",
        svg: "phone",
        phoneCode: "+7",
        value: "",
        error: undefined
      }
    },
    {
      secondStage: true,
      input: {
        type: 'text',
        id: "city",
        svg: "address",
        value: "",
        placeholder: "City",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/
      }
    },
    {
      secondStage: true,
      input: {
        type: 'multitext',
        texts: [
          {
            type: 'text',
            id: "street",
            svg: "address",
            value: "",
            placeholder: "Street",
            error: undefined,
            regex: /^[a-zA-Z0-9\s,.'-]+(?:\s(?:Street|St|Avenue|Ave|Road|Rd|Boulevard|Blvd|Drive|Dr|Lane|Ln|Court|Ct))?$/
          },
          {
            type: 'text',
            id: "apt",
            svg: "address",
            value: "",
            placeholder: "Apt",
            error: undefined,
            regex: /^[a-zA-Z0-9\s,.'-]+$/
          }
          
        ]
      }
    }
  ])

  const [actions, setActions] = useState<Array<IInput>>([
    {
      type: 'checkbox',
      id: "agree",
      text:  <>I certify that I am 18 years of age or
      older, and I agree to the <a href="#">Terms of Agreement, Privacy
        Policy</a> and <a href="#">KYC & AML Policy</a></>,
      value: false,
      error: undefined
    },
  ])

  const navigate = useNavigate()

  const onButtonClick = async () => {

    let hasErrors = false
    let curValues: {[index: string]: string | boolean} = {}
    
    const newInputs = inputs.map(v => {
      if (v.input.type !== "multitext") {
        if (v.input.value === "" && v.secondStage === isSecondStage) {
          v.input.error = "Can't be empty"
          hasErrors = true
        }
        
        if (v.input.error && v.secondStage === isSecondStage) {
          hasErrors = true
        }
        if (v.input.id !== "password-repeat") {
          if (v.input.id === "city") {
            curValues["address"] = v.input.value + ", "
          } else if (v.input.id === "phone" && v.input.type === "phone") {
            if (!v.input.value.toString().includes("+")) {
              curValues[v.input.id] = v.input.phoneCode + " " + v.input.value
            } else {
              curValues[v.input.id] = v.input.value
            }
          } else {
            curValues[v.input.id] = v.input.value
          }
        }
        
      } else {
        v.input.texts = v.input.texts.map(i => {
          if (i.value === "" && v.secondStage === isSecondStage) {
            i.error = "Can't be empty"
            hasErrors = true
          }
          
          if (i.error && v.secondStage === isSecondStage) {
            hasErrors = true
          }
          curValues["address"] += i.value + ", "
          return i
        })
      }
      return v
    })

    curValues["address"] = curValues["address"].toString().slice(0, -2)

    setInputs(newInputs)

    if (isSecondStage && actions[0].type === "checkbox" && !actions[0].value) {
      toast.error("Please confirm our Terms of Agreement, Privacy Policy and KYC & AML Policy")
      hasErrors = true
    }

    if (!hasErrors) {
      if (!isSecondStage) {
        setIsSecondStage(true)
      } else {
        if (process.env.REACT_APP_API_URL) {
          console.log(curValues)
          
          const req = await fetch(process.env.REACT_APP_API_URL + "/users", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(curValues)
          })

          const res = await req.json()

          if (req.status !== 201) {
            toast.error(res['message'])
          } else {
            toast.success("Account has been successfully created")
            setTimeout(() => navigate("/login"), 2000)
          }

        }
      }
    }
    
  }

  const checkPasswordsEquals: () => boolean = () => {
    const passwordInput = inputs.find(e => e.input.type === "password" && !e.input.repeat)
    const repeatPasswordInput = inputs.find(e => e.input.type === "password" && e.input.repeat)
    if (!passwordInput || !repeatPasswordInput) return false
    if (passwordInput.input.type !== "password" || repeatPasswordInput.input.type !== "password") return false
    return passwordInput.input.value === repeatPasswordInput.input.value
  }

  const setPasswordHide = (input_id: string, show: boolean) => {
    setInputs(input => input.map(v => {
      if ((v.input.type === "password") && (v.input.id === input_id)) {
        v.input.show = show
      }
      return v
    }))
  }

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs(input => input.map(v => {
      if (v.input.type !== "multitext") {
        if (v.input.id === input_id) {
          v.input.value = value
          if (v.input.type === "date") {
            if (v.input.value === "") {
              v.input.error = "Can't be empty"
              
            } else {
              v.input.error = ""
            }
          }
        }
      } else {
        v.input.texts = v.input.texts.map(i => {
          if (i.id === input_id) {
            i.value = value.toString()
          }
          return i
        })
      }
      return v
    }))
  }

  const setActionValue = (action_id: string, value: string | boolean) => {
    setActions(action => action.map(v => {
      
      if (v.type === "checkbox" && typeof(value) === "boolean" && v.id === action_id) {
        console.log(action_id, value)
        v.value = value
      }
      return v
    }))
  }

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs(input => input.map(v => {
      if (v.input.type !== "multitext") {
        if (v.input.id === input_id) {
          v.input.error = error.toString()
        }
      } else {
        v.input.texts = v.input.texts.map(i => {
          if (i.id === input_id) {
            i.error = error.toString()
          }
          return i
        })
      }
      return v
    }))
  }

  const setPhoneCode = (input_id: string, value: string) => {
    setInputs(input => input.map(v => {
      if (v.input.type === "phone")
        if (v.input.id === input_id) {
          v.input.phoneCode = value
        }
      
      return v
    }))
  }

  return (
  <div className="register">
  <div className="register__hero">
    <div className="hero">
  <div>

  </div>
  <div className="hero__decor">
    <picture >
      <source srcSet="./img/background/decor.webp" type="image/webp" />
      <img  loading="lazy" src="./img/background/decor.png" className="hero__image" width="" height="" alt="" />
    </picture>
  </div>

  <div className="hero__body">
    <a href="#" className="hero__logo"><img src="./img/logo.svg" alt="logo"/></a>
    <div className="hero__content">
      <h1 className="hero__title"><span className="hero__title-amount">237,453,783</span> users trust us
      </h1>
      <p className="hero__text">Tristique tortor dolor magna arcu porttitor dignissim id fames dolor. Semper fringilla sed
        proin venenatis nam turpis facilisi venenatis. Non quisque odio nulla massa sagittis elementum eget fusce.</p>
    </div>
    <div className="hero__links">
      <a href="#" className="hero__link">
        <img src="./img/binance.svg" alt="binance"/>
      </a>
      <a href="#" className="hero__link">
        <img src="./img/coinmarket.svg" alt="coinmarket"/>
      </a>
      <a href="#" className="hero__link">
        <img src="./img/uniswap.svg" alt="uniswap"/>
      </a>
    </div>
  </div>

</div>

    </div>
    <main className="register__main">
      <a href="#" className="register__logo"><img src="./img/logo.svg" alt="logo"/></a>

      <div className="register__body">
        <h1 className="register__title">Registration</h1>
        <form className="register__form">
          <div className="register__form-items">
            { inputs.map(v => {
              if ((v.secondStage === isSecondStage) && (v.input.type !== "checkbox")) {
                return (
                  <Input
                    input={v.input}
                    setHideInput={setPasswordHide}
                    setInputValue={setInputValue}
                    setPhoneCode={setPhoneCode}
                    setInputError={setInputError}
                    checkPasswordsEquals={checkPasswordsEquals}
                    setActionValue={setActionValue}
                  />
                )
              }
            })}
            { (isSecondStage) && <div className="register__actions">
              {
                actions.map(v => {
                  if ((v.type === "checkbox")) {
                    return (
                      <Input
                        input={v}
                        setHideInput={setPasswordHide}
                        setInputValue={setInputValue}
                        setPhoneCode={setPhoneCode}
                        setInputError={setInputError}
                        checkPasswordsEquals={checkPasswordsEquals}
                        setActionValue={setActionValue}
                      />
                    )
                  }
                })
              }
            </div>}
          </div>
          <button onClick={() => onButtonClick()} type="button" className="register__button button">{ (isSecondStage) ? "Finish Registration" : "Next"}</button>
        </form>
      </div>
      <div className="register__socials">
                <ul className="socials">
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#telegram"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#medium"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#reddit"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#linkedin"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#twitter"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#crunchbase"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#discord"></use>
              </svg>
            </a>
          </li>
        </ul>

      </div>
    </main>
  </div>
  );
};

export default Register;
