import React from "react";
import { UseFormReturn, Controller } from "react-hook-form";
import { INewCardForm } from "../../types/types";

interface IProps {
  returnFormInstance: UseFormReturn<INewCardForm>;
  onSubmit: (data: INewCardForm) => void;
}

const NewCard: React.FC<IProps> = ({ returnFormInstance, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = returnFormInstance;

  const cardNumber = watch("card_number");

  const getCardType = (cardNumber: string) => {
    if (/^4/.test(cardNumber)) {
      return "visa";
    } else if (/^5[1-5]/.test(cardNumber)) {
      return "mastercard";
    } else if (/^3[47]/.test(cardNumber)) {
      return "american";
    }
    return "credit-card";
  };

  const formatExpirationDate = (value: string) => {
    let formattedValue = value.replace(/\D/g, "");
    if (formattedValue.length > 2) {
      formattedValue =
        formattedValue.slice(0, 2) + "/" + formattedValue.slice(2, 4);
    }
    return formattedValue;
  };

  const formatCardNumber = (value: string) => {
    let formattedValue = value.replace(/\D/g, "");
    formattedValue = formattedValue.replace(/(\d{4})/g, "$1 ").trim();
    return formattedValue.slice(0, 19);
  };

  const cardType = getCardType(cardNumber || "");

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="add-card form">
      <div className="add-card__items form__items">
        <div className="form__item">
          <label className="form__label">Cardholder name</label>
          <div className="form__input input">
            <Controller
              name="cardholder"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className="input__control"
                  placeholder="John Johnson"
                />
              )}
            />
          </div>
          {errors.cardholder && (
            <p className="form__error">{errors.cardholder.message}</p>
          )}
        </div>
        <div className="form__item">
          <label className="form__label">Card number</label>
          <div className="form__input input">
            <Controller
              name="card_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className="input__control"
                  placeholder="5544 3569 6974 1235"
                  value={formatCardNumber(field.value)}
                  onChange={(e) => {
                    const formattedValue = formatCardNumber(e.target.value);
                    field.onChange(formattedValue);
                  }}
                  maxLength={19}
                />
              )}
            />
            {cardType && (
              <img
                className="input__icon"
                src={`./img/card/${cardType}.${
                  cardType === "credit-card" ? "png" : "svg"
                }`}
                alt={cardType}
              />
            )}
          </div>
          {errors.card_number && (
            <p className="form__error">{errors.card_number.message}</p>
          )}
        </div>
        <div className="form__row">
          <div className="form__item">
            <label className="form__label">Expiration date</label>
            <div className="form__input input">
              <Controller
                name="exp_date"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className="input__control"
                    placeholder="MM/YY"
                    value={formatExpirationDate(field.value)}
                    onChange={(e) => {
                      const formattedValue = formatExpirationDate(
                        e.target.value
                      );
                      field.onChange(formattedValue);
                    }}
                    maxLength={5}
                  />
                )}
              />
            </div>
            {errors.exp_date && (
              <p className="form__error">{errors.exp_date.message}</p>
            )}
          </div>
          <div className="form__item">
            <label className="form__label">CVV</label>
            <div className="form__input input">
              <Controller
                name="cvv"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className="input__control"
                    placeholder="123"
                    maxLength={3}
                  />
                )}
              />
            </div>
            {errors.cvv && <p className="form__error">{errors.cvv.message}</p>}
          </div>
        </div>
      </div>
      <button type="submit" className="order-form__button button">
        Add Card
      </button>
    </form>
  );
};

export default NewCard;
