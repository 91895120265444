import { useMutation, useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";

interface FeesParams {
  search: string;
  fee_type: string;
}

const getFees = async (params: FeesParams) => {
  const searchParams = new URLSearchParams({
    ...params,
  });
  const response = await authApi.get(`/fees?${searchParams}`);
  return response.data;
};

interface UpdateFeeData {
  id: number;
  operation_type: string;
  min_sum: number;
  max_sum: number;
  fee: number;
  is_fixed: boolean;
}

const updateFee = async (data: UpdateFeeData) => {
  console.log("Sending update request with data:", data);
  const response = await authApi.patch(`/fees/${data.id}`, {
    operation_type: data.operation_type,
    min_sum: data.min_sum,
    max_sum: data.max_sum,
    fee: data.fee,
    is_fixed: data.is_fixed,
  });
  console.log("Update response:", response.data);
  return response.data;
};

export const useGetFees = (params: FeesParams) => {
  return useQuery({
    queryKey: ["fees", params],
    queryFn: () => getFees(params),
  });
};

export const useUpdateFee = (
  onSuccess: () => void,
  onError: (error: any) => void
) => {
  return useMutation({
    mutationFn: updateFee,
    onSuccess: (data) => {
      console.log("Update successful:", data);
      onSuccess();
    },
    onError: (error) => {
      console.error("Update failed:", error);
      onError(error);
    },
  });
};
