import React, { useState } from "react";
import { ICrowdfundingPlan } from "../../types/types";
import moment from "moment";

interface IProps {
  personalPlans: ICrowdfundingPlan[];
}

const CrowdfundingDeposits: React.FC<IProps> = ({ personalPlans }) => {
  const [selectedPlans, setSelectedPlans] = useState<string[]>([]);

  const handlePlanClick = (id: string) => {
    setSelectedPlans((prev) =>
      prev.includes(id) ? prev.filter((planId) => planId !== id) : [...prev, id]
    );
  };

  return (
    <>
      <div
        className="refund__table transactions-table"
        style={{
          maxWidth: "400px",
        }}
      >
        <div className="transactions-table__content table__content">
          {personalPlans.length > 0 && (
            <div className="table__row">
              <ul className="transactions-table__items table__items table__items--title">
                <li className="table__item table__item--order">Order</li>
                <li className="table__item table__item--date">Date</li>
                <li className="table__item table__item--status">Rate</li>
                <li className="table__item">Amount</li>
              </ul>
            </div>
          )}
          {personalPlans.length > 0 ? (
            personalPlans?.map((item) => (
              <div
                key={item.id}
                className={`table__row ${
                  selectedPlans.includes(item.id.toString())
                    ? "table__items--paid"
                    : ""
                }`}
                onClick={() => handlePlanClick(item.id.toString())}
              >
                <ul className="transactions-table__items table__items">
                  <li className="table__item">
                    <div
                      className={`table__item-order ${
                        selectedPlans.includes(item.id.toString())
                          ? "table__item-order--paid"
                          : ""
                      }`}
                    >
                      #{item.id}
                    </div>
                  </li>
                  <li className="table__item">
                    {moment(new Date(item.end_date)).format("MMM DD hh:mm")}
                  </li>
                  <li className="table__item">{item.rate}%</li>
                  <li className="table__item">{item.amount} BRT</li>
                </ul>
              </div>
            ))
          ) : (
            <div className="crowdfunding-empty">
              You have no deposits for this plan.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CrowdfundingDeposits;
