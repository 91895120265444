import React, { FC, useEffect, useState } from 'react'

const Content : FC = () => {

  return (
    <div className="users">
          <div className="users__table ">
            <div className="table">
              <div className="table__header">
                <h2 className="table__title">Users list</h2>
                <form className="table__inputs">
                  <div className="table__input-search input-search">
                    <svg className="input-search__icon">
                      <use xlinkHref="img/sprite.svg#search"></use>
                    </svg>
                    <input type="text" name="" value="" placeholder="Pagem user, date" className="input-search__control"/>
                  </div>
                </form>
                <div className="table__actions">
                  <div className="table__button button">Create New Page</div>
                </div>
              </div>
              <div className="table__body">
                <div className="table__row">
                  <ul className="table__items table__items--title">
                    <li className="table__item table__item--id">ID</li>
                    <li className="table__item">Date</li>
                    <li className="table__item">User type</li>
                    <li className="table__item">Page</li>
                    <li className="table__item">Status</li>
                    <li className="table__item"></li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id table__item-id--check">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Admin 1</li>
                    <li className="table__item">Crowdfunding</li>
                    <li className="table__item">
                      <div className="table__item-status table__item-status--published">Published</div>
                    </li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#edit"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#basket"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Admin 1</li>
                    <li className="table__item">Crowdfunding</li>
                    <li className="table__item">
                      <div className="table__item-status table__item-status--canceled">Canceled</div>
                    </li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#edit"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#basket"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id table__item-id--check">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Admin 1</li>
                    <li className="table__item">Crowdfunding</li>
                    <li className="table__item">
                      <div className="table__item-status table__item-status--published">
                        Published</div>
                    </li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#edit"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#basket"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
  )
}

export default Content