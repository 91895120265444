import React from 'react'
import { useNavigate } from 'react-router-dom'
import UserWrap from '../../components/UserWrap/UserWrap'


const Avalanche  = () => {

  const navigate = useNavigate()

  return (
      <main className="main avalanche">
        <div className="avalanche__code code">
          <div className="code__qr">
            <img src="./img/qr-code.svg" alt=""/>
          </div>
          <div className="code__body">
            <h2 className="code__title">Code for your referrals</h2>
            <div className="code__address">
              <span className="code__address-text">a5d7fga56v6ad4fg2sadf4gadfg</span>
              <button type="button" className="code__address-copy">
                <svg className="code__address-copy-icon">
                  <use xlinkHref="img/sprite.svg#copy"></use>
                </svg>
              </button>
            </div>
            <button type="button" className="code__btn button button--blue">Generate</button>
          </div>
        </div>
        <ul className="avalanche__tariff tariff">
          <li className="tariff__card tariff__card--stairs-1">
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">Golden Stairs I</h2>
              </div>
              <ul className="tariff__descr-items">
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon"/>
                  <div className="tariff__descr-text">
                    <p>Parturient dui iaculis sed proin nunc faucibus amet. Nisl vitae in dui eget pretium ut etiam.
                      Faucibus semper diam quis volutpat est augue. Faucibus suspendisse ultrices egestas lacus sodales
                      lobortis quam.</p>
                    <p>Tellus sagittis mollis libero felis suspendisse risus. Malesuada leo est suspendisse ultricies
                      in quam lacus. Elit sit massa elementum hendrerit feugiat sit etiam vel turpis. Nulla et est
                      suspendisse maecenas faucibus luctus vel.</p>
                  </div>
                </li>

              </ul>
              <button type="button" className="tariff__button button">5 BRT</button>
            </div>
          </li>
          <li className="tariff__card tariff__card--stairs-2">
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">Golden Stairs II</h2>
              </div>
              <ul className="tariff__descr-items">
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon"/>
                  <div className="tariff__descr-text">
                    <p>Parturient dui iaculis sed proin nunc faucibus amet. Nisl vitae in dui eget pretium ut etiam.
                      Faucibus semper diam quis volutpat est augue. Faucibus suspendisse ultrices egestas lacus sodales
                      lobortis quam.</p>
                    <p>Tellus sagittis mollis libero felis suspendisse risus. Malesuada leo est suspendisse ultricies
                      in quam lacus. Elit sit massa elementum hendrerit feugiat sit etiam vel turpis. Nulla et est
                      suspendisse maecenas faucibus luctus vel.</p>
                  </div>
                </li>

              </ul>
              <button type="button" className="tariff__button button">5 BRT</button>
            </div>
          </li>
          <li className="tariff__card tariff__card--stairs-3">
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">Golden Stairs III</h2>
              </div>
              <ul className="tariff__descr-items">
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon"/>
                  <div className="tariff__descr-text">
                    <p>Parturient dui iaculis sed proin nunc faucibus amet. Nisl vitae in dui eget pretium ut
                      etiam.
                      Faucibus semper diam quis volutpat est augue. Faucibus suspendisse ultrices egestas
                      lacus sodales
                      lobortis quam.</p>
                    <p>Tellus sagittis mollis libero felis suspendisse risus. Malesuada leo est suspendisse
                      ultricies
                      in quam lacus. Elit sit massa elementum hendrerit feugiat sit etiam vel turpis. Nulla et
                      est
                      suspendisse maecenas faucibus luctus vel.</p>
                  </div>
                </li>

              </ul>
              <button type="button" className="tariff__button button">500 BRT</button>
            </div>
          </li>
          <li className="tariff__card tariff__card--stairs-4">
            <div className="tariff__body">
              <div className="tariff__header">
                <h2 className="tariff__title">Golden Stairs IV</h2>
              </div>
              <ul className="tariff__descr-items">
                <li className="tariff__descr-item">
                  <img src="./img/svg/arrow-tariff.svg" alt="icon" className="tariff__descr-icon"/>
                  <div className="tariff__descr-text">
                    <p>Parturient dui iaculis sed proin nunc faucibus amet. Nisl vitae in dui eget
                      pretium ut etiam.
                      Faucibus semper diam quis volutpat est augue. Faucibus suspendisse ultrices
                      egestas lacus sodales
                      lobortis quam.</p>
                    <p>Tellus sagittis mollis libero felis suspendisse risus. Malesuada leo est
                      suspendisse ultricies
                      in quam lacus. Elit sit massa elementum hendrerit feugiat sit etiam vel
                      turpis. Nulla et est
                      suspendisse maecenas faucibus luctus vel.</p>
                  </div>
                </li>

              </ul>
              <button type="button" className="tariff__button button">5000 BRT</button>
            </div>
          </li>
        </ul>
      </main>
  )
}

export default Avalanche 