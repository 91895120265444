import moment from "moment";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { ITX } from "../../../types/types";

interface IProps {
  userHistoryMonth: Date | null;
  setUserHistoryMonth: React.Dispatch<React.SetStateAction<Date | null>>;
  txs: Array<ITX>;
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>;
}

const TransactionHistory: React.FC<IProps> = ({
  userHistoryMonth,
  setUserHistoryMonth,
  txs,
  setSelectedPage,
}) => {
  const OneDateInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }, ref) => (
      <button
        onClick={onClick}
        ref={ref}
        className="transactions__calendar calendar"
      >
        <img className="calendar__icon" src="./img/svg/date.svg" alt="" />
        <span className="calendar__date">{value}</span>
        <svg className="calendar__arrow">
          <use xlinkHref="img/sprite.svg#arrow"></use>
        </svg>
      </button>
    )
  );

  const getTransactionType = (tx: ITX) => {
    // Определяем тип транзакции на основе данных
    return tx.type === "IN" ? "In" : "Out";
  };

  return (
    <div className="dashboard-analytics__transactions transactions">
      <div className="transactions__header">
        <h2 className="transactions__title">Transactions History</h2>
        <div className="">
          <DatePicker
            selected={userHistoryMonth}
            onChange={(date) => setUserHistoryMonth(date)}
            customInput={<OneDateInput />}
            dateFormat="MMM yyyy"
            showMonthYearPicker
          />
        </div>
      </div>
      <div className="transactions__table transactions-table">
        <div className="transactions-table__content table__content">
          <div className="table__row">
            <ul className="transactions-table__items history-table__items table__items--title">
              <li className="table__item">Order</li>
              <li className="table__item table__item--type">Type</li>
              <li className="table__item table__item--date">Date</li>
              <li className="table__item table__item--status">Status</li>
              <li className="table__item">Total</li>
            </ul>
          </div>
          <div className="transactions-table__body table__body">
            {txs?.map((item, index) => (
              <div key={item.created_at} className="table__row">
                <ul className="transactions-table__items history-table__items">
                  <li className="table__item">
                    <div className="admin-table__item-order">#{index + 1}</div>
                  </li>
                  <li className="table__item">
                    <span
                      className={`transaction-type transaction-type--${getTransactionType(
                        item
                      ).toLowerCase()}`}
                    >
                      {getTransactionType(item)}
                    </span>
                  </li>
                  <li className="table__item">
                    {moment(new Date(item?.created_at)).format("MMM D, h:mm A")}
                  </li>
                  <li className="table__item">
                    <p
                      className={`table__item-status table__item-status--${item.status.toLowerCase()}`}
                    >
                      {item.status}
                    </p>
                  </li>
                  <li className="table__item">
                    <span>
                      {item.amount} {item.symbol}
                    </span>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <button
        onClick={() => setSelectedPage("history")}
        type="button"
        className="transactions__all"
      >
        All Transactions
      </button>
    </div>
  );
};

export default TransactionHistory;
