// AuthContext.tsx
import React, { createContext, useState, useEffect, useCallback, ReactNode } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoginArguments } from "../types/types";

// Define the shape of the context state
interface AuthContextState {
  user: User | null;
  login: (curValues: LoginArguments) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
}

// Define the shape of the User object
interface User {
  id: string;
  full_name: string;
  email: string;
  role: string;
}

// Props for AuthProvider component
interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextState | undefined>(undefined);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  // Check if the user is authorized based on the JWT stored in cookies
  const checkAuth = useCallback(async () => {

    try {
      const res = await fetch(process.env.REACT_APP_API_URL + "/auth/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include"
      });

      const data = await res.json();

      if (res.status === 201 && data) {
        setUser(data);
      } else if (res.status === 401) {
        // If access token is expired, try refreshing the token
        await refreshAccessToken();
      } else {
        setUser(null);
        toast.error("Session expired. Please log in again.");
      }
    } catch (error) {
      setUser(null);
      toast.error("Error verifying session.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Function to refresh the access token
  const refreshAccessToken = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + "/auth/refresh-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      const data = await res.json();

      if (res.status === 201) {
        // Update the access token in cookies and state
        console.log("Session refreshed");
        setUser(data.user); // Update user state with the new user data
      } else {
        console.log("fue")
        setUser(null);
        console.log("Unable to refresh token. Please log in again.");
        navigate("/login");
      }
    } catch (error) {
      console.log(error)
      setUser(null);
      console.log("Error refreshing token. Please log in again.");
      navigate("/login");
    }
  };

  // Run the checkAuth on component mount to verify user authentication
  useEffect(() => {
    checkAuth();
    return () => {}
  }, [checkAuth]);

  // Login function
  const login = async (curValues: LoginArguments): Promise<void> => {
    try {
      const req = await fetch(process.env.REACT_APP_API_URL + "/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(curValues),
        credentials: "include",
      });

      const res = await req.json();
      setUser(res)
      if (req.status !== 201) {
        toast.error(res["message"]);
      } else {
        toast.success("Successfully signed in");
        setTimeout(() => navigate("/"), 2000);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Login failed");
    }
  };

  // Logout function
  const logout = async (): Promise<void> => {
    try {
      const req = await fetch(process.env.REACT_APP_API_URL + "/auth/logout", {
        method: "POST",
        credentials: "include",
      });

      const res = await req.json();
      if (req.status === 200) {
        toast.success("Logged out successfully");
        setUser(null);
        navigate("/login");
      } else {
        toast.error(res["message"]);
      }
    } catch (error) {
      toast.error("Logout failed");
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
