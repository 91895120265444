import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from "react-router-dom";
import { IInput } from '../../components/Input/input.types'
import Input from '../../components/Input/Input'

const PasswordReset = () => {

  const [isError, setIsError] = useState(true)
  const [inputs, setInputs] = useState<Array<IInput>>([
    {
        type: 'password',
        id: "new_password",
        svg: "password",
        value: "",
        placeholder: "Password",
        repeat: false,
        show: false,
        error: undefined
    },
    {
        type: 'password',
        id: "password-repeat",
        svg: "password",
        value: "",
        placeholder: "Repeat Password",
        repeat: true,
        show: false,
        error: undefined
    }
  ])

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs(input => input.map(v => {
      if (v.type !== "multitext") {
        if (v.id === input_id) {
          v.value = value
          if (v.type === "date") {
            if (v.value === "") {
              v.error = "Can't be empty"
              
            } else {
              v.error = ""
            }
          }
        }
      }
      return v
    }))
  }

  const setPasswordHide = (input_id: string, show: boolean) => {
    setInputs(input => input.map(v => {
      if ((v.type === "password") && (v.id === input_id)) {
        v.show = show
      }
      return v
    }))
  }

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs(input => input.map(v => {
      if (v.type !== "multitext") {
        if (v.id === input_id) {
          v.error = error.toString()
        }
      }
      return v
    }))
  }

  const checkPasswordsEquals: () => boolean = () => {
    const passwordInput = inputs.find(e => e.type === "password" && !e.repeat)
    const repeatPasswordInput = inputs.find(e => e.type === "password" && e.repeat)
    if (!passwordInput || !repeatPasswordInput) return false
    if (passwordInput.type !== "password" || repeatPasswordInput.type !== "password") return false
    return passwordInput.value === repeatPasswordInput.value
  }

  const onButtonClick = async () => {

    let hasErrors = false
    let curValues: {[index: string]: string | boolean} = {}
    
    const newInputs = inputs.map(v => {
      if (v.type !== "multitext") {
        if (v.value === "") {
          v.error = "Can't be empty"
          hasErrors = true
        }
        
        if (v.error) {
          hasErrors = true
        }
        if (v.id !== "password-repeat") {
          if (v.id === "city") {
            curValues["address"] = v.value + ", "
          } else {
            curValues[v.id] = v.value
          }
        }
      }
      return v
    })

    setInputs(newInputs)

    if (!hasErrors) {
      if (process.env.REACT_APP_API_URL) {
        console.log(curValues)
        const token = searchParams.get('token')
        if (token) {
          curValues['token'] = token
          const req = await fetch(process.env.REACT_APP_API_URL + "/auth/reset_password", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(curValues)
          })
          const res = await req.json()
          if (req.status !== 201) {
            toast.error(res['message'])
          } else {
            toast.success("Password has been successfully changed!")
            setTimeout(() => navigate('/login'), 2000)
          }
        }
      }
    }
    
  }

    useEffect(() => {
        if (searchParams.size > 0) {
            const token = searchParams.get('token')
            if (token) {
                console.log(token)
                fetch(process.env.REACT_APP_API_URL + "/auth/check_reset_password_token", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ token })
                  })
                  .then(req => req.json()
                    .then(res => {
                        if (req.status === 201) {
                            setIsError(false)
                        }
                    })
                  )
                  
                  
            }
        }
    }, [searchParams])

  return (
    <>
      <div className="register">
    <div className="register__hero">
      <div className="hero">
  <div>

  </div>
  <div className="hero__decor">
    <picture >
      <source srcSet="./img/background/decor.webp" type="image/webp"/>
      <img  loading="lazy" src="./img/background/decor.png" className="hero__image" width="" height="" alt=""/>
    </picture>
  </div>

  <div className="hero__body">
    <a href="#" className="hero__logo"><img src="./img/logo.svg" alt="logo"/></a>
    <div className="hero__content">
      <h1 className="hero__title"><span className="hero__title-amount">237,453,783</span> users trust us
      </h1>
      <p className="hero__text">Tristique tortor dolor magna arcu porttitor dignissim id fames dolor. Semper fringilla sed
        proin venenatis nam turpis facilisi venenatis. Non quisque odio nulla massa sagittis elementum eget fusce.</p>
    </div>
    <div className="hero__links">
      <a href="#" className="hero__link">
        <img src="./img/binance.svg" alt="binance"/>
      </a>
      <a href="#" className="hero__link">
        <img src="./img/coinmarket.svg" alt="coinmarket"/>
      </a>
      <a href="#" className="hero__link">
        <img src="./img/uniswap.svg" alt="uniswap"/>
      </a>
    </div>
  </div>

</div>

    </div>
    <main className="register__main">
      <a href="#" className="register__logo"><img src="./img/logo.svg" alt="logo"/></a>
      <div className="register__body">
        <h1 className="register__title">{ isError ? "Error!" : "Reset Password"}</h1>
        <form className="register__form">
            { isError && 
                    <p style={{ paddingBottom: "10px" }}>Your token has been expired. Please request another one</p>
            }
            {
                !isError &&
                <div className="register__form-items">
                    {inputs.map(v => 
                        <Input
                            input={v}
                            setHideInput={setPasswordHide}
                            setInputValue={setInputValue}
                            setInputError={setInputError}
                            checkPasswordsEquals={checkPasswordsEquals}
                        />
                    )}
                </div>                    
            }
            <button onClick={() => isError ? navigate('/reset-password') : onButtonClick()} type="button" className="register__button button">{isError ? "Request" : "Reset"}</button>
        </form>
      </div>
      <div className="register__socials">
                <ul className="socials">
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#telegram"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#medium"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#reddit"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#linkedin"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#twitter"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#crunchbase"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#discord"></use>
              </svg>
            </a>
          </li>
        </ul>

      </div>
    </main>
  </div>
    </>
  )
}

export default PasswordReset