/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from "react";
import { useGetFees, useUpdateFee } from "../../services/fees.services";
import EditFees from "./EditFees";
import Filter from "../../components/Filter/Filter";
import { toast } from "react-toastify";

export interface IFees {
  id: number;
  operation_type: string;
  min_sum: number;
  max_sum: number;
  fee: string;
  is_fixed: boolean;
  checked?: boolean;
}

const FEE_TYPES = ["SWAP", "SELL", "WITHDRAW", "DEPOSIT", "ALL"] as const;
type FeeType = (typeof FEE_TYPES)[number];

const Fees: FC = () => {
  const [search, setSearch] = useState("");
  const [selectedFeeType, setSelectedFeeType] = useState<FeeType>("ALL");

  const { data: fees = [], refetch: refetchFees } = useGetFees({
    search,
    fee_type: selectedFeeType === "ALL" ? "" : selectedFeeType,
  });

  const { mutate: updateFee } = useUpdateFee(
    () => {
      console.log("Fee updated successfully, refetching...");
      refetchFees();
      toast.success("Fee updated successfully!");
    },
    (error) => {
      console.error("Fee update failed:", error);
      toast.error(error.response?.data?.message || "Failed to update fee");
    }
  );

  return (
    <div className="users">
      <div className="users__table">
        <div className="table" style={{ minHeight: "80vh", height: "100%" }}>
          <div className="table__header">
            <h2 className="table__title">Fees list</h2>
            <form
              className="table__inputs"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="table__input-search input-search">
                <svg className="input-search__icon">
                  <use xlinkHref="img/sprite.svg#search"></use>
                </svg>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search fees..."
                  className="input-search__control"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <Filter
                placeholder="Transaction Type"
                value={selectedFeeType}
                setInputValue={setSelectedFeeType as any}
                option={FEE_TYPES as any}
              />
            </form>
          </div>
          <div className="table__body">
            <div className="table__row">
              <ul className="table__items table__items--title">
                <li className="table__item admin-table__item--id">ID</li>
                <li className="table__item">Operation Type</li>
                <li className="table__item">Sum min</li>
                <li className="table__item">Sum max</li>
                <li className="table__item">Current Fee</li>
                <li className="table__item"></li>
              </ul>
            </div>
            {fees.map((fee: any) => (
              <div className="table__row" key={fee.id}>
                <ul className="table__items">
                  <li className="table__item">
                    <div
                      className={`admin-table__item--id ${
                        fee.checked ? "table__item-id--check" : ""
                      }`}
                    >
                      #{fee.id}
                    </div>
                  </li>
                  <li className="table__item">{fee.operation_type}</li>
                  <li className="table__item">{fee.min_sum} USDT</li>
                  <li className="table__item">{fee.max_sum} USDT</li>
                  <li className="table__item">{fee.fee}%</li>
                  <li className="table__item">
                    <div className="table__item-actions">
                      <EditFees
                        fees={fee}
                        getFees={refetchFees as any}
                        setFees={updateFee as any}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fees;
