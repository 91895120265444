import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserWrap from '../../components/UserWrap/UserWrap'
import { toast } from 'react-toastify'

interface IBalance {
  symbol: string,
  full_name: string,
  balance: number,
  price: number,
  price_24h_pcnt: number
}

interface IBalances {
  [index: string]: Array<IBalance>
}

interface ITX {
  timestamp: number,
	status: string,
	type: string,
	amount: number
  symbol: string
}

const Dashboard = () => {

  const navigate = useNavigate()

  const [balances, setBalances] = useState<IBalances>()
  const [txs, setTxs] = useState<Array<ITX>>()

  const getBalances = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/dashboard/balance", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
    const result : IBalances = await response.json()
    for (const network of Object.keys(result)) {
      result[network] = await Promise.all(
        result[network].map(async (v) => {
          v.price = 1;
          if (v.symbol !== "USDT" && v.symbol !== "BRT") {
            const params = new URLSearchParams({
              category: "spot",
              symbol: v.symbol.toUpperCase() + "USDT",
            });
            const response = await fetch("https://api.bybit.com/v5/market/tickers?" + params);
            const data = await response.json();
            if (data.retMsg === "OK") {
              v.price = parseFloat(data.result.list[0].lastPrice);
              v.price_24h_pcnt = parseFloat(data.result.list[0].price24hPcnt)
            }
          }
          return v;
        })
      );
    }

    return result
  } 

  const getTxs = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/dashboard/txs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
    const result : Array<ITX> = await response.json()

    return result
  } 

  useEffect(() => {
    getBalances().then(result => setBalances(result))
    getTxs().then(result => setTxs(result))

    return () => {}
  }, [])

  return (
      <main className="main dashboard">
        <div className="dashboard__balance-info balance-info">
          <div className="balance-info__item">
            <div className="balance-info__top">
              <svg className="balance-info__icon balance-info__icon--total">
                <use xlinkHref="img/sprite.svg#heart"></use>
              </svg>
              <label className="balance-info__label">Total Balance</label>
              <button type="button" className="balance-info__btn">
                <svg className="balance-info__dots">
                  <use xlinkHref="img/sprite.svg#dots"></use>
                </svg>
              </button>
            </div>
            <div className="balance-info__amount amount">
              <div className="amount__text">1 000 000
                <span className="amount__currency">BRT</span>
              </div>
              <div className="amount__change amount__change--up">

                <span className="amount__change-amount">28.4%</span>
                <svg className="amount__change-icon">
                  <use xlinkHref="img/sprite.svg#arrow-change"></use>
                </svg>
              </div>
            </div>
          </div>
          <div className="balance-info__item">
            <div className="balance-info__top">
              <svg className="balance-info__icon balance-info__icon--depo">
                <use xlinkHref="img/sprite.svg#depo"></use>
              </svg>
              <label className="balance-info__label">In Depo</label>
              <button type="button" className="balance-info__btn">
                <svg className="balance-info__dots">
                  <use xlinkHref="img/sprite.svg#dots"></use>
                </svg>
              </button>
            </div>
            <div className="balance-info__amount amount">
              <div className="amount__text">500 000
                <span className="amount__currency">BRT</span>
              </div>
              <div className="amount__change amount__change--down">
                <span className="amount__change-amount">28.4%</span>
                <svg className="amount__change-icon">
                  <use xlinkHref="img/sprite.svg#arrow-change"></use>
                </svg>
              </div>
            </div>
          </div>
          <div className="balance-info__item">
            <div className="balance-info__top">
              <svg className="balance-info__icon balance-info__icon--crowdfunding">
                <use xlinkHref="img/sprite.svg#bag"></use>
              </svg>
              <label className="balance-info__label">Crowdfunding dividends</label>
              <button type="button" className="balance-info__btn">
                <svg className="balance-info__dots">
                  <use xlinkHref="img/sprite.svg#dots"></use>
                </svg>
              </button>
            </div>
            <div className="balance-info__amount amount">
              <div className="amount__text">80 000
                <span className="amount__currency">BRT</span>
              </div>
              <div className="amount__change amount__change--up">
                <span className="amount__change-amount">28.4%</span>
                <svg className="amount__change-icon">
                  <use xlinkHref="img/sprite.svg#arrow-change"></use>
                </svg>
              </div>
            </div>
          </div>
          <div className="balance-info__item">
            <div className="balance-info__top">
              <svg className="balance-info__icon balance-info__icon--wallet">
                <use xlinkHref="img/sprite.svg#exchange"></use>
              </svg>
              <label className="balance-info__label">Wallet Address</label>
              <button type="button" className="balance-info__btn">
                <svg className="balance-info__dots">
                  <use xlinkHref="img/sprite.svg#dots"></use>
                </svg>
              </button>
            </div>
            <div className="balance-info__address">
              <span className="balance-info__address-text">0×24asda5s7da5s7fasf24aa1</span>
              <button type="button" className="balance-info__address-copy">
                <svg className="balance-info__address-copy-icon">
                  <use xlinkHref="img/sprite.svg#copy"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="dashboard__analytics dashboard-analytics">
          <div className="dashboard-analytics__main">
            <div className="dashboard-analytics__chart chart-section">
              <div className="chart-section__header">
                <div className="chart-section__total">
                  <h2 className="chart-section__title">
                    <svg className="chart-section__title-icon">
                      <use xlinkHref="img/sprite.svg#chart"></use>
                    </svg>
                    Total revenue
                  </h2>
                  <div className="chart-section__amount amount">
                    <div className="amount__text">$240.8K</div>
                    <div className="amount__change amount__change--up">
                      <span className="amount__change-amount">28.4%</span>
                      <svg className="amount__change-icon">
                        <use xlinkHref="img/sprite.svg#arrow-change"></use>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="chart-section__legend">
                  <span className="chart-section__legend-item chart-section__legend-item--revenue">Revenue</span>
                  <span className="chart-section__legend-item chart-section__legend-item--expenses">Expenses</span>
                  <button type="button" className="chart-section__calendar calendar">
                    <img className="calendar__icon" src="./img/svg/date.svg" alt=""/>

                    <span className="calendar__date">Jan 2024 — Dec 2024</span>
                    <svg className="calendar__arrow">
                      <use xlinkHref="img/sprite.svg#arrow"></use>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="chart-section__graph">
                <div className="chart-section__point amount-point">
                  <div className="amount-point__top">
                    <div className="amount-point__text">$240.8K</div>
                    <div className="amount-point__change amount-point__change--up">
                      <span className="amount-point__change-amount">12.5%</span>
                      <svg className="amount-point__change-icon">
                        <use xlinkHref="img/sprite.svg#arrow-change"></use>
                      </svg>
                    </div>
                  </div>
                  <span className="amount-point__date">Oct 29, 2024</span>
                </div>
                <img src="./img/graph.png" alt=""/>
              </div>
            </div>
            <div className="dashboard-analytics__wallet wallet-actions">
              <div className="wallet-actions__buttons">
                <button type="button" className="wallet-actions__button button">Send</button>
                <button type="button" className="wallet-actions__button button button--blue">Recieve</button>
                <button type="button" className="wallet-actions__button button button--blue">Swap</button>
              </div>
              <div className="wallet-actions__assets assets">
                <h2 className="assets__title">My actives</h2>
                <ul className="assets__items">
                  {
                    (balances) && Object.values(balances).map(network => {
                      return network.map(v => {
                        let svg = v.full_name.toLowerCase()
                        if (svg.includes("tether")) svg = svg.replace(" usd", "")
                        
                        return(
                          <li className="assets__item">
                            <div className="assets__item-crypto">
                              <div className="assets__item-crypto-logo">
                                <img src={`./img/cryptocurrency/${svg}.svg`} alt="" className="assets__item-crypto-img"/>
                              </div>
                              <div className="assets__item-crypto-info">
                                <span className="assets__item-crypto-name">{v.full_name}</span>
                                <span className="assets__item-crypto-amount">${v.price}</span>
                              </div>
                            </div>
                            <div className="assets__item-amount amount-assets">
                              <div className="amount-assets__text">{v.balance + " "}
                                <span className="amount-assets__currency">{v.symbol}</span>
                              </div>
                              {(v.symbol !== "USDT" && v.symbol !== "BRT") && 
                                <div className={`amount-assets__change amount-assets__change--${(v.price_24h_pcnt > 0 ? "up" : "down")}`}>
                                  <span className="amount-assets__change-amount">{v.price_24h_pcnt}</span>
                                  <svg className="amount-assets__change-icon">
                                    <use xlinkHref="img/sprite.svg#arrow-change"></use>
                                  </svg>
                                </div>
                              }
                            </div>
                          </li>
                        )
                      })
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="dashboard-analytics__transactions transactions">
            <div className="transactions__header">
              <h2 className="transactions__title">Transactions History</h2>
              <button type="button" className="transactions__calendar calendar">
                <img className="calendar__icon" src="./img/svg/date.svg" alt=""/>
                <span className="calendar__date">Jan 2024</span>
                <svg className="calendar__arrow">
                  <use xlinkHref="img/sprite.svg#arrow"></use>
                </svg>
              </button>
            </div>
            <div className="transactions__table transactions-table">
              <div className="transactions-table__content table__content">
                <div className="table__row">
                  <ul className="transactions-table__items table__items table__items--title">
                    <li className="table__item table__item--order">Order</li>
                    <li className="table__item table__item--date">Date</li>
                    <li className="table__item table__item--status">Status</li>
                    <li className="table__item">Total</li>
                  </ul>
                </div>
                <div className="transactions-table__body table__body">
                  
                  <div className="table__row">
                    <ul className="transactions-table__items table__items ">
                      <li className="table__item">
                        <div className="table__item-order">#1532</div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM</li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--pending">Pending</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>
                  <div className="table__row">
                    <ul className="transactions-table__items table__items table__items--paid">
                      <li className="table__item">
                        <div className="table__item-order table__item-order--paid">#1532</div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM</li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--paid">Paid</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>
                  <div className="table__row">
                    <ul className="transactions-table__items table__items ">
                      <li className="table__item">
                        <div className="table__item-order">#1532</div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM</li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--pending">Pending</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>
                  <div className="table__row">
                    <ul className="transactions-table__items table__items table__items--paid">
                      <li className="table__item">
                        <div className="table__item-order table__item-order--paid">#1532
                        </div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM</li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--paid">Paid</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>
                  <div className="table__row">
                    <ul className="transactions-table__items table__items ">
                      <li className="table__item">
                        <div className="table__item-order">#1532</div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM</li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--pending">Pending</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>
                  <div className="table__row">
                    <ul className="transactions-table__items table__items table__items--paid">
                      <li className="table__item">
                        <div className="table__item-order table__item-order--paid">
                          #1532</div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM
                      </li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--paid">Paid</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>
                  <div className="table__row">
                    <ul className="transactions-table__items table__items ">
                      <li className="table__item">
                        <div className="table__item-order">#1532</div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM
                      </li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--pending">Pending</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>
                  <div className="table__row">
                    <ul className="transactions-table__items table__items table__items--paid">
                      <li className="table__item">
                        <div className="table__item-order table__item-order--paid">#1532</div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM</li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--paid">Paid</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>
                  <div className="table__row">
                    <ul className="transactions-table__items table__items ">
                      <li className="table__item">
                        <div className="table__item-order">#1532</div>
                      </li>
                      <li className="table__item">Dec 30, 10:06 AM</li>
                      <li className="table__item">
                        <a href="#" className="table__item-status table__item-status--pending">Pending</a>
                      </li>
                      <li className="table__item"><span>$ 329.40</span></li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <button type="button" className="transactions__all">
              All Transactions
            </button>
          </div>
        </div>
      </main>
  )
}

export default Dashboard