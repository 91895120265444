/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  useGetBalances,
  useGetBrt,
  useGetStatistics,
  useGetTxs,
} from "../../services/dashboard.services";
import BalanceInfo from "./components/BalanceInfo";
import Analytics from "./components/Analytics";
import TransactionHistory from "./components/TransactionHistory";

const Dashboard = ({
  setSelectedPage,
}: {
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  // const navigate = useNavigate();
  const { balances } = useGetBalances();
  const { brt } = useGetBrt();

  const [userHistoryMonth, setUserHistoryMonth] = useState<Date | null>(
    new Date()
  );
  const [statisticsRange, setStatisticsRange] = useState<
    [Date | null, Date | null]
  >([
    moment().subtract(1, "months").startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);

  const getMonthRange = (date: Date | null) => {
    const start = moment(date).startOf("month").format("YYYY-MM-DD");
    const end = moment(date).endOf("month").format("YYYY-MM-DD");
    return { start, end };
  };

  const { start: historyStartDate, end: historyEndDate } =
    getMonthRange(userHistoryMonth);

  const statisticsStartDate = getMonthRange(statisticsRange[0]).start;
  const statisticsEndDate = getMonthRange(statisticsRange[1]).end;

  const { txs } = useGetTxs(historyStartDate, historyEndDate);
  const { statistics, refetch } = useGetStatistics(
    statisticsStartDate,
    statisticsEndDate
  );

  useEffect(() => {
    if (statisticsRange[0] && statisticsRange[1]) {
      refetch();
    }
  }, [statisticsRange]);

  return (
    <main className="main dashboard">
      <BalanceInfo brt={brt!} />
      <div className="dashboard__analytics dashboard-analytics">
        <Analytics
          balances={balances!}
          statistics={statistics!}
          setSelectedPage={setSelectedPage}
          setStatisticsRange={setStatisticsRange}
          statisticsRange={statisticsRange}
        />
        <TransactionHistory
          setUserHistoryMonth={setUserHistoryMonth}
          userHistoryMonth={userHistoryMonth}
          txs={txs!}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </main>
  );
};

export default Dashboard;
