import { useMutation, useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";
import { ISendForm } from "../types/types";

const sendFunds = async (data: ISendForm) => {
  return authApi.post("/dashboard/withdraw", data).then((res) => res.data);
};

const requestEmailCode = async () => {
  return authApi.post("/dashboard/withdraw/email").then((res) => res.data);
};

const getTransactionFee = async (data: { network: string; asset: string }) => {
  return authApi
    .get(`/dashboard/withdraw?asset=${data.asset}&network=${data.network}`)
    .then((res) => res.data);
};

export const useSendFunds = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["Send"],
    mutationFn: (data: ISendForm) => sendFunds(data),
    onSuccess,
    onError,
  });
};

export const useRequestEmailCode = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["RequestEmailCode"],
    mutationFn: requestEmailCode,
    onSuccess,
    onError,
  });
};

export const useGetTransactionFee = (data: {
  asset: string;
  network: string;
}) => {
  const {
    data: transactionInfo,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["WithdrawInfo"],
    queryFn: () => getTransactionFee(data),
    enabled: !!data.asset && !!data.network,
  });

  return {
    transactionInfo,
    isLoading,
    isError,
    refetch,
  };
};
