import { FC, useState, useRef, useEffect } from "react";
import { useGetSecurityLogs } from "../../services/securityLogs.services";
import moment from "moment";

const FILTER_TYPES = [
  { value: "UNUSUAL_IP_LOGIN", label: "Unusual IP Login" },
  { value: "MANY_LOGIN_ATTEMPTS", label: "Many Login Attempts" },
  { value: "REQUEST_LIMIT_EXCEED", label: "Request Limit Exceed" },
  { value: "DDOS", label: "DDOS" },
  { value: "XSS", label: "XSS" },
  { value: "SQL_INJECTION", label: "SQL Injection" },
  { value: "SYSTEM_ERRORS", label: "System Errors" },
] as const;

const ITEMS_PER_PAGE = 10;

const SecurityLogs: FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES[0]);
  // const [search, setSearch] = useState("");
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { data, isLoading } = useGetSecurityLogs({
    filter: selectedFilter.value,
    limit: ITEMS_PER_PAGE,
    offset: (currentPage * ITEMS_PER_PAGE).toString(),
    // ...(search && { search }),
  });

  const handleFilterSelect = (filter: (typeof FILTER_TYPES)[number]) => {
    setSelectedFilter(filter as any);
    setShowFilterDropdown(false);
  };

  const handleFilterDropdown = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setShowFilterDropdown((prev) => !prev);
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="users">
      <div className="users__table">
        <div className="table" style={{ minHeight: "80vh", height: "100%" }}>
          <div className="table__header">
            <h2 className="table__title">Security Logs</h2>
            <form
              className="table__inputs"
              onSubmit={(e) => e.preventDefault()}
            >
              {/* <div className="table__input-search input-search">
                <svg className="input-search__icon">
                  <use xlinkHref="img/sprite.svg#search"></use>
                </svg>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search..."
                  className="input-search__control"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </div> */}
              <div
                className={`table__filter-dropdown ${
                  showFilterDropdown ? "active" : ""
                }`}
              >
                <button
                  className="table__input-rank input-rank"
                  onClick={handleFilterDropdown}
                  type="button"
                >
                  <span className="input-rank__text">
                    {selectedFilter.label}
                  </span>
                  <svg className="input-rank__arrow">
                    <use xlinkHref="img/sprite.svg#arrow"></use>
                  </svg>
                </button>
                {showFilterDropdown && (
                  <div className="filter-dropdown__content">
                    {FILTER_TYPES.map((filter) => (
                      <button
                        key={filter.value}
                        className="filter-dropdown__item"
                        onClick={() => handleFilterSelect(filter)}
                        type="button"
                      >
                        {filter.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="table__body">
            <div className="table__row">
              <ul className="table__items table__items--title">
                <li className="table__item admin-table__item--id">ID</li>
                <li className="table__item">Date</li>
                <li className="table__item">Status</li>
                <li className="table__item">Type</li>
                <li className="table__item">Action</li>
                {/* <li className="table__item"></li> */}
              </ul>
            </div>
            {isLoading ? (
              <div className="table__loading">Loading...</div>
            ) : (
              data?.map((item: any) => (
                <div key={item?.id} className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="admin-table__item--id">#{item?.id}</div>
                    </li>
                    <li className="table__item">
                      {moment(item?.created_at).format("DD.MM.YYYY HH:mm")}
                    </li>
                    <li className="table__item">{item?.status}</li>
                    <li className="table__item">{item?.type}</li>
                    <li className="table__item">{item?.action}</li>
                    {/* <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li> */}
                  </ul>
                </div>
              ))
            )}
          </div>
          <div className="table__pagination">
            <button
              className="pagination__btn"
              onClick={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
              disabled={currentPage === 0}
            >
              Previous
            </button>
            <span className="pagination__current">Page {currentPage + 1}</span>
            <button
              className="pagination__btn"
              onClick={() => setCurrentPage((prev) => prev + 1)}
              disabled={!data || data.length < ITEMS_PER_PAGE}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityLogs;
