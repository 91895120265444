import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'


const Personal: FC  = () => {

  const navigate = useNavigate()

  return (
    <div className="settings__body">
    <div className="settings__card">
      <div className="settings__header">
        <h2 className="settings__title">Personal information</h2>
        <div className="settings__subtitle">Lorem ipsum dolor sit amet consectetur adipiscing.</div>
      </div>
      <form action="" className="settings__form form-settings">
        <div className="form-settings__item">
          <div className="form-settings__label">
            <svg className="form-settings__label-icon">
              <use xlinkHref="img/sprite.svg#user"></use>
            </svg>
            <span className="form-settings__label-text">Full name</span>
          </div>
          <input type="text" className="form-settings__input" value="John Carter"/>
        </div>
        <div className="form-settings__item">
          <div className="form-settings__label">
            <svg className="form-settings__label-icon">
              <use xlinkHref="img/sprite.svg#email"></use>
            </svg>
            <span className="form-settings__label-text">Email address</span>
          </div>
          <input type="text" className="form-settings__input" value="john@dashdark.com"/>
        </div>
        <div className="form-settings__photo">
          <div className="form-settings__label form-settings__label--photo">
            <svg className="form-settings__label-icon">
              <use xlinkHref="img/sprite.svg#image"></use>
            </svg>
            <span className="form-settings__label-text">Photo</span>
          </div>
          <div className="form-settings__src">
            <div className="form-settings__image">
              <div className="form-settings__ava">
                <img src="./img/person.png" alt="ava"/>
              </div>
              <button type="button" className="form-settings__photo-del">Delete</button>
            </div>
            <div className="form-settings__upload-photo upload-photo">
              <div className="upload-photo__img">
                <svg className="upload-photo__icon">
                  <use xlinkHref="img/sprite.svg#image"></use>
                </svg>
              </div>
              <div className="upload-photo__file">
                <div className="upload-photo__btn">
                  <input id="formImage" accept=".jpg, .png, .gif" type="file" name="image"
                    className="upload-photo__input"/>

                  <div className="upload-photo__input-btn"> <span>Click to upload</span> or drag and drop
                    SVG, PNG, JPG or GIF (max. 800×400px)</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
    <div className="settings__card">
      <div className="settings__header">
        <h2 className="settings__title">Basic information</h2>
        <div className="settings__subtitle">Lorem ipsum dolor sit amet consectetur adipiscing.</div>
      </div>

      <form action="" className="settings__form form-settings">
        <div className="form-settings__item">
          <div className="form-settings__label">
            <svg className="form-settings__label-icon">
              <use xlinkHref="img/sprite.svg#phone"></use>
            </svg>
            <span className="form-settings__label-text">Phone</span>
          </div>
          <input type="text" className="form-settings__input" value="(123) 456 - 7890"/>
        </div>
        <div className="form-settings__item">
          <div className="form-settings__label">
            <svg className="form-settings__label-icon">
              <use xlinkHref="img/sprite.svg#bag"></use>
            </svg>
            <span className="form-settings__label-text">Birthday</span>
          </div>
          <input type="text" className="form-settings__input" value="11.11.1991"/>
        </div>
        <div className="form-settings__item">
          <div className="form-settings__label">
            <svg className="form-settings__label-icon">
              <use xlinkHref="img/sprite.svg#address"></use>
            </svg>
            <span className="form-settings__label-text">Location</span>
          </div>
          <input type="text" className="form-settings__input" value="New York, NY"/>
        </div>
      </form>
    </div>
  </div>
  )
}

export default Personal 