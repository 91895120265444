import Cookies from "js-cookie";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

const Google = ({
  setSelectedPage,
}: {
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [secret, setSecret] = useState("");
  const [qr, setQr] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key >= "0" && e.key <= "9") {
      if (inputsRef.current[index]) {
        inputsRef.current[index]!.value = "";
      }
      if (index < inputsRef.current.length - 1) {
        setTimeout(() => inputsRef.current[index + 1]?.focus(), 10);
      }
    } else if (e.key === "Backspace") {
      if (index > 0) {
        setTimeout(() => inputsRef.current[index - 1]?.focus(), 10);
      }
    } else {
      e.preventDefault();
    }
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(/[^0-9]/g, "");
    e.currentTarget.value = value;
  };

  const generate = useCallback(async () => {
    try {
      const accessToken = Cookies.get("access_token");

      if (!accessToken) {
        throw new Error("No accessToken token available");
      }

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/2fa/generate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await res.json();

      if (res.status === 201 && data) {
        setSecret(data.secret);
        setQr(data.qr);
        setError(null);
      }
    } catch (error) {
      setSecret("");
      setQr("");
      console.log(error);
      toast.error("");
    }
  }, []);

  useEffect(() => {
    generate();
  }, [generate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (step === 1) {
      setStep(2);
    } else {
      const code = inputsRef.current
        .map((input) => input?.value || "")
        .join("");
      if (code.length === 6) {
        try {
          const accessToken = Cookies.get("access_token");

          if (!accessToken) {
            throw new Error("No accessToken token available");
          }

          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/auth/2fa/turn-on`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({ code }),
            }
          );
          const data = await res.json();

          if (res.status === 201 && data) {
            setError(null);
            setSelectedPage("Default");
            toast.success("Success");
          } else {
            toast.error(data["message"]);
          }
        } catch (error) {
          setError("Error");
        }
      } else {
        setError("Please enter a 6-digit code");
      }
    }
  };

  return (
    <form className="settings__form form-settings" onSubmit={handleSubmit}>
      {step === 1 ? (
        <>
          <div className="form-settings__verification verification">
            <div className="verification__header">
              <h2 className="verification__title">
                Turn on 2-Step Verification
              </h2>
              <p className="verification__subtitle">
                Open authenticator and choose scan barcode
              </p>
            </div>
            <div className="verification__qr">
              <img src={qr} alt="" />
            </div>
            <div className="verification__code">
              <label className="verification__code-label">
                Or enter the code manualy
              </label>
              <input
                type="text"
                className="verification__code-input"
                value={secret}
              />
            </div>
          </div>
          <button
            className="form-settings__button form-settings__button--verification button"
            type="submit"
          >
            Complete
          </button>
        </>
      ) : (
        <>
          <div className="form-settings__verification verification">
            <div className="verification__header">
              <h2 className="verification__title">
                Verify Authentication Code
              </h2>
              <p className="verification__subtitle verification__subtitle--code">
                Enter the 6-digit code
              </p>
            </div>
            <div className="verification__authentication authentication error">
              {error && <span className="error-text">{error}</span>}
              <div className="authentication__inputs ">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    className="authentication__input"
                    ref={(el) => (inputsRef.current[index] = el)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onInput={handleInput}
                  />
                ))}
              </div>
            </div>
          </div>
          <button
            className="form-settings__button form-settings__button--verification button"
            type="submit"
          >
            Complete
          </button>
        </>
      )}
    </form>
  );
};

export default Google;
