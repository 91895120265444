import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { IUpdatePassword } from "../../../types/types";
import { useUpdatePassword } from "../../../services/settings.services";
import { toast } from "react-toastify";

const Password: FC = () => {
  const { mutate: updatePassword, isPending } = useUpdatePassword(
    () => {
      toast.success("Password successfully updated!");
      reset();
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const UpdatePasswordFormSchema = yup.object().shape({
    old_password: yup.string().required("Old password is a required field!"),
    new_password: yup.string().required("New password is a required field!"),
    repeat_new_password: yup
      .string()
      .required("Confirm new password")
      .oneOf([yup.ref("new_password")], "Passwords must match"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm<IUpdatePassword>({
    resolver: yupResolver(UpdatePasswordFormSchema),
  });

  const onSubmit = (data: IUpdatePassword) => {
    updatePassword(data);
  };

  return (
    <div className="settings__body">
      <div className="settings__card">
        <div className="settings__header">
          <h2 className="settings__title">Change Password</h2>
          <div className="settings__subtitle">
            You can update your password here to enhance account security.
            Please enter your current password and set a new one.
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="settings__form form-settings"
        >
          <div className="form-settings__items">
            <div className="form-settings__item">
              <div className="form-settings__label">
                <svg className="form-settings__label-icon">
                  <use xlinkHref="img/sprite.svg#password"></use>
                </svg>
                <span className="form-settings__label-text">Old Password</span>
              </div>
              <div className="form-settings__input-wrapper">
                <Controller
                  name="old_password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      type="password"
                      className={`form-settings__input ${
                        errors.old_password?.message ? "error" : ""
                      }`}
                      placeholder="********"
                    />
                  )}
                />
                {errors.old_password && (
                  <p className="form-settings__input-error">
                    {errors.old_password.message}
                  </p>
                )}
              </div>
            </div>
            <div className="form-settings__item">
              <div className="form-settings__label">
                <svg className="form-settings__label-icon">
                  <use xlinkHref="img/sprite.svg#password"></use>
                </svg>
                <span className="form-settings__label-text">New Password</span>
              </div>
              <div className="form-settings__input-wrapper">
                <Controller
                  name="new_password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      type="password"
                      className={`form-settings__input ${
                        errors.new_password?.message ? "error" : ""
                      }`}
                      placeholder="********"
                    />
                  )}
                />
                {errors.new_password && (
                  <p className="form-settings__input-error">
                    {errors.new_password.message}
                  </p>
                )}
              </div>
            </div>
            <div className="form-settings__item">
              <div className="form-settings__label">
                <svg className="form-settings__label-icon">
                  <use xlinkHref="img/sprite.svg#password"></use>
                </svg>
                <span className="form-settings__label-text">
                  Repeat New Password
                </span>
              </div>
              <div className="form-settings__input-wrapper">
                <Controller
                  name="repeat_new_password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      type="password"
                      className={`form-settings__input ${
                        errors.repeat_new_password?.message ? "error" : ""
                      }`}
                      placeholder="********"
                    />
                  )}
                />
                {errors.repeat_new_password && (
                  <p className="form-settings__input-error">
                    {errors.repeat_new_password.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          <button
            disabled={!isValid || isPending}
            type="submit"
            className="form-settings__button button"
          >
            {isPending ? (
              <>
                <div className="spinner"></div>
              </>
            ) : (
              "Save"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Password;
