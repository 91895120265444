import { useMutation, useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";
import { ICrowdfundingForm } from "../types/types";

const joinToCrowdfundingPlan = async (data: ICrowdfundingForm) => {
  return authApi.post("/crowdfunding", data).then((res) => res.data);
};

const getUserCrowdfundingPlans = async () => {
  return authApi.get("/crowdfunding").then((res) => res.data);
};

const refundPersonalPlanAmount = async (id: string) => {
  return authApi.delete(`/crowdfunding/${id}`).then((res) => res.data);
};

export const useJoinToCrowdfundingPlan = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["JoinToCrowdfunding"],
    mutationFn: (data: ICrowdfundingForm) => joinToCrowdfundingPlan(data),
    onSuccess,
    onError,
  });
};

export const useGetUserCrowdfundingPlans = () => {
  const {
    data: userPlans,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["UserCrowdfundingPlans"],
    queryFn: getUserCrowdfundingPlans,
  });

  return {
    userPlans,
    isLoading,
    isError,
    refetch,
  };
};

export const useRefundPersonalPlanAmount = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["RefundPersonalPlan"],
    mutationFn: (id: string) => refundPersonalPlanAmount(id),
    onSuccess,
    onError,
  });
};
