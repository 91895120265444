import React, { useRef, useEffect, useState } from "react";

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  children: React.ReactNode;
  subtitle?: string;
}

const Modal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  title,
  children,
  subtitle,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [showAnimateClass, setShowAnimateClass] = useState(false);
  const previousActiveElement = useRef<Element | null>(null);

  useEffect(() => {
    if (isOpen) {
      previousActiveElement.current = document.activeElement;
      disableScroll();
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setShowAnimateClass(true);
      }, 500);
    } else {
      enableScroll();
      setShowAnimateClass(false);
      if (previousActiveElement.current) {
        (previousActiveElement.current as HTMLElement).focus();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
    document.documentElement.style.overflow = "";
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div ref={modalRef} className={`modal ${isOpen ? "is-open" : ""}`}>
      <div
        className={`modal__container ${isOpen ? "modal-open fadeInUp" : ""} ${
          showAnimateClass ? "animate-open" : ""
        }`}
        role="dialog"
        aria-modal="true"
      >
        <button
          onClick={handleCloseModal}
          type="button"
          className="modal__close js-modal-close"
          aria-label="close modal"
        >
          <svg className="modal__close-icon">
            <use xlinkHref="img/sprite.svg#close"></use>
          </svg>
        </button>
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
          {subtitle && <p className="modal__subtitle">{subtitle}</p>}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
