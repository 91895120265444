/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Input from "../../components/Input/Input";
import { IInput } from "../../components/Input/input.types";
import { useGetTokenCredentials } from "../../services/receive.services";
import { useGetSwapAvailableAssets } from "../../services/swap.services";
import { IBalance } from "../../types/types";

interface Inputs {
  input: IInput;
  group?: string;
}

const Recieve = () => {
  const [inputs, setInputs] = useState<Inputs[]>([
    {
      input: {
        type: "dropdown",
        id: "network",
        svg: "",
        value: "",
        values: [],
        placeholder: "Network",
        error: undefined,
      },
      group: "Select Network",
    },
    {
      input: {
        type: "dropdown",
        id: "asset",
        svg: "",
        value: "",
        values: [],
        placeholder: "Asset",
        error: undefined,
      },
      group: "Select Asset",
    },
    {
      input: {
        type: "copy",
        id: "address",
        svg: "copy",
        value: "",
        placeholder: "Address",
        regex: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
        error: undefined,
      },
    },
  ]);

  const [assetValue, setAssetValue] = useState("");
  const [networkValue, setNetworkValue] = useState("");
  const [depositAddress, setDepositAddress] = useState<string>("");
  const [addressQr, setAddressQr] = useState<string>("");

  const { tokenCredentials, refetch } = useGetTokenCredentials(
    assetValue,
    networkValue
  );
  const { availableAssets } = useGetSwapAvailableAssets();

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs((prevInputs) =>
      prevInputs.map((v) => {
        if (v.input.type !== "multitext" && v.input.id === input_id) {
          v.input.value = value;
        }
        return v;
      })
    );

    if (input_id === "network" && typeof value === "string") {
      setNetworkValue(value);
      const selectedAsset = inputs.find(
        (input) => (input.input as any).id === "network"
      )?.input;
      if (
        selectedAsset &&
        selectedAsset.type === "dropdown" &&
        availableAssets
      ) {
        const networks =
          availableAssets[value]?.map((item: IBalance) => item.symbol) || [];
        setInputValues("asset", networks);
        setInputValue("asset", "");
        setAssetValue("");
      }
    }

    if (input_id === "asset" && typeof value === "string") {
      setAssetValue(value);
    }
  };

  const setInputValues = (input_id: string, values: string[]) => {
    setInputs((prevInputs) =>
      prevInputs.map((v) => {
        if (v.input.type === "dropdown" && v.input.id === input_id) {
          v.input.values = values;
        }
        return v;
      })
    );
  };

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs((prevInputs) =>
      prevInputs.map((v) => {
        if (v.input.type !== "multitext" && v.input.id === input_id) {
          v.input.error = error.toString();
        }
        return v;
      })
    );
  };

  useEffect(() => {
    if (availableAssets) {
      const networks = Object.keys(availableAssets);
      setInputValues("network", networks);
    }
  }, [availableAssets]);

  useEffect(() => {
    if (tokenCredentials) {
      setDepositAddress(tokenCredentials.address);
      setAddressQr(tokenCredentials.qr);
    } else {
      setDepositAddress("");
      setAddressQr("");
    }
  }, [tokenCredentials]);

  useEffect(() => {
    if (assetValue && networkValue) {
      refetch();
    }
  }, [assetValue, networkValue]);

  const renderFormBlock = (title: string, group: string) => (
    <div className="order-form__block">
      <h3 className="order-form__title">{title}</h3>
      <div className="order-form__items">
        {inputs
          .filter((v) => v.group === group)
          .map((v, i) => (
            <Input
              key={i}
              input={v.input}
              setInputValue={setInputValue}
              setInputError={setInputError}
            />
          ))}
      </div>
    </div>
  );

  const renderAddressBlock = () => {
    if (!assetValue || !networkValue) return null;

    if (assetValue === "BRT") {
      return (
        <div className="order-form__block">
          <p className="send-bruno-warning">
            BRT can only be received via the registered user's email!
          </p>
        </div>
      );
    }

    return (
      <div className="order-form__block">
        <div className="order-form__qr">
          <img src={addressQr} alt="" />
        </div>
        <h3 className="order-form__title">Address</h3>
        <div className="order-form__items">
          <Input
            input={{
              type: "copy",
              id: "address",
              svg: "copy",
              value: depositAddress,
              placeholder: "Address",
              regex: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
              error: undefined,
            }}
            setInputValue={setInputValue}
          />
        </div>
      </div>
    );
  };

  return (
    <main className="main exchange">
      <div className="exchange__order">
        <h2 className="exchange__order-title">Receive Crypto</h2>
        <form action="" className="exchange__order-form form">
          <div className="order-form__body">
            {renderFormBlock("Select Network", "Select Network")}
            {renderFormBlock("Select Asset", "Select Asset")}
            {renderAddressBlock()}
          </div>
        </form>
      </div>
    </main>
  );
};

export default Recieve;
