import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUpdatePersonalInfoForm } from "../../../types/types";
import {
  useGetPersonalInfo,
  useGetUserAvatar,
  useUpdatePersonalInfo,
} from "../../../services/settings.services";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { authApi } from "../../../api/api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Personal: FC = () => {
  const { mutate: updatePersonalInfo, isPending } = useUpdatePersonalInfo();

  const [startDate, setStartDate] = useState(new Date());
  const [avatar, setAvatar] = useState<string>("./img/person.png");

  const { personalInfo } = useGetPersonalInfo();
  const { userAvatar, refetch } = useGetUserAvatar(personalInfo?.photo_name!);

  const UpdatePersonalInfoFormSchema = yup.object().shape({
    full_name: yup.string().required("Full name is a required field!"),
    phone: yup.string().required("Phone is a required field!"),
    birthdate: yup.string().required("Birthdate is a required field!"),
    address: yup.string().required("Location is a required field!"),
  });

  const returnFormSchema = useForm<IUpdatePersonalInfoForm>({
    resolver: yupResolver(UpdatePersonalInfoFormSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = returnFormSchema;

  const handleSubmitForm = (data: IUpdatePersonalInfoForm) => {
    updatePersonalInfo(data, {
      onSuccess: () => {
        reset(data);
        toast.success("Information updated!");
      },
      onError: (data) => {
        toast.error(data.response.data.message);
      },
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("photo", file);

      try {
        const response = await authApi.post("/users/photo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data) {
          toast.success("Photo uploaded successfully!");
        } else {
          toast.error("Failed to upload photo.");
        }
      } catch (error) {
        toast.error("An error occurred while uploading the photo.");
      }
    }
  };

  useEffect(() => {
    if (personalInfo) {
      reset({
        full_name: personalInfo.full_name,
        address: personalInfo.location,
        phone: personalInfo.phone,
        birthdate: new Date(personalInfo.birthday).toLocaleString(),
      });
      refetch();
      setStartDate(new Date(personalInfo.birthday));
    }
  }, [personalInfo, reset, refetch]);

  useEffect(() => {
    if (personalInfo?.photo_name && personalInfo?.photo_name?.length > 0) {
      setAvatar(userAvatar!);
    }
  }, [personalInfo, userAvatar]);

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} className="settings__body">
      <div className="settings__card">
        <div className="settings__header">
          <h2 className="settings__title">Personal information</h2>
          <div className="settings__subtitle">
            Manage your personal details and keep your information up to date.
          </div>
        </div>
        <div className="settings__form form-settings">
          <Controller
            name="full_name"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className="form-settings__item">
                <div className="form-settings__label">
                  <svg className="form-settings__label-icon">
                    <use xlinkHref="img/sprite.svg#user"></use>
                  </svg>
                  <span className="form-settings__label-text">Full name</span>
                </div>
                <div className="form-settings__input-wrapper">
                  <input
                    type="text"
                    className={`form-settings__input ${
                      errors.full_name?.message ? "error" : ""
                    }`}
                    value={value}
                    onChange={onChange}
                    maxLength={60}
                    placeholder="John Johns"
                  />

                  <span className="form-settings__input-error">
                    {errors.full_name?.message}
                  </span>
                </div>
              </div>
            )}
          />
          <div className="form-settings__item">
            <div className="form-settings__label">
              <svg className="form-settings__label-icon">
                <use xlinkHref="img/sprite.svg#email"></use>
              </svg>
              <span className="form-settings__label-text">Email address</span>
            </div>
            <div className="form-settings__input-wrapper">
              <input
                type="email"
                className={`form-settings__input`}
                placeholder="example@gmail.com"
                maxLength={50}
                value={personalInfo?.email}
                disabled
              />
            </div>
          </div>

          <div className="form-settings__photo">
            <div className="form-settings__label form-settings__label--photo">
              <svg className="form-settings__label-icon">
                <use xlinkHref="img/sprite.svg#image"></use>
              </svg>
              <span className="form-settings__label-text">Photo</span>
            </div>
            <div className="form-settings__src">
              <div className="form-settings__image">
                <div className="form-settings__ava">
                  <img src={avatar} alt="ava" />
                </div>
                <button type="button" className="form-settings__photo-del">
                  Delete
                </button>
              </div>
              <div className="form-settings__upload-photo upload-photo">
                <div className="upload-photo__img">
                  <svg className="upload-photo__icon">
                    <use xlinkHref="img/sprite.svg#image"></use>
                  </svg>
                </div>
                <div className="upload-photo__file">
                  <div className="upload-photo__btn">
                    <input
                      id="formImage"
                      accept=".jpg, .png, .gif"
                      type="file"
                      name="image"
                      onChange={handleFileChange}
                      className="upload-photo__input"
                    />

                    <div className="upload-photo__input-btn">
                      <span>Click to upload</span> or drag and drop SVG, PNG,
                      JPG or GIF (max. 800×400px)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="settings__card">
        <div className="settings__header">
          <h2 className="settings__title">Basic information</h2>
          <div className="settings__subtitle">
            Ensure your basic details are accurate for better service.
          </div>
        </div>

        <div className="settings__form form-settings">
          <Controller
            name="phone"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className="form-settings__item">
                <div className="form-settings__label">
                  <svg className="form-settings__label-icon">
                    <use xlinkHref="img/sprite.svg#phone"></use>
                  </svg>
                  <span className="form-settings__label-text">Phone</span>
                </div>
                <div className="form-settings__input-wrapper">
                  <PhoneInput
                    country={"us"}
                    value={value}
                    onChange={onChange}
                    inputClass={`form-settings__input ${
                      errors.phone?.message ? "error" : ""
                    }`}
                    containerClass="phone-input-container"
                    buttonClass="phone-input-button"
                    dropdownClass="phone-input-dropdown"
                    searchClass="phone-input-search"
                    placeholder="+1 (234) 567-8900"
                  />
                  <span className="form-settings__input-error">
                    {errors.phone?.message}
                  </span>
                </div>
              </div>
            )}
          />
          <Controller
            name="birthdate"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className="form-settings__item">
                <div className="form-settings__label">
                  <svg className="form-settings__label-icon">
                    <use xlinkHref="img/sprite.svg#bag"></use>
                  </svg>
                  <span className="form-settings__label-text">Birthday</span>
                </div>
                <div className="form-settings__input-wrapper">
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date | null) => {
                      setStartDate(date!);
                      onChange(date?.toISOString().split("T")[0]);
                    }}
                    className={`form-settings__input ${
                      errors.birthdate?.message ? "error" : ""
                    }`}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                  />
                  <span className="form-settings__input-error">
                    {errors.birthdate?.message}
                  </span>
                </div>
              </div>
            )}
          />
          <Controller
            name="address"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className="form-settings__item">
                <div className="form-settings__label">
                  <svg className="form-settings__label-icon">
                    <use xlinkHref="img/sprite.svg#address"></use>
                  </svg>
                  <span className="form-settings__label-text">Location</span>
                </div>
                <div className="form-settings__input-wrapper">
                  <input
                    type="text"
                    className={`form-settings__input ${
                      errors.address?.message ? "error" : ""
                    }`}
                    value={value}
                    onChange={onChange}
                    placeholder="New York, NY"
                  />
                  <span className="form-settings__input-error">
                    {errors.address?.message}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <button
        type="submit"
        className="button"
        style={{ maxWidth: 150 }}
        disabled={!isDirty || isPending}
      >
        {isPending ? <div className="spinner"></div> : "Save"}
      </button>
    </form>
  );
};

export default Personal;
