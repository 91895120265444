/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeroContent from "../../components/HeroContent/HeroContent";
import { socials } from "../../const/socials";

const ConfirmMail = () => {
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.size > 0) {
      const token = searchParams.get("token");
      if (token) {
        console.log(token);
        fetch(process.env.REACT_APP_API_URL + "/auth/confirm_mail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        }).then((req) =>
          req.json().then((res) => {
            if (req.status === 201) {
              setIsError(false);
            }
          })
        );
      }
    }
  }, [searchParams]);

  return (
    <>
      <div className="register">
        <div className="register__hero">
          <div className="hero">
            <div></div>
            <div className="hero__decor">
              <picture>
                <source
                  srcSet="./img/background/decor.webp"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  src="./img/background/decor.png"
                  className="hero__image"
                  width=""
                  height=""
                  alt=""
                />
              </picture>
            </div>

            <HeroContent />
          </div>
        </div>
        <main className="register__main">
          <div className="register__logo">
            <img src="./img/logo.svg" alt="logo" />
          </div>
          <div className="register__body">
            <h1 className="register__title">
              {isError ? "Error!" : "Congrats!"}
            </h1>
            <form className="register__form">
              <p style={{ paddingBottom: "10px" }}>
                {isError
                  ? "Something went wrong. Please try again later"
                  : "You've successfully confirmed your e-mail address"}
              </p>
              <button
                onClick={() => navigate("/login")}
                type="button"
                className="register__button button"
              >
                Sign In
              </button>
            </form>
          </div>
          <div className="register__socials">
            <ul className="socials">
              {socials.map((icon) => (
                <li className="socials__item" key={icon.id}>
                  <a
                    href={icon.href}
                    rel="noreferrer"
                    target="_blank"
                    className="socials__link"
                  >
                    <svg className="socials__icon">
                      <use xlinkHref={`img/sprite.svg#${icon.id}`}></use>
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </main>
      </div>
    </>
  );
};

export default ConfirmMail;
