import React, { FC, useEffect, useState } from 'react'
import Histogram from '../../components/Histogram/Histogram'

interface IUserCounts {
  total: number,
  userCounts: Array<any>,
  percentageChange: number
}

const Users : FC = () => {

  const [usersMonth, setUsersMonth] = useState("")

  const [onlyAdmins, setOnlyAdmins] = useState(false)
  const [onlyUsers, setOnlyUsers] = useState(false)

  const [userCounts, setUserCounts] = useState<IUserCounts>({ total: 0, percentageChange: 0, userCounts: []})
  const [activeUserCounts, setActiveUserCounts] = useState<IUserCounts>({ total: 0, percentageChange: 0, userCounts: []})

  const getUsersCount = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/users/count", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
    const result = await response.json()

    return result
  } 

  const getActiveUsersCount = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/users/count/active", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
    const result = await response.json()

    return result
  }

  const getUsers = async () => {

    const params = new URLSearchParams({
        sel_month: usersMonth,
        only_admins: "",
        only_users: ""
    })

    const response = await fetch(process.env.REACT_APP_API_URL + "/users?" + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
    const result = await response.json()

    return result
  }

  useEffect(() => {
    getUsersCount().then(res => setUserCounts(res))
    getActiveUsersCount().then(res => setActiveUserCounts(res))

    const date = new Date();
    setUsersMonth(date.toLocaleDateString('en-US', { month: 'short', year: 'numeric'}))

    

    return () => {}
  }, [])

  useEffect(() => {
    getUsers()
  }, [usersMonth, onlyAdmins, onlyUsers])

  return (
    <>
      <div className="users">
          <div className="users__charts ">
            <div style={{ display: "flex", flexDirection: "column", minHeight: "270px"}} className="users__chart chart-section">
              <div className="chart-section__header">
                <div className="chart-section__total">
                  <h2 className="chart-section__title">Total users</h2>
                  <div className="chart-section__amount amount">
                    <div className="amount__text">{userCounts.total}</div>
                    {(userCounts.percentageChange !== 0) && <div className={`amount__change amount__change--${userCounts.percentageChange > 0 ? "up" : "down"}`}>
                      <span className="amount__change-amount">{userCounts.percentageChange}%</span>
                      <svg className="amount__change-icon">
                        <use xlinkHref="img/sprite.svg#arrow-change"></use>
                      </svg>
                    </div>}
                  </div>
                </div>
                <div className="chart-section__legend">
                  <span className="chart-section__legend-item chart-section__legend-item--current">Current clients</span>
                  <button className="chart-section__calendar calendar">
                    <img className="calendar__icon" src="./img/svg/date.svg" alt=""/>
                    <span className="calendar__date">Jan 2024 — Dec 2024</span>
                    <svg className="calendar__arrow">
                      <use xlinkHref="img/sprite.svg#arrow"></use>
                    </svg>
                  </button>
                </div>
              </div>
              <div style={{ flexGrow: 1}} className="chart-section__graph">
                <Histogram data={userCounts.userCounts} />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", minHeight: "270px"}} className="users__chart chart-section">
              <div className="chart-section__header">
                <div className="chart-section__total">
                  <h2 className="chart-section__title">Active users</h2>
                  <div className="chart-section__amount amount">
                    <div className="amount__text">{activeUserCounts.total}</div>
                    {(activeUserCounts.percentageChange !== 0) && <div className={`amount__change amount__change--${activeUserCounts.percentageChange > 0 ? "up" : "down"}`}>
                      <span className="amount__change-amount">{activeUserCounts.percentageChange}%</span>
                      <svg className="amount__change-icon">
                        <use xlinkHref="img/sprite.svg#arrow-change"></use>
                      </svg>
                    </div>}
                  </div>
                </div>
                <div className="chart-section__legend">
                  <span className="chart-section__legend-item chart-section__legend-item--active">Active clients</span>
                  <button className="chart-section__calendar calendar">
                    <img className="calendar__icon" src="./img/svg/date.svg" alt=""/>
                    <span className="calendar__date">Jan 2024 — Dec 2024</span>
                    <svg className="calendar__arrow">
                      <use xlinkHref="img/sprite.svg#arrow"></use>
                    </svg>
                  </button>
                </div>
              </div>
              <div style={{ flexGrow: 1}} className="chart-section__graph">
                <Histogram data={userCounts.userCounts} />
              </div>
            </div>
          </div>
          <div className="users__table ">
            <div className="table">
            <div className="table__header">
              <h2 className="table__title">Users list</h2>
              <div className="table__filter">
                <div className="table__checkbox checkbox">
                  <input onClick={() => setOnlyAdmins(prev => !prev)} id="admins" type="checkbox" className="checkbox__input" checked={onlyAdmins}/>
                  <label htmlFor="admins" className="checkbox__label">Admins</label>
                </div>
                <div className="table__checkbox checkbox">
                  <input onClick={() => setOnlyUsers(prev => !prev)} id="users" type="checkbox" className="checkbox__input" checked={onlyUsers}/>
                  <label htmlFor="users" className="checkbox__label">Users</label>
                </div>
              </div>
              <form className="table__inputs">
                <div className="table__input-search input-search">
                  <svg className="input-search__icon">
                    <use xlinkHref="img/sprite.svg#search"></use>
                  </svg>
                  <input type="text" name="" value="" placeholder="ID, Name" className="input-search__control"/>
                </div>
                
              </form>
              <div className="table__actions">
                <button className="table__calendar calendar">
                  <img className="calendar__icon" src="./img/svg/date.svg" alt=""/>
                  <span className="calendar__date">{usersMonth}</span>
                  <svg className="calendar__arrow">
                    <use xlinkHref="img/sprite.svg#arrow"></use>
                  </svg>
                </button>
                <div className="table__button button">Add admin</div>
              </div>

            </div>
            <div className="table__body">
              <div className="table__row">
                <ul className="table__items table__items--users table__items--title">
                  <li className="table__item table__item--id">ID</li>
                  <li className="table__item">Client</li>
                  <li className="table__item">User type</li>
                  <li className="table__item">Deposit</li>
                  <li className="table__item">Referals</li>
                  <li className="table__item"> Balance</li>
                  <li className="table__item"></li>
                </ul>
              </div>
              <div className="table__row">
                <ul className="table__items table__items--users">
                  <li className="table__item">
                    <div className="table__item-id table__item-id--check">#1532</div>
                  </li>
                  <li className="table__item">
                    <div className="table__item-client">
                      John Carter <span>hello@johncarter.com</span>
                    </div>
                  </li>
                  <li className="table__item">User</li>
                  <li className="table__item">$ 1,099.24</li>
                  <li className="table__item">36</li>
                  <li className="table__item">$ 1,099.24</li>
                  <li className="table__item">
                    <div className="table__item-actions">
                      <button className="table__item-btn button button--transparent unlock">Unlock</button>
                      <button className="table__item-edit">
                        <svg className="table__item-edit-icon">
                          <use xlinkHref="img/sprite.svg#edit"></use>
                        </svg>
                      </button>
                      <button className="table__item-basket">
                        <svg className="table__item-basket-icon">
                          <use xlinkHref="img/sprite.svg#basket"></use>
                        </svg>
                      </button>

                      
                    </div>
                  </li>
                </ul>
              </div>
              <div className="table__row">
                <ul className="table__items table__items--users">
                  <li className="table__item">
                    <div className="table__item-id">#1532</div>
                  </li>
                  <li className="table__item">
                    <div className="table__item-client">
                      John Carter <span>hello@johncarter.com</span>
                    </div>
                  </li>
                  <li className="table__item">User</li>
                  <li className="table__item">$ 1,099.24</li>
                  <li className="table__item">36</li>
                  <li className="table__item">$ 1,099.24</li>
                  <li className="table__item">
                    <div className="table__item-actions">
                      <button className="table__item-btn button button--blue lock">Lock</button>
                      <button className="table__item-edit">
                        <svg className="table__item-edit-icon">
                          <use xlinkHref="img/sprite.svg#edit"></use>
                        </svg>
                      </button>
                      <button className="table__item-basket">
                        <svg className="table__item-basket-icon">
                          <use xlinkHref="img/sprite.svg#basket"></use>
                        </svg>
                      </button>

                      
                    </div>
                  </li>
                </ul>
              </div>

            </div>
            </div>

          </div>
        </div>
    </>
  )
}

export default Users