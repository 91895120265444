import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

interface IProps {
  onSubmit: (data: { type: string; code: string; agreement: boolean }) => void;
  returnFormInstance: UseFormReturn<{
    type: string;
    code: string;
    agreement: boolean;
  }>;
}

const AvalancheTerms: React.FC<IProps> = ({ onSubmit, returnFormInstance }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="order form">
      <div
        className="order__items"
        style={{
          marginTop: 20,
        }}
      >
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <div className="order__item form__item">
              <div className="order__input form__input input">
                <input
                  {...field}
                  type="text"
                  placeholder="Referral Code"
                  className="input__control"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {errors.code?.message && (
                  <span className="error-text">{errors.code.message}</span>
                )}
              </div>
            </div>
          )}
        />

        <Controller
          name="agreement"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="register__checkbox checkbox">
              <input
                id={`formAgreement-avalanche`}
                type="checkbox"
                checked={value}
                onChange={(e) => {
                  onChange(!e.target.checked);
                  e.preventDefault();
                }}
                className="checkbox__input"
              />
              <label
                htmlFor={`formAgreement-avalanche`}
                className="checkbox__label"
                onClick={(e) => {
                  const target = e.target as HTMLElement;

                  if (target.tagName === "A") {
                    e.stopPropagation();
                  } else {
                    onChange(!value);
                  }
                }}
              >
                <div
                  className="checkbox__label-text"
                  style={{
                    maxWidth: 280,
                  }}
                >
                  I certify that I am 18 years of age or older, and I agree
                  to the <a href="/">Terms of Agreement</a>,
                  <a href="/"> Privacy Policy</a> and{" "}
                  <a href="/">KYC & AML Policy</a>
                </div>
              </label>
            </div>
          )}
        />

        <button
          type="button"
          onClick={handleSubmit(onSubmit)}
          className="terms__button button"
        >
          Join
        </button>
      </div>
    </form>
  );
};

export default AvalancheTerms;
