import React, { FC, useEffect, useState } from 'react'

const SecurityLogs : FC = () => {

  return (
    <div className="users">
          <div className="users__table ">
            <div className="table">
              <div className="table__header">
                <h2 className="table__title">Users list</h2>
                <div className="table__filter">
                  <div className="table__checkbox checkbox">
                    <input id="admins" type="checkbox" className="checkbox__input" checked/>
                    <label htmlFor="admins" className="checkbox__label">Admins</label>
                  </div>
                  <div className="table__checkbox checkbox">
                    <input id="users" type="checkbox" className="checkbox__input" checked/>
                    <label htmlFor="users" className="checkbox__label">Users</label>
                  </div>
                  <div className="table__checkbox checkbox">
                    <input id="moders" type="checkbox" name="fees" className="checkbox__input" checked/>
                    <label htmlFor="moders" className="checkbox__label">Moders</label>
                  </div>
                </div>
                <form className="table__inputs">
                  <div className="table__input-search input-search">
                    <svg className="input-search__icon">
                      <use xlinkHref="img/sprite.svg#search"></use>
                    </svg>
                    <input type="text" name="" value="" placeholder="ID, Name" className="input-search__control"/>
                  </div>
                  <button className="table__input-rank input-rank">
                    <span className="input-rank__text">Rank Filter</span>
                    <svg className="input-rank__arrow">
                      <use xlinkHref="img/sprite.svg#arrow"></use>
                    </svg>
                  </button>
                </form>
              </div>
              <div className="table__body">
                <div className="table__row">
                  <ul className="table__items table__items--title">
                    <li className="table__item table__item--id">ID</li>
                    <li className="table__item">Date</li>
                    <li className="table__item">Status</li>
                    <li className="table__item">Type</li>
                    <li className="table__item">Action</li>
                    <li className="table__item"></li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id table__item-id--check">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">DDoS</li>
                    <li className="table__item">1234</li>
                    <li className="table__item">No actions</li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Crack</li>
                    <li className="table__item">1234</li>
                    <li className="table__item">Block user acc﻿aunt</li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id table__item-id--check">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Penetration AP</li>
                    <li className="table__item">1234</li>
                    <li className="table__item">Block Admin Account</li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Unusual login location</li>
                    <li className="table__item">1234</li>
                    <li className="table__item">Block Admin Accounts</li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
  )
}

export default SecurityLogs