import moment from "moment";
import React, { useState, useMemo } from "react";
import { IBrt, ICrowdfundingForm } from "../../types/types";
import { Controller, UseFormReturn } from "react-hook-form";

interface IProps {
  brt: IBrt;
  onSubmit: (data: ICrowdfundingForm) => void;
  returnFormInstance: UseFormReturn<ICrowdfundingForm>;
  interestRate: number;
  term: string;
  depositType: string;
  buttonText: string;
}

const CrowdfundingPlan: React.FC<IProps> = ({
  brt,
  onSubmit,
  returnFormInstance,
  interestRate,
  term,
  depositType,
  buttonText,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = returnFormInstance;

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = () => {
    setIsDropdownOpen(false);
  };

  const amount = watch("amount");
  const payoutFrequency = watch("payout_frequency", "Monthly");

  const calculatedInterestRate = useMemo(() => {
    if (payoutFrequency === "Daily") {
      return `${(interestRate / 30).toFixed(4)}%`;
    }
    return `${interestRate}%`;
  }, [payoutFrequency, interestRate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="order form">
      <div className="order__items">
        <Controller
          name="amount"
          control={control}
          render={({ field }) => (
            <div className="order__item form__item" style={{ marginBottom: 0 }}>
              <div className="order__input form__input input">
                <input
                  {...field}
                  type="number"
                  placeholder="Minimal: 100 BRT"
                  className="input__control"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {errors.amount?.message && (
                  <span className="error-text">{errors.amount.message}</span>
                )}
                <span
                  className="form__item-text-bottom"
                  style={{ marginLeft: "auto" }}
                >
                  Balance: {brt.total} BRT
                </span>
              </div>
            </div>
          )}
        />
        <Controller
          name="payout_frequency"
          control={control}
          render={({ field }) => (
            <div className="order__item form__item">
              <div className="custom-dropdown">
                <button
                  type="button"
                  className="form__control form-control"
                  onClick={toggleDropdown}
                >
                  <p className="form-control__text">{field.value}</p>
                  <svg className="form-control__arrow">
                    <use xlinkHref="img/sprite.svg#arrow"></use>
                  </svg>
                </button>
                {isDropdownOpen && (
                  <ul
                    className={`dropdown-menu ${isDropdownOpen ? "open" : ""}`}
                  >
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        field.onChange("Daily");
                        handleOptionClick();
                      }}
                    >
                      Daily
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        field.onChange("Monthly");
                        handleOptionClick();
                      }}
                    >
                      Monthly
                    </li>
                  </ul>
                )}
              </div>
            </div>
          )}
        />
        <ul className="order__details order-details">
          <li className="order-details__item order-details__item--header">
            <h4 className="order-details__title">You deposit</h4>
            <span className="order-details__value">{amount || 0} BRT</span>
          </li>
          <li className="order-details__item">
            <h4 className="order-details__title">Interest rate</h4>
            <span className="order-details__value">
              {calculatedInterestRate}
            </span>
          </li>
          <li className="order-details__item">
            <h4 className="order-details__title">Term:</h4>
            <span className="order-details__value">{term}</span>
          </li>
          <li className="order-details__item">
            <h4 className="order-details__title">Type:</h4>
            <span className="order-details__value">{depositType}</span>
          </li>
          <li className="order-details__item">
            <h4 className="order-details__title">Date/Time:</h4>
            <span className="order-details__value">
              {moment(new Date()).format("DD.MM.YYYY hh:mm")}
            </span>
          </li>
        </ul>
      </div>
      <button type="submit" className="order__button button">
        {buttonText}
      </button>
    </form>
  );
};

export default CrowdfundingPlan;
