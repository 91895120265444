/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import HeroContent from "../../components/HeroContent/HeroContent";
import Input from "../../components/Input/Input";
import { IInput } from "../../components/Input/input.types";
import { socials } from "../../const/socials";
import { AuthContext } from "../../contexts/AuthContext";
import { useAuth } from "../../hooks/useAuth";
import { LoginArguments } from "../../types/types";

const Login = () => {
  const data = useContext(AuthContext);
  const [inputs, setInputs] = useState<Array<IInput>>([
    {
      type: "text",
      id: "email",
      svg: "email",
      value: "",
      placeholder: "E-mail",
      regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      error: "",
    },
    {
      type: "password",
      id: "password",
      svg: "password",
      value: "",
      placeholder: "Password",
      repeat: false,
      show: false,
      error: undefined,
    },
  ]);
  const [token, setToken] = useState<null | string>(null);
  const [code, setCode] = useState<null | string>(null);

  const navigate = useNavigate();
  const { login } = useAuth();

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.type !== "multitext") {
          if (v.id === input_id) {
            v.value = value;
            if (v.type === "date") {
              if (v.value === "") {
                v.error = "Can't be empty";
              } else {
                v.error = "";
              }
            }
          }
        }
        return v;
      })
    );
  };

  const setPasswordHide = (input_id: string, show: boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.type === "password" && v.id === input_id) {
          v.show = show;
        }
        return v;
      })
    );
  };

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.type !== "multitext") {
          if (v.id === input_id) {
            v.error = error.toString();
          }
        }
        return v;
      })
    );
  };

  const onButtonClick = async () => {
    let hasErrors = false;
    let curValues: LoginArguments = { email: "", password: "" };

    const newInputs = inputs.map((v) => {
      if (v.type !== "multitext") {
        if (v.value === "") {
          v.error = "Can't be empty";
          hasErrors = true;
        }

        if (v.error) {
          hasErrors = true;
        }
        curValues[v.id] = v.value.toString();
      }
      return v;
    });

    setInputs(newInputs);

    // if (!token) {
    //   toast.error("Recaptcha error");
    //   hasErrors = true;
    // }

    if (!hasErrors) {
      if (process.env.REACT_APP_API_URL) {
        console.log(curValues);
        await login(curValues);
      }
    }
  };

  const onChange = (token: string | null) => {
    setToken(token);
  };

  useEffect(() => {
    if (data?.is2FA) {
      setInputs((prev) => [
        ...prev,
        {
          type: "password",
          id: "code",
          svg: "password",
          value: "",
          placeholder: "2FA",
          repeat: false,
          show: false,
          error: undefined,
        },
      ]);
    }
  }, [data?.is2FA]);

  return (
    <>
      <div className="register">
        <div className="register__hero">
          <div className="hero">
            <div></div>
            <div className="hero__decor">
              <picture>
                <source
                  srcSet="./img/background/decor.webp"
                  type="image/webp"
                />
                <img
                  loading="lazy"
                  src="./img/background/decor.png"
                  className="hero__image"
                  width=""
                  height=""
                  alt=""
                />
              </picture>
            </div>

            <HeroContent />
          </div>
        </div>
        <main className="register__main">
          <div className="register__logo">
            <img src="./img/logo.svg" alt="logo" />
          </div>
          <div className="register__body">
            <h1 className="register__title">Log in</h1>
            <form className="register__form">
              <div className="register__form-items">
                {inputs.map((v, i) => (
                  <Input
                    key={i}
                    input={v}
                    setHideInput={setPasswordHide}
                    setInputValue={setInputValue}
                    setInputError={setInputError}
                  />
                ))}
                {/* {!data?.is2FA && (
                  <div className="register__form-recaptcha">
                    <ReCAPTCHA
                      sitekey="6LdRG7cqAAAAABCwVJyzxQt9kqddhoOCcUFRjgge"
                      onChange={onChange}
                      // style={{
                      //   transform: "scale(0.)",
                      //   transformOrigin: "0 0",
                      // }}
                      size="normal"
                    />
                  </div>
                )} */}
              </div>

              <button
                onClick={onButtonClick}
                type="button"
                className="register__button button"
              >
                Login
              </button>
              <div className="register__form-links">
                <a
                  onClick={() => navigate("/reset-password")}
                  className="register__form-link"
                >
                  Forgot Password
                </a>
                <a
                  onClick={() => navigate("/register")}
                  className="register__form-link"
                >
                  Sign Up
                </a>
              </div>
            </form>
          </div>
          <div className="register__socials">
            <ul className="socials">
              {socials.map((icon) => (
                <li className="socials__item" key={icon.id}>
                  <a
                    href={icon.href}
                    rel="noreferrer"
                    target="_blank"
                    className="socials__link"
                  >
                    <svg className="socials__icon">
                      <use xlinkHref={`img/sprite.svg#${icon.id}`}></use>
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </main>
      </div>
    </>
  );
};

export default Login;
