import { useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";
import { IBalances, IBrt, IStatistics, ITX } from "../types/types";

const getBalances = async () => {
  let result = await authApi
    .get("/dashboard/balance")
    .then((res) => res.data as IBalances);

  for (const network of Object.keys(result)) {
    if (result[network]) {
      result[network] = await Promise.all(
        result[network].map(async (v: any) => {
          v.price = 1;
          if (v.symbol !== "USDT" && v.symbol !== "BRT") {
            const params = new URLSearchParams({
              category: "spot",
              symbol: v.symbol.toUpperCase() + "USDT",
            });
            const response = await fetch(
              "https://api.bybit.com/v5/market/tickers?" + params
            );
            const data = await response.json();
            if (data.retMsg === "OK") {
              v.price = parseFloat(data.result.list[0].lastPrice);
              v.price_24h_pcnt = parseFloat(data.result.list[0].price24hPcnt);
            }
          }
          return v;
        })
      );
    }
  }

  return result;
};

const getBrt = async () => {
  return authApi.get("/dashboard/balance-brt").then((res) => res.data as IBrt);
};

const getTxs = async (startDate: string, endDate: string) => {
  const params = new URLSearchParams({
    start_date: new Date(startDate).getTime().toString(),
    end_date: new Date(endDate).getTime().toString(),
  });

  const response = await authApi.get(`/dashboard/txs?${params}`);
  return response.data as Array<ITX>;
};

const getStatistics = async (startDate: string, endDate: string) => {
  const params = new URLSearchParams({
    start_date: new Date(new Date(startDate).toISOString())
      .getTime()
      .toString(),
    end_date: new Date(new Date(endDate).toISOString()).getTime().toString(),
  });

  const response = await authApi.get(`/dashboard/statistic?${params}`);
  return response.data as IStatistics;
};

export const useGetBalances = () => {
  const {
    data: balances,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Balances"],
    queryFn: getBalances,
  });

  return {
    balances,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetBrt = () => {
  const {
    data: brt,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Brt"],
    queryFn: getBrt,
  });

  return {
    brt,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetTxs = (startDate: string, endDate: string) => {
  const {
    data: txs,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Txs", startDate, endDate],
    queryFn: () => getTxs(startDate, endDate),
    enabled: !!startDate && !!endDate,
  });

  return {
    txs,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetStatistics = (startDate: string, endDate: string) => {
  const {
    data: statistics,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["Statistics"],
    queryFn: () => getStatistics(startDate, endDate),
  });

  return {
    statistics,
    isError,
    isLoading,
    refetch,
  };
};
