import React from "react";
import { IBrt } from "../../../types/types";

interface IProps {
  brt: IBrt;
}

const BalanceInfo: React.FC<IProps> = ({ brt }) => {
  return (
    <div className="dashboard__balance-info balance-info">
      <div className="balance-info__item">
        <div className="balance-info__top">
          <svg className="balance-info__icon balance-info__icon--total">
            <use xlinkHref="img/sprite.svg#heart"></use>
          </svg>
          <label className="balance-info__label">Total Balance</label>
          {/* <button type="button" className="balance-info__btn">
            <svg className="balance-info__dots">
              <use xlinkHref="img/sprite.svg#dots"></use>
            </svg>
          </button> */}
        </div>
        <div className="balance-info__amount amount">
          <div className="amount__text">
            {brt?.total}
            <span className="amount__currency">BRT</span>
          </div>
          {brt?.total_change ? (
            <div
              className={`amount__change amount__change--${
                brt.total_change > 0 ? "up" : "down"
              }`}
            >
              <span className="amount__change-amount">{brt.total_change}%</span>
              <svg className="amount__change-icon">
                <use xlinkHref="img/sprite.svg#arrow-change"></use>
              </svg>
            </div>
          ) : null}
        </div>
      </div>
      <div className="balance-info__item">
        <div className="balance-info__top">
          <svg className="balance-info__icon balance-info__icon--depo">
            <use xlinkHref="img/sprite.svg#depo"></use>
          </svg>
          <label className="balance-info__label">In Depo</label>
          {/* <button type="button" className="balance-info__btn">
            <svg className="balance-info__dots">
              <use xlinkHref="img/sprite.svg#dots"></use>
            </svg>
          </button> */}
        </div>
        <div className="balance-info__amount amount">
          <div className="amount__text">
            {brt?.in_depo}
            <span className="amount__currency">BRT</span>
          </div>
          {brt?.in_depo_change ? (
            <div
              className={`amount__change amount__change--${
                brt.in_depo_change > 0 ? "up" : "down"
              }`}
            >
              <span className="amount__change-amount">
                {brt.in_depo_change}%
              </span>
              <svg className="amount__change-icon">
                <use xlinkHref="img/sprite.svg#arrow-change"></use>
              </svg>
            </div>
          ) : null}
        </div>
      </div>
      <div className="balance-info__item">
        <div className="balance-info__top">
          <svg className="balance-info__icon balance-info__icon--crowdfunding">
            <use xlinkHref="img/sprite.svg#bag"></use>
          </svg>
          <label className="balance-info__label">Crowdfunding dividends</label>
          {/* <button type="button" className="balance-info__btn">
            <svg className="balance-info__dots">
              <use xlinkHref="img/sprite.svg#dots"></use>
            </svg>
          </button> */}
        </div>
        <div className="balance-info__amount amount">
          <div className="amount__text">
            {brt?.crowdfunfing_divs}
            <span className="amount__currency">BRT</span>
          </div>
          {brt?.crowdfunfing_divs_change ? (
            <div
              className={`amount__change amount__change--${
                brt.crowdfunfing_divs_change > 0 ? "up" : "down"
              }`}
            >
              <span className="amount__change-amount">
                {brt.crowdfunfing_divs_change}%
              </span>
              <svg className="amount__change-icon">
                <use xlinkHref="img/sprite.svg#arrow-change"></use>
              </svg>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className="balance-info__item">
      <div className="balance-info__top">
        <svg className="balance-info__icon balance-info__icon--wallet">
          <use xlinkHref="img/sprite.svg#exchange"></use>
        </svg>
        <label className="balance-info__label">Wallet Address</label>
        <button type="button" className="balance-info__btn">
          <svg className="balance-info__dots">
            <use xlinkHref="img/sprite.svg#dots"></use>
          </svg>
        </button>
      </div>
      <div className="balance-info__address">
        <span className="balance-info__address-text">
          0×24asda5s7da5s7fasf24aa1
        </span>
        <button type="button" className="balance-info__address-copy">
          <svg className="balance-info__address-copy-icon">
            <use xlinkHref="img/sprite.svg#copy"></use>
          </svg>
        </button>
      </div>
    </div> */}
    </div>
  );
};

export default BalanceInfo;
