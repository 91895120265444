/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  useGetAvalancheCodes,
  useGetAvalancheParticipatedPlans,
  useGetAvalanchePrices,
  useJoinToAvalancheClub,
} from "../../services/avalanche.services";
import Modal from "../../components/Modal";
import AvalancheTerms from "../../components/Modal/AvalancheTerms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

const avalancheList: {
  id: "GS_I" | "GS_II" | "GS_III" | "GS_IV";
  title: string;
  description: string[];
}[] = [
  {
    id: "GS_I",
    title: "Golden Stairs I",
    description: [
      "Ensure that the code you received is for the GS-I level.",
      "Enter your unique code to gain access to the Avalanche Club.",
      "Complete the payment for the Avalanche Club membership fee.",
      "Generate your personalized membership code for the Avalanche Club.",
      "Distribute your code to a minimum of THREE new users.",
      "Increase your earnings by inviting more users—each referral boosts your BRT rewards.",
      "Redeem your earned BRT by selling it on the exchange page or swapping it for USDT.",
      "Receive more than 100 BRT",
    ],
  },
  {
    id: "GS_II",
    title: "Golden Stairs II",
    description: [
      "Ensure that the code you received is for the GS-II level.",
      "Enter your unique code to gain access to the Avalanche Club.",
      "Complete the payment for the Avalanche Club membership fee.",
      "Generate your personalized membership code for the Avalanche Club.",
      "Distribute your code to a minimum of FIVE new users.",
      "Increase your earnings by inviting more users—each referral boosts your BRT rewards.",
      "Redeem your earned BRT by selling it on the exchange page or swapping it for USDT.",
      "Receive more than 10.000 BRT",
    ],
  },
  {
    id: "GS_III",
    title: "Golden Stairs III",
    description: [
      "Ensure that the code you received is for the GS-III level.",
      "Enter your unique code to gain access to the Avalanche Club.",
      "Complete the payment for the Avalanche Club membership fee.",
      "Generate your personalized membership code for the Avalanche Club.",
      "Distribute your code to a minimum of SEVEN new users.",
      "Increase your earnings by inviting more users—each referral boosts your BRT rewards.",
      "Redeem your earned BRT by selling it on the exchange page or swapping it for USDT.",
      "Receive more than 3.000.000 BRT",
    ],
  },
  {
    id: "GS_IV",
    title: "Golden Stairs IV",
    description: [
      "Ensure that the code you received is for the GS-IV level.",
      "Enter your unique code to gain access to the Avalanche Club.",
      "Complete the payment for the Avalanche Club membership fee.",
      "Generate your personalized membership code for the Avalanche Club.",
      "Distribute your code to a minimum of EIGHT new users.",
      "Increase your earnings by inviting more users—each referral boosts your BRT rewards.",
      "Redeem your earned BRT by selling it on the exchange page or swapping it for USDT.",
      "Receive more than 50.000.000 BRT",
    ],
  },
];

const JoinToAvalancheClubFormSchema = yup.object().shape({
  code: yup.string().required("Code is a required field!"),
  agreement: yup.boolean().required("Agree with Terms & Conditions"),
  type: yup.string().required("Type is a required field!"),
});

const Avalanche = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isReferralCodeModalOpen, setIsReferralCodeModalOpen] =
    useState<boolean>(false);

  const { avalancheCode, refetch } = useGetAvalancheCodes(selectedItem!);
  const { avalancheParticipated, avalancheParticipatedRefetch } =
    useGetAvalancheParticipatedPlans();

  const useFormReturn = useForm({
    resolver: yupResolver(JoinToAvalancheClubFormSchema),
  });

  const { mutate: joinToAvalancheClub } = useJoinToAvalancheClub(
    () => {
      setIsOpen(false);
      avalancheParticipatedRefetch();
      toast.success("You are successfully joined!");
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const { setValue } = useFormReturn;

  const handleSubmitForm = (data: {
    code: string;
    agreement: boolean;
    type: string;
  }) => {
    if (data.agreement) {
      joinToAvalancheClub({
        type: data.type,
        code: data.code,
      });
    } else {
      toast.error("Please agree with Terms & Conditions!");
    }
  };

  const { avalanchePrices } = useGetAvalanchePrices();

  const handleCopyReferralCode = async () => {
    if (avalancheCode?.code) {
      try {
        await navigator.clipboard.writeText(avalancheCode.code);
        toast.success("Referral code copied to clipboard!");
      } catch (error) {
        toast.error("Failed to copy referral code!");
      }
    }
  };

  useEffect(() => {
    if (selectedItem) {
      refetch();
    }
  }, [selectedItem, refetch]);

  return (
    <main className="main avalanche">
      {/* <div className="avalanche__code code">
        <div className="code__qr">
          <img src="./img/qr-code.svg" alt="" />
        </div>
        <div className="code__body">
          <h2 className="code__title">Code for your referrals</h2>
          <div className="code__address">
            <span className="code__address-text">
              a5d7fga56v6ad4fg2sadf4gadfg
            </span>
            <button type="button" className="code__address-copy">
              <svg className="code__address-copy-icon">
                <use xlinkHref="img/sprite.svg#copy"></use>
              </svg>
            </button>
          </div>
          <button
            type="button"
            className="code__btn button button--blue"
            onClick={() => {
              setIsReferralCodeModalOpen(true);
            }}
          >
            Generate
          </button>
        </div>
      </div> */}
      <ul className="avalanche__tariff tariff">
        {avalanchePrices &&
          avalancheList?.map((card) => (
            <li className="tariff__card tariff__card--stairs-1" key={card.id}>
              <div className="tariff__body">
                <div className="tariff__header">
                  <h2 className="tariff__title">{card.title}</h2>
                </div>
                <ul className="tariff__descr-items">
                  <li className="tariff__descr-item">
                    <img
                      src="./img/svg/arrow-tariff.svg"
                      alt="icon"
                      className="tariff__descr-icon"
                    />
                    <div className="tariff__descr-text">
                      {card.description.map((text, i) => (
                        <p key={i}>{text}</p>
                      ))}
                    </div>
                  </li>
                </ul>
                {avalancheParticipated && avalancheParticipated![card.id] ? (
                  <button
                    type="button"
                    className="tariff__button button"
                    onClick={() => {
                      setIsReferralCodeModalOpen(true);
                      setSelectedItem(card.id);
                    }}
                  >
                    Get Referral Code
                  </button>
                ) : (
                  <button
                    type="button"
                    className="tariff__button button"
                    onClick={() => {
                      setIsOpen(true);
                      setValue("type", card.id);
                    }}
                  >
                    {avalanchePrices![card.id]} BRT
                  </button>
                )}
              </div>
            </li>
          ))}
      </ul>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Avalanche Club">
        <AvalancheTerms
          returnFormInstance={useFormReturn}
          onSubmit={handleSubmitForm}
        />
      </Modal>

      <Modal
        title="Code for your referrals"
        isOpen={isReferralCodeModalOpen}
        setIsOpen={setIsReferralCodeModalOpen}
      >
        <div className="code-ref">
          <div className="code-ref__qr">
            <img src={avalancheCode?.qr} alt="" />
          </div>
          <div className="code__address">
            <span className="code__address-text">{avalancheCode?.code}</span>
            <button
              type="button"
              className="code__address-copy"
              onClick={handleCopyReferralCode}
            >
              <svg className="code__address-copy-icon">
                <use xlinkHref="img/sprite.svg#copy"></use>
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default Avalanche;
