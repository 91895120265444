import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Input from "../../components/Input/Input";
import { useGetBalances } from "../../services/dashboard.services";
import { useGetSwapAvailableAssets } from "../../services/swap.services";
import { ISendForm } from "../../types/types";
import {
  useGetTransactionFee,
  useRequestEmailCode,
  useSendFunds,
} from "../../services/send.services";

interface IBalance {
  symbol: string;
  full_name: string;
  balance: number;
  price: number;
  price_24h_pcnt: number;
}

interface IBalances {
  [index: string]: Array<IBalance>;
}

const schema = yup.object().shape({
  asset: yup.string().required("Asset is required"),
  network: yup.string().required("Network is required"),
  to: yup.string().required("Address is a required field!"),
  amount: yup
    .number()
    .typeError("Amount must be a number")
    .required("Amount is required")
    .positive("Amount must be positive"),
  code: yup
    .number()
    .min(4, "Code must be 4 digits")
    .required("Code is required"),
});

const Send: React.FC = () => {
  const [step, setStep] = useState(1);
  const [assetPayValue, setAssetPayValue] = useState("");
  const [networkPayValue, setNetworkPayValue] = useState("");
  const [timer, setTimer] = useState(0);
  const [isCodeRequested, setIsCodeRequested] = useState(false);

  const { balances } = useGetBalances();
  const { availableAssets: data } = useGetSwapAvailableAssets();
  const { transactionInfo } = useGetTransactionFee({
    asset: assetPayValue,
    network: networkPayValue,
  });

  const { mutate: sendFunds } = useSendFunds(
    () => {
      setStep(2);
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const { mutate: requestEmailCode } = useRequestEmailCode(
    () => {
      toast.success("Code sent successfully!");
      setIsCodeRequested(true);
      const now = new Date().getTime();
      localStorage.setItem("codeRequestTime", now?.toString() || "");
      setTimer(60);
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ISendForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      asset: "",
      network: "",
      to: "",
      amount: 0,
      code: undefined,
    },
  });

  const watchNetworkPay = watch("network");
  const watchAssetPay = watch("asset");

  useEffect(() => {
    if (watchNetworkPay && data) {
      const networks =
        data[watchNetworkPay]?.map((item: any) => item.symbol) || [];
      setValue("asset", "");
      setAssetPayValue("");
      setValue("asset", networks[0]);
    }
  }, [watchNetworkPay, data, setValue]);

  useEffect(() => {
    if (watchAssetPay) {
      setAssetPayValue(watchAssetPay);
    }
  }, [watchAssetPay]);

  useEffect(() => {
    if (watchNetworkPay) {
      setNetworkPayValue(watchNetworkPay);
    }
  }, [watchNetworkPay]);

  useEffect(() => {
    const savedTime = localStorage.getItem("codeRequestTime");
    if (savedTime) {
      const now = new Date().getTime();
      const elapsedTime = Math.floor((now - parseInt(savedTime)) / 1000);
      if (elapsedTime < 60) {
        setIsCodeRequested(true);
        setTimer(60 - elapsedTime);
      } else {
        localStorage.removeItem("codeRequestTime");
      }
    }
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0 && isCodeRequested) {
      setIsCodeRequested(false);
      localStorage.removeItem("codeRequestTime");
    }
  }, [timer, isCodeRequested]);

  const getBalance = (data: IBalances, asset: string, network: string) => {
    const assetLower = asset.toLowerCase();
    const networkLower = network.toLowerCase();

    if (!data.hasOwnProperty(assetLower) || data[assetLower] === null) {
      return 0;
    }

    if (Array.isArray(data[assetLower])) {
      const assetData = data[assetLower].find(
        (n) => n.symbol.toLowerCase() === networkLower
      );

      return assetData ? assetData.balance.toFixed(2) : 0;
    }

    return 0;
  };

  const onSubmit = async (data: ISendForm) => {
    sendFunds(data);
  };

  const handleGetCode = (e: any) => {
    e.preventDefault();
    requestEmailCode();
  };

  const contentForm = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="exchange__order-form form"
      >
        <div className="order-form__body">
          {assetPayValue === "BRT" && (
            <p className="send-bruno-warning">
              BRT can only be sent to the internal Bruno account via the
              registered user's email!
            </p>
          )}
          <div className="order-form__block">
            <h3 className="order-form__title">Select Network</h3>
            <div className="order-form__items">
              <Controller
                name="network"
                control={control}
                render={({ field }) => (
                  <Input
                    input={{
                      type: "dropdown",
                      id: "networkPay",
                      svg: "",
                      value: field.value,
                      values: data ? Object.keys(data) : [],
                      placeholder: "Network",
                      error: errors.network?.message,
                    }}
                    setInputValue={(_e, value) => {
                      console.log(value);
                      field.onChange(value);
                    }}
                    setInputError={() => {}}
                  />
                )}
              />
            </div>
          </div>
          <div className="order-form__block">
            <h3 className="order-form__title">Select Asset</h3>
            <div className="order-form__items">
              <Controller
                name="asset"
                control={control}
                render={({ field }) => (
                  <Input
                    input={{
                      type: "dropdown",
                      id: "assetPay",
                      svg: "",
                      value: field.value,
                      values: data
                        ? data[watchNetworkPay]?.map(
                            (item: any) => item.symbol
                          ) || []
                        : [],
                      placeholder: "Asset",
                      error: errors.asset?.message,
                    }}
                    setInputValue={(_e, value) => {
                      field.onChange(value);
                    }}
                    setInputError={() => {}}
                  />
                )}
              />
            </div>
          </div>
          <div className="order-form__block">
            <h3 className="order-form__title">To</h3>
            <div className="order-form__items">
              <Controller
                name="to"
                control={control}
                render={({ field }) => (
                  <Input
                    input={{
                      type: "text",
                      id: "toAddress",
                      svg: "",
                      value: field.value?.toString() || "",
                      placeholder:
                        assetPayValue === "BRT" ? "Email" : "To address",
                      regex: /^\d+$/,
                      error: errors.to?.message,
                    }}
                    setInputValue={(_e, value) => {
                      field.onChange(value);
                    }}
                    setInputError={() => {}}
                  />
                )}
              />
            </div>
          </div>

          <div className="order-form__block">
            <h3 className="order-form__title">Amount</h3>
            <div className="order-form__items">
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <Input
                    input={{
                      type: "text",
                      id: "amountPay",
                      svg: "",
                      value: field.value?.toString() || "",
                      placeholder: "Amount",
                      regex: /^\d+$/,
                      error: errors.amount?.message,
                    }}
                    setInputValue={(_e, value) => {
                      field.onChange(value);
                    }}
                    setInputError={() => {}}
                  />
                )}
              />
              {assetPayValue && balances && (
                <span className="form__item-text-bottom">
                  Balance:{" "}
                  {getBalance(balances, networkPayValue, assetPayValue)}{" "}
                  {assetPayValue}
                </span>
              )}
            </div>
          </div>

          <ul className="order-details">
            <li className="order-details__item order-details__item--fee">
              <h4 className="order-details__title">Transaction fee</h4>
              <span className="order-details__value">
                {transactionInfo?.fee || 0} {assetPayValue}
              </span>
            </li>
          </ul>
          {transactionInfo && !transactionInfo?.is_2fa_enabled ? (
            <div className="order-form__block">
              <h3 className="order-form__title">Enter Confirmation Code</h3>
              <div className="order-form__items">
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <Input
                      input={{
                        type: "right-button",
                        id: "code",
                        svg: "",
                        value: field.value?.toString() || "",
                        placeholder: "Code",
                        regex: /^\d+$/,
                        error: errors.code?.message,
                        rightButton: isCodeRequested ? (
                          <span className="right-button-text">{timer}s</span>
                        ) : (
                          <button
                            className="right-button-text"
                            onClick={handleGetCode}
                          >
                            Get Code
                          </button>
                        ),
                      }}
                      setInputValue={(_e, value) => {
                        field.onChange(value);
                      }}
                      setInputError={() => {}}
                    />
                  )}
                />
              </div>
            </div>
          ) : (
            <div className="order-form__block">
              <h3 className="order-form__title">
                Enter Google Authenticator Code
              </h3>
              <div className="order-form__items">
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <Input
                      input={{
                        type: "right-button",
                        id: "code",
                        svg: "",
                        value: field.value?.toString() || "",
                        placeholder: "Code",
                        regex: /^\d+$/,
                        error: errors.code?.message,
                      }}
                      setInputValue={(_e, value) => {
                        field.onChange(value);
                      }}
                      setInputError={() => {}}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>

        <button className="order-form__button button" type="submit">
          Confirm
        </button>
      </form>
    );
  };

  return (
    <>
      {step === 1 ? (
        <main className="main exchange">
          <div className="exchange__order">
            <h2 className="exchange__order-title">Send Crypto</h2>
            {contentForm()}
          </div>
        </main>
      ) : step === 2 ? (
        <main className="main">
          <div className="exchange">
            <div className="exchange__order hidden">
              <h2 className="exchange__order-title">Send</h2>
              {contentForm()}
            </div>
            <div className="exchange__order exchange__order--done">
              <button
                type="button"
                className=" modal__close js-modal-close"
                aria-label="close modal"
                onClick={() => setStep(1)}
              >
                <svg className="modal__close-icon">
                  <use xlinkHref="img/sprite.svg#close"></use>
                </svg>
              </button>
              <h2 className="exchange__order-title exchange__order-title--done">
                Transaction completed successfully
              </h2>

              <p className="exchange__order-text">
                Your transaction has been successfully processed. You can track
                the status in the transaction history.
              </p>
              <button
                type="button"
                className="exchange__order-button button"
                onClick={() => setStep(1)}
              >
                Done
              </button>
            </div>
          </div>
        </main>
      ) : (
        <></>
      )}
    </>
  );
};

export default Send;
