import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'


const Billing: FC  = () => {

  const navigate = useNavigate()

  return (
    <div className="settings__body">
            <div className="settings__card">
              <div className="settings__header">
                <h2 className="settings__title">Personal information</h2>
                <div className="settings__subtitle">Lorem ipsum dolor sit amet consectetur adipiscing.</div>
              </div>
              <form action="" className="settings__form form-settings">
                <div className="form-settings__cards">
                  <div className="form-settings__card options">
                    <div className="options__row">
                      <div className="options__item">
                        <input id="visa" type="radio" name="card-type" className="options__input" checked/>
                        <label htmlFor="visa" className="options__label">
                          <div className="options__label-card">
                            <div className="options__label-card-img options__label-card-img--orange">
                              <img className="options__label-card-logo" src="./img/card/visa.svg" alt="card-logo"/>
                            </div>
                            <div className="options__label-card-type">
                              <span className="options__label-card-num">VISA **** 8092</span>
                              <span className="options__label-card-date"> Expires on 12/26</span>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="options__del">
                        <svg className="options__del-icon">
                          <use xlinkHref="img/sprite.svg#close"></use>
                        </svg>
                      </div>
                    </div>
                    <div className="options__row">
                      <div className="options__item">
                        <input id="mastercard" type="radio" name="card-type" className="options__input" checked/>
                        <label htmlFor="mastercard" className="options__label">
                          <div className="options__label-card">
                            <div className="options__label-card-img">
                              <img className="options__label-card-logo" src="./img/card/mastercard.svg" alt="card-logo"/>
                            </div>
                            <div className="options__label-card-type">
                              <span className="options__label-card-num">Mastercard **** 8092</span>
                              <span className="options__label-card-date"> Expires on 12/26</span>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="options__del">
                        <svg className="options__del-icon">
                          <use xlinkHref="img/sprite.svg#close"></use>
                        </svg>
                      </div>
                    </div>
                    <div className="options__row">
                      <div className="options__item">
                        <input id="american" type="radio" name="card-type" className="options__input" checked/>
                        <label htmlFor="american" className="options__label">
                          <div className="options__label-card">
                            <div className="options__label-card-img">
                              <img className="options__label-card-logo" src="./img/card/american.svg" alt="card-logo"/>
                            </div>
                            <div className="options__label-card-type">
                              <span className="options__label-card-num">American Express **** 8092</span>
                              <span className="options__label-card-date"> Expires on 12/26</span>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="options__del">
                        <svg className="options__del-icon">
                          <use xlinkHref="img/sprite.svg#close"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="form-settings__cards-add">
                    <img className="form-settings__cards-add-icon" src="./img/add.png" alt="add"/>
                    <span className="form-settings__cards-add-text">Add a new payment method</span>
                  </button>
                </div>
                <div className="settings__header">
                  <h2 className="settings__title">Basic information</h2>
                  <div className="settings__subtitle">Lorem ipsum dolor sit amet consectetur adipiscing.
                  </div>
                </div>
                <div className="form-settings__item">
                  <div className="form-settings__label">
                    <svg className="form-settings__label-icon">
                      <use xlinkHref="img/sprite.svg#user"></use>
                    </svg>
                    <span className="form-settings__label-text">Full name</span>
                  </div>
                  <input type="text" className="form-settings__input" value="John Carter"/>
                </div>
                <div className="form-settings__item">
                  <div className="form-settings__label">
                    <svg className="form-settings__label-icon">
                      <use xlinkHref="img/sprite.svg#dashboard"></use>
                    </svg>
                    <span className="form-settings__label-text">Address</span>
                  </div>
                  <input type="text" className="form-settings__input" value="601 4th St #103"/>
                </div>
                <div className="form-settings__item">
                  <div className="form-settings__label">
                    <svg className="form-settings__label-icon">
                      <use xlinkHref="img/sprite.svg#address"></use>
                    </svg>
                    <span className="form-settings__label-text">State</span>
                  </div>
                  <input type="text" className="form-settings__input" value="Los Angeles"/>
                </div>
                <div className="form-settings__item">
                  <div className="form-settings__label">
                    <svg className="form-settings__label-icon">
                      <use xlinkHref="img/sprite.svg#email"></use>
                    </svg>
                    <span className="form-settings__label-text">Zip code</span>
                  </div>
                  <input type="text" className="form-settings__input" value="90001"/>
                </div>
              </form>
            </div>
          </div>
  )
}

export default Billing 