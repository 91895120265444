import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from "react-router-dom";
import { IInput } from '../../components/Input/input.types'
import Input from '../../components/Input/Input'

const ConfirmMail = () => {

  const [isError, setIsError] = useState(true)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.size > 0) {
            const token = searchParams.get('token')
            if (token) {
                console.log(token)
                fetch(process.env.REACT_APP_API_URL + "/auth/confirm_mail", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ token })
                  })
                  .then(req => req.json()
                    .then(res => {
                        if (req.status === 201) {
                            setIsError(false)
                        }
                    })
                  )
                  
                  
            }
        }
    }, [searchParams])

  return (
    <>
      <div className="register">
    <div className="register__hero">
      <div className="hero">
  <div>

  </div>
  <div className="hero__decor">
    <picture >
      <source srcSet="./img/background/decor.webp" type="image/webp"/>
      <img  loading="lazy" src="./img/background/decor.png" className="hero__image" width="" height="" alt=""/>
    </picture>
  </div>

  <div className="hero__body">
    <a href="#" className="hero__logo"><img src="./img/logo.svg" alt="logo"/></a>
    <div className="hero__content">
      <h1 className="hero__title"><span className="hero__title-amount">237,453,783</span> users trust us
      </h1>
      <p className="hero__text">Tristique tortor dolor magna arcu porttitor dignissim id fames dolor. Semper fringilla sed
        proin venenatis nam turpis facilisi venenatis. Non quisque odio nulla massa sagittis elementum eget fusce.</p>
    </div>
    <div className="hero__links">
      <a href="#" className="hero__link">
        <img src="./img/binance.svg" alt="binance"/>
      </a>
      <a href="#" className="hero__link">
        <img src="./img/coinmarket.svg" alt="coinmarket"/>
      </a>
      <a href="#" className="hero__link">
        <img src="./img/uniswap.svg" alt="uniswap"/>
      </a>
    </div>
  </div>

</div>

    </div>
    <main className="register__main">
      <a href="#" className="register__logo"><img src="./img/logo.svg" alt="logo"/></a>
      <div className="register__body">
        <h1 className="register__title">{ isError ? "Error!" : "Congrats!"}</h1>
        <form className="register__form">
          <p style={{ paddingBottom: "10px" }}>{ isError ? "Something went wrong. Please try again later" : "You've successfully confirmed your e-mail address"}</p>
          <button onClick={() => navigate('/login')} type="button" className="register__button button">Sign In</button>
        </form>
      </div>
      <div className="register__socials">
                <ul className="socials">
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#telegram"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#medium"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#reddit"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#linkedin"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#twitter"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#crunchbase"></use>
              </svg>
            </a>
          </li>
          <li className="socials__item">
            <a href="#" className="socials__link">
              <svg className="socials__icon">
                <use xlinkHref="img/sprite.svg#discord"></use>
              </svg>
            </a>
          </li>
        </ul>

      </div>
    </main>
  </div>
    </>
  )
}

export default ConfirmMail