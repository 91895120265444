import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'


const Password: FC  = () => {

  const navigate = useNavigate()

  return (
    <div className="settings__body">
            <div className="settings__card">
              <div className="settings__header">
                <h2 className="settings__title">Change Password</h2>
                <div className="settings__subtitle">Lorem ipsum dolor sit amet consectetur adipiscing.</div>
              </div>
              <form action="" className="settings__form form-settings">
                <div className="form-settings__items">
                <div className="form-settings__item">
                  <div className="form-settings__label">
                    <svg className="form-settings__label-icon">
                      <use xlinkHref="img/sprite.svg#password"></use>
                    </svg>
                    <span className="form-settings__label-text">Old Password</span>
                  </div>
                  <input type="password" className="form-settings__input" value="John Carter"/>
                </div>
                <div className="form-settings__item">
                  <div className="form-settings__label">
                    <svg className="form-settings__label-icon">
                      <use xlinkHref="img/sprite.svg#password"></use>
                    </svg>
                    <span className="form-settings__label-text">New Password</span>
                  </div>
                  <input type="password" className="form-settings__input" value="john@dashdark.com"/>
                </div>
                <div className="form-settings__item">
                  <div className="form-settings__label">
                    <svg className="form-settings__label-icon">
                      <use xlinkHref="img/sprite.svg#password"></use>
                    </svg>
                    <span className="form-settings__label-text">Repeat New Password</span>
                  </div>
                  <input type="password" className="form-settings__input" value="john@dashdark.com"/>
                </div>
                </div>

                <button type="button" className="form-settings__button button">Save</button>
              </form>
            </div>
          </div>
  )
}

export default Password 