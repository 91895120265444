import { useMutation, useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";

const getSwapAvailableAssets = async () => {
  return authApi.get("/swap/available-assets").then((res) => res.data);
};

const getSwapTokensInfo = async (curValues: {
  [index: string]: string | boolean;
}) => {
  return authApi
    .get(
      `/swap/swap-info?from_asset=${curValues.assetPay}&from_network=${curValues.networkPay}&amount=${curValues.amountPay}&to_asset=${curValues.assetGet}&to_network=${curValues.networkGet}`
    )
    .then((res) => res.data);
};

export const swapTokens = async (data: {
  from_asset: string;
  from_network: string;
  amount: number;
  to_asset: string;
  to_network: string;
}) => {
  return authApi.post("/swap/swap", data).then((res) => res.data);
};

export const useGetSwapAvailableAssets = () => {
  const {
    data: availableAssets,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["SwapAvailableAssets"],
    queryFn: getSwapAvailableAssets,
  });

  return {
    availableAssets,
    isLoading,
    isError,
  };
};

export const useGetSwapTokensInfo = (curValues: {
  [index: string]: string | boolean;
}) => {
  const {
    data: swapInfo,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["SwapInfo"],
    queryFn: () => getSwapTokensInfo(curValues),
    enabled: !!(
      curValues.from_asset &&
      curValues.from_network &&
      curValues.amount &&
      curValues.to_asset &&
      curValues.to_network
    ),
  });

  return {
    swapInfo,
    isLoading,
    isError,
    refetch,
  };
};

export const useSwapTokens = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["SwapTokens"],
    mutationFn: (data: {
      from_asset: string;
      from_network: string;
      amount: number;
      to_asset: string;
      to_network: string;
    }) => swapTokens(data),
    onSuccess,
    onError,
  });
};
