import React, { useEffect } from "react";
import * as yup from "yup";
import {
  useGetNotificationSettings,
  useUpdateNotificationSettings,
} from "../../../services/settings.services";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { INotificationSettingsForm } from "../../../types/types";

const UpdateNotificationSettingsFormSchema = yup.object().shape({
  order_status: yup.string().required(""),
  system_message: yup.string().required(""),
  terms_changed: yup.string().required(""),
  token_rate: yup.string().required(""),
});

const Notifications: React.FC = () => {
  const { notificationSettings } = useGetNotificationSettings();

  const { mutate: updateNotificationSettings, isPending } =
    useUpdateNotificationSettings(
      (newSettings) => {
        toast.success("Notification settings were successfully updated!");
        reset(newSettings); // Сбрасываем форму с новыми значениями после успешного сохранения
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<INotificationSettingsForm>({
    resolver: yupResolver(UpdateNotificationSettingsFormSchema),
    defaultValues: {
      order_status: "IN_APP",
      system_message: "IN_APP",
      terms_changed: "IN_APP",
      token_rate: "IN_APP",
    },
  });

  useEffect(() => {
    if (notificationSettings) {
      reset(notificationSettings); // Устанавливаем начальные значения формы при загрузке данных
    }
  }, [notificationSettings, reset]);

  const handleSubmitNotificationSettingsForm = (
    data: INotificationSettingsForm
  ) => {
    updateNotificationSettings(data);
  };

  return (
    <div className="settings__body">
      <div className="settings__card">
        <div className="settings__header">
          <h2 className="settings__title">General notifications</h2>
          <div className="settings__subtitle">
            Manage how you receive notifications about important updates and
            changes.
          </div>
        </div>
        <form
          onSubmit={handleSubmit(handleSubmitNotificationSettingsForm)}
          className="settings__form form-settings"
        >
          {/* Поле "Order status" */}
          <div className="form-settings__item-notification">
            <div className="form-settings__label">
              <span className="form-settings__label-text">Order status</span>
              <img
                className="form-settings__label-icon"
                src="./img/info.png"
                alt=""
              />
            </div>
            <Controller
              name="order_status"
              control={control}
              render={({ field }) => (
                <div className="form-settings__switch">
                  <button
                    type="button"
                    className={`form-settings__switch-btn form-settings__switch-btn--app ${
                      field.value === "IN_APP" ? "active" : ""
                    }`}
                    onClick={() => field.onChange("IN_APP")}
                  >
                    <svg className="form-settings__switch-icon">
                      <use xlinkHref="img/sprite.svg#app"></use>
                    </svg>
                    <span className="form-settings__switch-text">In-app</span>
                  </button>
                  <button
                    type="button"
                    className={`form-settings__switch-btn form-settings__switch-btn--mail ${
                      field.value === "EMAIL" ? "active" : ""
                    }`}
                    onClick={() => field.onChange("EMAIL")}
                  >
                    <svg className="form-settings__switch-icon">
                      <use xlinkHref="img/sprite.svg#email"></use>
                    </svg>
                    <span className="form-settings__switch-text">Email</span>
                  </button>
                </div>
              )}
            />
          </div>

          {/* Поле "System message" */}
          <div className="form-settings__item-notification">
            <div className="form-settings__label">
              <span className="form-settings__label-text">System message</span>
              <img
                className="form-settings__label-icon"
                src="./img/info.png"
                alt=""
              />
            </div>
            <Controller
              name="system_message"
              control={control}
              render={({ field }) => (
                <div className="form-settings__switch">
                  <button
                    type="button"
                    className={`form-settings__switch-btn form-settings__switch-btn--app ${
                      field.value === "IN_APP" ? "active" : ""
                    }`}
                    onClick={() => field.onChange("IN_APP")}
                  >
                    <svg className="form-settings__switch-icon">
                      <use xlinkHref="img/sprite.svg#app"></use>
                    </svg>
                    <span className="form-settings__switch-text">In-app</span>
                  </button>
                  <button
                    type="button"
                    className={`form-settings__switch-btn form-settings__switch-btn--mail ${
                      field.value === "EMAIL" ? "active" : ""
                    }`}
                    onClick={() => field.onChange("EMAIL")}
                  >
                    <svg className="form-settings__switch-icon">
                      <use xlinkHref="img/sprite.svg#email"></use>
                    </svg>
                    <span className="form-settings__switch-text">Email</span>
                  </button>
                </div>
              )}
            />
          </div>

          {/* Поле "Terms has changed" */}
          <div className="form-settings__item-notification">
            <div className="form-settings__label">
              <span className="form-settings__label-text">
                Terms has changed
              </span>
              <img
                className="form-settings__label-icon"
                src="./img/info.png"
                alt=""
              />
            </div>
            <Controller
              name="terms_changed"
              control={control}
              render={({ field }) => (
                <div className="form-settings__switch">
                  <button
                    type="button"
                    className={`form-settings__switch-btn form-settings__switch-btn--app ${
                      field.value === "IN_APP" ? "active" : ""
                    }`}
                    onClick={() => field.onChange("IN_APP")}
                  >
                    <svg className="form-settings__switch-icon">
                      <use xlinkHref="img/sprite.svg#app"></use>
                    </svg>
                    <span className="form-settings__switch-text">In-app</span>
                  </button>
                  <button
                    type="button"
                    className={`form-settings__switch-btn form-settings__switch-btn--mail ${
                      field.value === "EMAIL" ? "active" : ""
                    }`}
                    onClick={() => field.onChange("EMAIL")}
                  >
                    <svg className="form-settings__switch-icon">
                      <use xlinkHref="img/sprite.svg#email"></use>
                    </svg>
                    <span className="form-settings__switch-text">Email</span>
                  </button>
                </div>
              )}
            />
          </div>

          {/* Поле "Token rate has changed" */}
          <div className="form-settings__item-notification">
            <div className="form-settings__label">
              <span className="form-settings__label-text">
                Token rate has changed
              </span>
              <img
                className="form-settings__label-icon"
                src="./img/info.png"
                alt=""
              />
            </div>
            <Controller
              name="token_rate"
              control={control}
              render={({ field }) => (
                <div className="form-settings__switch">
                  <button
                    type="button"
                    className={`form-settings__switch-btn form-settings__switch-btn--app ${
                      field.value === "IN_APP" ? "active" : ""
                    }`}
                    onClick={() => field.onChange("IN_APP")}
                  >
                    <svg className="form-settings__switch-icon">
                      <use xlinkHref="img/sprite.svg#app"></use>
                    </svg>
                    <span className="form-settings__switch-text">In-app</span>
                  </button>
                  <button
                    type="button"
                    className={`form-settings__switch-btn form-settings__switch-btn--mail ${
                      field.value === "EMAIL" ? "active" : ""
                    }`}
                    onClick={() => field.onChange("EMAIL")}
                  >
                    <svg className="form-settings__switch-icon">
                      <use xlinkHref="img/sprite.svg#email"></use>
                    </svg>
                    <span className="form-settings__switch-text">Email</span>
                  </button>
                </div>
              )}
            />
          </div>

          {/* Кнопка "Save" */}
          <button
            type="submit"
            className="button"
            style={{ maxWidth: 150 }}
            disabled={!isDirty || isPending}
          >
            {isPending ? <div className="spinner"></div> : "Save"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Notifications;
