/* eslint-disable array-callback-return */
import Cookies from "js-cookie";
import { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Input from "../../components/Input/Input";
import { IInput } from "../../components/Input/input.types";

interface RegisterInputs {
  input: IInput;
}
interface IUser {
  checked?: boolean;
  balance: number;
  deposit: number;
  email: string;
  full_name: string;
  id: number;
  is_locked: boolean;
  role: string;
  refferals: number;
}

const EditUser = ({
  getUsers,
  setUsers,
  user,
}: {
  getUsers: () => void;
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
  user: IUser;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reOpen, setReOpen] = useState(false);
  const [showAnimateClass, setShowAnimateClass] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const modalRef = useRef<HTMLDivElement>(null);
  const previousActiveElement = useRef<Element | null>(null);
  const [inputs, setInputs] = useState<Array<RegisterInputs>>([
    {
      input: {
        type: "text",
        id: "full_name",
        svg: "",
        value: `${user.full_name}`,
        placeholder: "Name",
        regex: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
        error: undefined,
      },
    },
    {
      input: {
        type: "text",
        id: "email",
        svg: "",
        value: `${user.email}`,
        placeholder: "E-mail",
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        error: "",
      },
    },
    {
      input: {
        type: "dropdown",
        id: "role",
        svg: "",
        value: `${user.role}`,
        values: ["ADMIN", "USER", "MODER"],
        placeholder: "Type",
        error: undefined,
      },
    },
    // {
    //   input: {
    //     type: "text",
    //     id: "brt_balance",
    //     svg: "exchange",
    //     value: `${user.balance}`,
    //     placeholder: "Balance",
    //     regex: /^[a-zA-Z0-9\s,.'-]+$/,
    //     error: undefined,
    //   },
    // },
  ]);

  const enableScroll = () => {
    document.body.style.overflow = "";
    document.documentElement.style.overflow = "";
  };

  const openModal = () => {
    previousActiveElement.current = document.activeElement;

    if (isOpen) {
      setReOpen(true);
      closeModal();
      return;
    }

    if (modalRef.current) {
      modalRef.current.scrollTo(0, 0);
      modalRef.current.style.setProperty("--transition-time", `${500 / 1000}s`);
    }

    document.body.style.overflow = "hidden";
    setIsOpen(true);

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setShowAnimateClass(true);
    }, 500);
  };

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.scrollTo(0, 0);
    }

    enableScroll();
    setIsOpen(false);
    setShowAnimateClass(false);

    if (previousActiveElement.current) {
      (previousActiveElement.current as HTMLElement).focus();
    }

    if (reOpen) {
      setReOpen(false);
      openModal();
    }
  };

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.input.type !== "multitext") {
          if (v.input.id === input_id) {
            v.input.error = error.toString();
          }
        } else {
          v.input.texts = v.input.texts.map((i) => {
            if (i.id === input_id) {
              i.error = error.toString();
            }
            return i;
          });
        }
        return v;
      })
    );
  };

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.input.type !== "multitext") {
          if (v.input.id === input_id) {
            v.input.value = value;
            if (v.input.type === "date") {
              if (v.input.value === "") {
                v.input.error = "Can't be empty";
              } else {
                v.input.error = "";
              }
            }
          }
        } else {
          v.input.texts = v.input.texts.map((i) => {
            if (i.id === input_id) {
              i.value = value.toString();
            }
            return i;
          });
        }
        return v;
      })
    );
  };

  const onButtonClick = async () => {
    let hasErrors = false;
    let curValues: { [index: string]: string | boolean } = {};

    const newInputs = inputs.map((v) => {
      if (v.input.type !== "multitext") {
        if (v.input.value === "") {
          v.input.error = "Can't be empty";
          hasErrors = true;
        }

        if (v.input.error) {
          hasErrors = true;
        }
        curValues[v.input.id] = v.input.value;
      }
      return v;
    });

    setInputs(newInputs);

    if (!hasErrors) {
      if (process.env.REACT_APP_API_URL) {
        const accessToken = Cookies.get("access_token");

        if (!accessToken) {
          throw new Error("No accessToken token available");
        }

        const req = await fetch(
          process.env.REACT_APP_API_URL + `/users/${user.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ ...curValues }),
          }
        );

        const res = await req.json();

        if (req.status === 200) {
          getUsers();
          toast.success("Account has been successfully created");
          closeModal();
        } else {
          toast.error(res["message"]);
        }
      }
    }
  };

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <button className="table__item-edit" onClick={openModal}>
        <svg className="table__item-edit-icon">
          <use xlinkHref="img/sprite.svg#edit"></use>
        </svg>
      </button>
      <div
        ref={modalRef}
        className={`modal ${isOpen ? "is-open" : ""}`}
        onClick={closeModal}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`modal__container ${isOpen ? "modal-open fadeInUp" : ""} ${
            showAnimateClass ? "animate-open" : ""
          }`}
          role="dialog"
          aria-modal="true"
        >
          <button
            onClick={closeModal}
            type="button"
            className="modal__close js-modal-close"
            aria-label="close modal"
          >
            <svg className="modal__close-icon">
              <use xlinkHref="img/sprite.svg#close"></use>
            </svg>
          </button>
          <div className="edit-user">
            <h2 className="edit-user__title">Edit User</h2>
            <div className="edit-user__form-items">
              {inputs.map((v, i) => {
                if (v.input.type !== "checkbox") {
                  return (
                    <Input
                      key={i}
                      input={v.input}
                      setInputValue={setInputValue}
                      setInputError={setInputError}
                    />
                  );
                }
              })}
            </div>
            <button
              className="edit-user__button button"
              onClick={() => onButtonClick()}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
