/* eslint-disable array-callback-return */
import { FC, useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Input from "../../components/Input/Input";
import { IInput } from "../../components/Input/input.types";
import { IFees } from "./Fees";

interface EditFeesProps {
  fees: IFees;
  getFees: () => void;
  setFees: (fee: UpdateFeeData) => void;
}

interface UpdateFeeData {
  id: number;
  operation_type: string;
  min_sum: number;
  max_sum: number;
  fee: number;
  is_fixed: boolean;
}

interface RegisterInputs {
  input: IInput;
  group?: string;
}

const EditFees: FC<EditFeesProps> = ({ getFees, setFees, fees }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reOpen, setReOpen] = useState(false);
  const [showAnimateClass, setShowAnimateClass] = useState(false);
  const [isFix, setIsFix] = useState(fees.is_fixed);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const [inputs, setInputs] = useState<Array<RegisterInputs>>([
    {
      input: {
        type: "dropdown",
        id: "type",
        svg: "",
        value: fees.operation_type,
        values: ["SWAP", "SELL", "WITHDRAW", "DEPOSIT"],
        placeholder: "Type",
        error: undefined,
      },
      group: "top",
    },
    {
      input: {
        type: "text",
        id: "min",
        svg: "",
        value: fees.min_sum.toString(),
        placeholder: "Sum min",
        regex: /^\d+$/,
        error: undefined,
      },
      group: "top",
    },
    {
      input: {
        type: "text",
        id: "max",
        svg: "",
        value: fees.max_sum.toString(),
        placeholder: "Sum max",
        regex: /^\d+$/,
        error: undefined,
      },
      group: "top",
    },
    {
      input: {
        type: "text",
        id: "percent",
        svg: "",
        value: fees.fee,
        placeholder: "%",
        regex: /^(100|[1-9]?\d(\.\d+)?)$/,
        error: undefined,
      },
      group: "top",
    },
  ]);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const previousActiveElement = useRef<Element | null>(null);

  const handleModalControls = {
    disableScroll: () => {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    },
    enableScroll: () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    },
    openModal: () => {
      previousActiveElement.current = document.activeElement;

      if (isOpen) {
        setReOpen(true);
        handleModalControls.closeModal();
        return;
      }

      if (modalRef.current) {
        modalRef.current.scrollTo(0, 0);
        modalRef.current.style.setProperty(
          "--transition-time",
          `${500 / 1000}s`
        );
      }

      handleModalControls.disableScroll();
      setIsOpen(true);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setShowAnimateClass(true);
      }, 500);
    },
    closeModal: () => {
      if (modalRef.current) {
        modalRef.current.scrollTo(0, 0);
      }

      handleModalControls.enableScroll();
      setIsOpen(false);
      setShowAnimateClass(false);

      if (previousActiveElement.current) {
        (previousActiveElement.current as HTMLElement).focus();
      }

      if (reOpen) {
        setReOpen(false);
        handleModalControls.openModal();
      }
    },
  };

  const handleInputs = {
    setInputError: (input_id: string, error: string | boolean) => {
      setInputs((prevInputs) =>
        prevInputs.map((input) => {
          if ((input.input as any).id === input_id) {
            return {
              ...input,
              input: { ...input.input, error: error.toString() },
            };
          }
          return input;
        })
      );
    },
    setInputValue: (input_id: string, value: string | boolean) => {
      setInputs(
        (prevInputs) =>
          prevInputs.map((input) => {
            if ((input.input as any).id === input_id) {
              return {
                ...input,
                input: {
                  ...input.input,
                  value,
                  error: value === "" ? "Can't be empty" : undefined,
                },
              };
            }
            return input;
          }) as any
      );
    },
  };

  useEffect(() => {
    if (isOpen) {
      setIsFix(fees.is_fixed);
    }
  }, [isOpen, fees.is_fixed]);

  const handleSubmit = async () => {
    let hasErrors = false;
    const formValues: Record<string, string | boolean> = {};

    inputs.forEach((input) => {
      if (
        (input.input as any).value === "" &&
        (input.input as any).id !== "text"
      ) {
        handleInputs.setInputError((input.input as any).id, "Can't be empty");
        hasErrors = true;
      }
      if ((input.input as any).error) {
        hasErrors = true;
      }
      formValues[(input.input as any).id] = (input.input as any).value;
    });

    if (!hasErrors) {
      const updateData: UpdateFeeData = {
        id: fees.id,
        operation_type: formValues.type as string,
        min_sum: Number(formValues.min),
        max_sum: Number(formValues.max),
        fee: Number(formValues.percent),
        is_fixed: isFix,
      };

      try {
        console.log("Updating fee with data:", updateData);
        setFees(updateData);
        handleModalControls.closeModal();
      } catch (error) {
        console.error("Error updating fee:", error);
        toast.error("Failed to update fee");
      }
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <button
        className="table__item-edit"
        onClick={handleModalControls.openModal}
      >
        <svg className="table__item-edit-icon">
          <use xlinkHref="img/sprite.svg#edit"></use>
        </svg>
      </button>
      <div
        ref={modalRef}
        className={`modal ${isOpen ? "is-open" : ""}`}
        onClick={handleModalControls.closeModal}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`modal__container ${isOpen ? "modal-open fadeInUp" : ""} ${
            showAnimateClass ? "animate-open" : ""
          }`}
          role="dialog"
          aria-modal="true"
        >
          <button
            onClick={handleModalControls.closeModal}
            type="button"
            className="modal__close js-modal-close"
            aria-label="close modal"
          >
            <svg className="modal__close-icon">
              <use xlinkHref="img/sprite.svg#close"></use>
            </svg>
          </button>
          <div className="fees">
            <h2 className="fees__title">Change Fee</h2>
            <div className="fees__current">Current fee: {fees.fee}%</div>
            <div className="fees__form-items">
              {inputs.map(
                (input) =>
                  input.group === "top" && (
                    <Input
                      key={(input.input as any).id}
                      input={input.input as any}
                      setInputValue={handleInputs.setInputValue}
                      setInputError={handleInputs.setInputError}
                    />
                  )
              )}
            </div>
            {(fees.operation_type === "WITHDRAW" ||
              fees.operation_type === "SWAP") && (
              <div className="fees__current">Fixed BRT fee: 0.02 BRT</div>
            )}
            <div className="fees__form-checkbox checkbox">
              <input
                id="fees"
                type="checkbox"
                name="fees"
                className="checkbox__input"
                checked={isFix}
                onChange={(e) => {
                  console.log("Checkbox changed:", e.target.checked);
                  setIsFix(e.target.checked);
                }}
              />
              <label htmlFor="fees" className="checkbox__label">
                Fix fee
              </label>
            </div>
            <button
              className="fees__button button"
              type="button"
              onClick={handleSubmit}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFees;
