import { useMutation, useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";
import {
  IBillingInfo,
  IBillingInfoForm,
  INewCardForm,
  INotificationSettings,
  INotificationSettingsForm,
  IPersonalInfo,
  IUpdatePassword,
  IUpdatePersonalInfoForm,
} from "../types/types";

const getUserAvatar = async (filename: string) => {
  return authApi
    .get(`/users/photo/${filename}`, { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      return URL.createObjectURL(blob);
    });
};

const getPersonalInfo = async () => {
  return authApi
    .get("/users/personal")
    .then((res) => res.data as IPersonalInfo);
};

const updatePersonalInfo = async (data: IUpdatePersonalInfoForm) => {
  return authApi.patch("/users/personal", data).then((res) => res.data);
};

const updatePassword = async (data: IUpdatePassword) => {
  return authApi.post("/users/password", data).then((res) => res.data);
};

const getPaymentMethods = async () => {
  return authApi.get("/users/cards").then((res) => res.data);
};

const newCard = async (data: INewCardForm) => {
  return authApi.post("/users/cards", data).then((res) => res.data);
};

const deleteCard = async (id: number) => {
  return authApi.delete(`/users/cards/${id}`).then((res) => res.data);
};

const getBillingInfo = async () => {
  return authApi.get("/users/billing").then((res) => res.data as IBillingInfo);
};

const updateBillingInfo = async (data: IBillingInfoForm) => {
  return authApi.post("/users/billing", data).then((res) => res.data);
};

const getNotificationSettings = async () => {
  return authApi
    .get("/users/notifs")
    .then((res) => res.data as INotificationSettings);
};

const updateNotificationSettings = async (data: INotificationSettingsForm) => {
  return authApi.post("/users/notifs", data).then((res) => res.data);
};

const getHasKeyValue = async () => {
  return authApi.get("/users/has_kyc").then((res) => res.data);
};

export const useGetUserAvatar = (filename: string) => {
  const {
    data: userAvatar,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["UserAvatar"],
    queryFn: () => getUserAvatar(filename),
    enabled: !!filename,
    retry: false,
  });

  return {
    userAvatar,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetPersonalInfo = () => {
  const {
    data: personalInfo,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["PersonalInfo"],
    queryFn: getPersonalInfo,
  });

  return {
    personalInfo,
    isLoading,
    isError,
  };
};

export const useUpdatePersonalInfo = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdatePersonalInfo"],
    mutationFn: (data: IUpdatePersonalInfoForm) => updatePersonalInfo(data),
    onSuccess,
    onError,
  });
};

export const useUpdatePassword = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdatePassword"],
    mutationFn: (data: IUpdatePassword) => updatePassword(data),
    onSuccess,
    onError,
  });
};

export const useGetPaymentMethods = () => {
  const {
    data: paymentMethods,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["PaymentMethods"],
    queryFn: getPaymentMethods,
    retry: false,
  });

  return {
    paymentMethods,
    isLoading,
    isError,
    refetch,
  };
};

export const useNewCard = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["CreateNewCard"],
    mutationFn: (data: INewCardForm) => newCard(data),
    onSuccess,
    onError,
  });
};

export const useDeleteCard = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["DeleteCard"],
    mutationFn: (id: number) => deleteCard(id),
    onSuccess,
    onError,
  });
};

export const useGetBillingInfo = () => {
  const {
    data: billingInfo,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["BillingInfo"],
    queryFn: getBillingInfo,
  });

  return {
    billingInfo,
    isLoading,
    isError,
  };
};

export const useUpdateBillingInfo = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdateBillingInfo"],
    mutationFn: (data: IBillingInfoForm) => updateBillingInfo(data),
    onSuccess,
    onError,
  });
};

export const useGetNotificationSettings = () => {
  const {
    data: notificationSettings,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["NotificationSettings"],
    queryFn: getNotificationSettings,
  });

  return {
    notificationSettings,
    isLoading,
    isError,
    refetch,
  };
};

export const useUpdateNotificationSettings = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdateNotificationSettings"],
    mutationFn: (data: INotificationSettingsForm) =>
      updateNotificationSettings(data),
    onSuccess,
    onError,
  });
};

export const useGetHasKycValue = () => {
  const {
    data: hasKyc,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["HasKyc"],
    queryFn: getHasKeyValue,
  });

  return {
    hasKyc,
    isLoading,
    isError,
  };
};
