import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from "./pages/Main/Main";
import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ConfirmMail from "./pages/ConfirmMail/ConfirmMail";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import GuestRoute from "./components/GuestRoute/GuestRoute";

function App() {
  
  return (
    <>
      <Routes>
        <Route path="/register" element={<GuestRoute><Register /></GuestRoute>} />
        <Route path="/login" element={<GuestRoute><Login /></GuestRoute>} />
        <Route path="/reset-password" element={<GuestRoute><ResetPassword /></GuestRoute>} />
        <Route path="/confirm" element={<GuestRoute><ConfirmMail /></GuestRoute>} />
        <Route path="/reset" element={<GuestRoute><PasswordReset /></GuestRoute>} />
        <Route path="/" element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        } />
       
      </Routes>
      <ToastContainer
        theme="dark"
        hideProgressBar={true}
        autoClose={2000}
      />
    </>
  );
}

export default App;
