/* eslint-disable react-hooks/exhaustive-deps */
import { FC, forwardRef, useState } from "react";
import Histogram from "../../components/Histogram/Histogram";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import EditUser from "./EditUser";
import moment from "moment";
import {
  useDeleteUser,
  useGetActiveUserCounts,
  useGetUserCounts,
  useGetUsers,
  useLockUser,
} from "../../services/users.services";

// interface IUserCounts {
//   total: number;
//   userCounts: Array<any>;
//   percentageChange: number;
// }

interface IUser {
  checked?: boolean;
  balance: number;
  deposit: number;
  email: string;
  full_name: string;
  id: number;
  is_locked: boolean;
  role: string;
  refferals: number;
  create_time: string;
}

interface UserParams {
  sel_month: string;
  only_admins: string;
  only_users: string;
  only_super_admins: string;
  search?: string;
}

const Users: FC = () => {
  const defaultStartDate = moment().startOf("month").toDate();
  const defaultEndDate = moment().endOf("month").toDate();

  const [totalUsersDateRange, setTotalUsersDateRange] = useState<
    [Date | null, Date | null]
  >([defaultStartDate, defaultEndDate]);
  const [totalUsersStartDate, totalUsersEndDate] = totalUsersDateRange;

  const [activeUsersDateRange, setActiveUsersDateRange] = useState<
    [Date | null, Date | null]
  >([defaultStartDate, defaultEndDate]);
  const [activeUsersStartDate, activeUsersEndDate] = activeUsersDateRange;

  const { mutate: lockUser } = useLockUser(
    () => {
      refetchUsers();
      toast.success("User status changed!");
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const { mutate: deleteUser } = useDeleteUser(
    () => {
      refetchUsers();
      toast.success("User deleted!");
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const [tableDate, setTableDate] = useState(defaultStartDate);

  const [onlyAdmins, setOnlyAdmins] = useState(true);
  const [onlyUsers, setOnlyUsers] = useState(true);
  const [onlyModers, setOnlyModers] = useState(true);
  const [userSearch, setUserSearch] = useState("");

  const defaultParams: UserParams = {
    sel_month: moment(defaultStartDate).format("MMM YYYY"),
    only_admins: "1",
    only_users: "1",
    only_super_admins: "1",
  };

  const {
    data: userCounts = { total: 0, percentageChange: 0, userCounts: [] },
  } = useGetUserCounts(
    moment(totalUsersStartDate).unix(),
    moment(totalUsersEndDate).unix()
  );

  const {
    data: activeUserCounts = { total: 0, percentageChange: 0, userCounts: [] },
  } = useGetActiveUserCounts(
    moment(activeUsersStartDate).unix(),
    moment(activeUsersEndDate).unix()
  );

  const { data: users = [], refetch: refetchUsers } = useGetUsers({
    ...defaultParams,
    sel_month: moment(tableDate).format("MMM YYYY"),
    only_admins: onlyAdmins ? "1" : "0",
    only_users: onlyUsers ? "1" : "0",
    only_super_admins: onlyModers ? "1" : "0",
    ...(userSearch && { search: userSearch }),
  } as UserParams);

  const handleDeleteUser = async (user: IUser) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete user ${user.full_name}?`
    );
    if (isConfirmed) {
      deleteUser(user.id);
    }
  };

  const changeLock = async (v: IUser) => {
    lockUser({ lock: !v.is_locked, user_id: v.id });
  };

  // Separate date picker components
  const ChartDateInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick, className }, ref) => (
      <button onClick={onClick} ref={ref} className="table__calendar calendar">
        <img className="calendar__icon" src="./img/svg/date.svg" alt="" />
        <span className="calendar__date">{value}</span>
        <svg className="calendar__arrow">
          <use xlinkHref="img/sprite.svg#arrow"></use>
        </svg>
      </button>
    )
  );

  const TableDateInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick, className }, ref) => (
      <button onClick={onClick} ref={ref} className="table__calendar calendar">
        <img className="calendar__icon" src="./img/svg/date.svg" alt="" />
        <span className="calendar__date">{value}</span>
        <svg className="calendar__arrow">
          <use xlinkHref="img/sprite.svg#arrow"></use>
        </svg>
      </button>
    )
  );

  return (
    <>
      <div className="users">
        <div className="users__charts">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "270px",
            }}
            className="users__chart chart-section"
          >
            <div className="chart-section__header">
              <div className="chart-section__total">
                <h2 className="chart-section__title">Total users</h2>
                <div className="chart-section__amount amount">
                  <div className="amount__text">{userCounts.total}</div>
                  {userCounts.percentageChange !== 0 && (
                    <div
                      className={`amount__change amount__change--${
                        userCounts.percentageChange > 0 ? "up" : "down"
                      }`}
                    >
                      <span className="amount__change-amount">
                        {userCounts.percentageChange}%
                      </span>
                      <svg className="amount__change-icon">
                        <use xlinkHref="img/sprite.svg#arrow-change"></use>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              <div className="chart-section__legend">
                <span className="chart-section__legend-item chart-section__legend-item--current">
                  Current clients
                </span>
                <DatePicker
                  selectsRange={true}
                  startDate={totalUsersStartDate}
                  endDate={totalUsersEndDate}
                  onChange={(update) => {
                    setTotalUsersDateRange(update);
                  }}
                  customInput={<ChartDateInput />}
                />
              </div>
            </div>
            <div style={{ flexGrow: 1 }} className="chart-section__graph">
              <Histogram data={userCounts.userCounts} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "270px",
            }}
            className="users__chart chart-section"
          >
            <div className="chart-section__header">
              <div className="chart-section__total">
                <h2 className="chart-section__title">Active users</h2>
                <div className="chart-section__amount amount">
                  <div className="amount__text">{activeUserCounts.total}</div>
                  {activeUserCounts.percentageChange !== 0 && (
                    <div
                      className={`amount__change amount__change--${
                        activeUserCounts.percentageChange > 0 ? "up" : "down"
                      }`}
                    >
                      <span className="amount__change-amount">
                        {activeUserCounts.percentageChange}%
                      </span>
                      <svg className="amount__change-icon">
                        <use xlinkHref="img/sprite.svg#arrow-change"></use>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              <div className="chart-section__legend">
                <span className="chart-section__legend-item chart-section__legend-item--active">
                  Active clients
                </span>
                <DatePicker
                  selectsRange={true}
                  startDate={activeUsersStartDate}
                  endDate={activeUsersEndDate}
                  onChange={(update) => {
                    setActiveUsersDateRange(update);
                  }}
                  customInput={<ChartDateInput />}
                />
              </div>
            </div>
            <div style={{ flexGrow: 1 }} className="chart-section__graph">
              <Histogram data={userCounts.userCounts} />
            </div>
          </div>
        </div>
        <div className="users__table">
          <div className="table">
            <div className="table__header">
              <h2 className="table__title">Users list</h2>
              <div className="table__filter">
                <div className="table__checkbox checkbox">
                  <input
                    onClick={() => setOnlyAdmins((prev) => !prev)}
                    id="admins"
                    type="checkbox"
                    className="checkbox__input"
                    checked={onlyAdmins}
                  />
                  <label htmlFor="admins" className="checkbox__label">
                    Admins
                  </label>
                </div>
                <div className="table__checkbox checkbox">
                  <input
                    onClick={() => setOnlyUsers((prev) => !prev)}
                    id="users"
                    type="checkbox"
                    className="checkbox__input"
                    checked={onlyUsers}
                  />
                  <label htmlFor="users" className="checkbox__label">
                    Users
                  </label>
                </div>
                <div className="table__checkbox checkbox">
                  <input
                    onClick={() => setOnlyModers((prev) => !prev)}
                    id="moders"
                    type="checkbox"
                    className="checkbox__input"
                    checked={onlyModers}
                  />
                  <label htmlFor="moders" className="checkbox__label">
                    Moders
                  </label>
                </div>
              </div>
              <form
                className="table__inputs"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="table__input-search input-search">
                  <svg className="input-search__icon">
                    <use xlinkHref="img/sprite.svg#search"></use>
                  </svg>
                  <input
                    type="text"
                    name=""
                    value={userSearch}
                    onChange={(e) => setUserSearch(e.target.value)}
                    placeholder="ID, Name"
                    className="input-search__control"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </form>
              <div className="table__actions">
                <DatePicker
                  selected={tableDate}
                  onChange={(date: Date | null) => setTableDate(date!)}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  customInput={<TableDateInput />}
                />
              </div>
            </div>
            <div className="table__body">
              <div className="table__row">
                <ul className="table__items table__items--users table__items--title">
                  <li className="table__item admin-table__item-id">ID</li>
                  <li className="table__item">Client</li>
                  <li className="table__item">User type</li>
                  <li className="table__item">Deposit</li>
                  <li className="table__item">Referals</li>
                  <li className="table__item">Balance</li>
                  <li className="table__item">Registration date</li>
                  <li className="table__item"></li>
                </ul>
              </div>
              {users.length > 0 &&
                users.map((v: IUser, i: number) => {
                  return (
                    <div className="table__row" key={i}>
                      <ul className="table__items table__items--users">
                        <li className="table__item">
                          <div
                            className={`admin-table__item-id ${
                              v.checked ? "table__item-id--check" : ""
                            }`}
                          >
                            #{v.id}
                          </div>
                        </li>
                        <li className="table__item">
                          <div className="table__item-client">
                            {v.full_name} <span>{v.email}</span>
                          </div>
                        </li>
                        <li className="table__item">{v.role}</li>
                        <li className="table__item">$ {v.deposit}</li>
                        <li className="table__item">{v.refferals}</li>
                        <li className="table__item">$ {v.balance}</li>
                        <li className="table__item">
                          {moment(v.create_time).format("DD.MM.YYYY")}
                        </li>
                        <li className="table__item">
                          <div className="table__item-actions">
                            <button
                              className={`table__item-btn button button--${
                                v.is_locked ? "transparent unlock" : "blue lock"
                              }`}
                              style={{
                                zIndex: "0",
                              }}
                              onClick={() => changeLock(v)}
                            >
                              {v.is_locked ? "Unlock" : "Lock"}
                            </button>
                            <EditUser
                              getUsers={() => refetchUsers()}
                              setUsers={() => {}}
                              user={v}
                            />
                            <button
                              className="table__item-basket"
                              onClick={() => handleDeleteUser(v)}
                            >
                              <svg className="table__item-basket-icon">
                                <use xlinkHref="img/sprite.svg#basket"></use>
                              </svg>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
