import React from "react";
import { UseFormReturn, Controller } from "react-hook-form";
import Select from "react-select";
import { IPartnerForm } from "../../types/types";
import countries from "./regions.json";

interface IProps {
  returnFormInstance: UseFormReturn<IPartnerForm>;
  onSubmit: (data: IPartnerForm) => void;
}

const NewPartner: React.FC<IProps> = ({ returnFormInstance, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  const currencyOptions = [
    {
      value: "BRT",
      label: "BRT",
    },
    {
      value: "BTC",
      label: "BTC",
    },
    {
      value: "USDT_TRON",
      label: "USDT (TRC20)",
    },
    {
      value: "USDT_BSC",
      label: "USDT (BEP20)",
    },
    {
      value: "TRX_TRON",
      label: "TRX",
    },
    {
      value: "BNB_BSC",
      label: "BNB",
    },
  ];

  const countryOptions = countries?.map((country) => ({
    value: country,
    label: country,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="add-card form">
      <div className="add-card__items form__items">
        <div className="form__item">
          <label className="form__label">Partner name</label>
          <div className="form__input input">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className="input__control"
                  placeholder="Binance"
                />
              )}
            />
          </div>
          {errors.name && <p className="form__error">{errors.name.message}</p>}
        </div>
        <div className="form__item">
          <label className="form__label">Currencies</label>
          <div className="form__input">
            <Controller
              name="symbols"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  options={currencyOptions}
                  value={currencyOptions.filter((option) =>
                    field.value.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    field.onChange(
                      selectedOptions.map((option) => option.value)
                    );
                  }}
                  classNamePrefix="select"
                  placeholder="Select currencies..."
                />
              )}
            />
          </div>
          {errors.symbols && (
            <p className="form__error">{errors.symbols.message}</p>
          )}
        </div>
        <div className="form__item">
          <label className="form__label">Region</label>
          <div className="form__input">
            <Controller
              name="country"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  options={countryOptions}
                  value={countryOptions.find(
                    (option) => option.value === field.value
                  )}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption?.value);
                  }}
                  classNamePrefix="select"
                  placeholder="Select country..."
                />
              )}
            />
          </div>
          {errors.country && (
            <p className="form__error">{errors.country.message}</p>
          )}
        </div>
        <div className="form__item">
          <label className="form__label">URL</label>
          <div className="form__input input">
            <Controller
              name="url"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className="input__control"
                  placeholder="https://example.com"
                />
              )}
            />
          </div>
          {errors.url && <p className="form__error">{errors.url.message}</p>}
        </div>
      </div>
      <button
        type="submit"
        className="order-form__button button"
        style={{
          zIndex: 0,
        }}
      >
        Add Partner
      </button>
    </form>
  );
};

export default NewPartner;
