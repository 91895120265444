import { FC } from "react";
import { useAuth } from "../../hooks/useAuth";
import Admin from "./Admin";
import User from "./User";

const Main: FC = () => {
  const { user } = useAuth();

  if (user?.role === "SUPERADMIN" || user?.role === "ADIMIN") {
    return <Admin />;
  } else if (user?.role === "USER") {
    return <User />;
  }

  return <></>;
};

export default Main;
