import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import AuthProvider from './contexts/AuthContext';

const rootElement = document.getElementById('root');

// Проверка существования rootElement для TypeScript
if (!rootElement) {
  throw new Error("Root element not found. Check your HTML file.");
}

// Типизация root для React 18
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
