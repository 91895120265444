import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'


const Notifications: FC  = () => {

  const navigate = useNavigate()

  return (
    <div className="settings__body">
            <div className="settings__card">
              <div className="settings__header">
                <h2 className="settings__title">General notifications</h2>
                <div className="settings__subtitle">Lorem ipsum dolor sit amet consectetur adipiscing.</div>
              </div>
              <form action="" className="settings__form form-settings">
                <div className="form-settings__item-notification">
                  <div className="form-settings__label">
                    <span className="form-settings__label-text">Order status</span>
                    <img className="form-settings__label-icon" src="./img/info.png" alt=""/>
                  </div>
                  <div className="form-settings__switch">
                    <button type="button" className="form-settings__switch-btn form-settings__switch-btn--app active">
                      <svg className="form-settings__switch-icon">
                        <use xlinkHref="img/sprite.svg#app"></use>
                      </svg>
                      <span className="form-settings__switch-text">In-app</span>
                    </button>
                    <button type="button" className="form-settings__switch-btn form-settings__switch-btn--mail">
                      <svg className="form-settings__switch-icon">
                        <use xlinkHref="img/sprite.svg#email"></use>
                      </svg>
                      <span className="form-settings__switch-text">Email</span>
                    </button>
                  </div>
                </div>
                <div className="form-settings__item-notification">
                  <div className="form-settings__label">
                    <span className="form-settings__label-text">System message</span>
                    <img className="form-settings__label-icon" src="./img/info.png" alt=""/>
                  </div>
                  <div className="form-settings__switch">
                    <button type="button" className="form-settings__switch-btn form-settings__switch-btn--app ">
                      <svg className="form-settings__switch-icon">
                        <use xlinkHref="img/sprite.svg#app"></use>
                      </svg>
                      <span className="form-settings__switch-text">In-app</span>
                    </button>
                    <button type="button" className="form-settings__switch-btn form-settings__switch-btn--mail active">
                      <svg className="form-settings__switch-icon">
                        <use xlinkHref="img/sprite.svg#email"></use>
                      </svg>
                      <span className="form-settings__switch-text">Email</span>
                    </button>
                  </div>
                </div>
                <div className="form-settings__item-notification">
                  <div className="form-settings__label">
                    <span className="form-settings__label-text">Terms has changed</span>
                    <img className="form-settings__label-icon" src="./img/info.png" alt=""/>
                  </div>
                  <div className="form-settings__switch">
                    <button type="button" className="form-settings__switch-btn form-settings__switch-btn--app ">
                      <svg className="form-settings__switch-icon">
                        <use xlinkHref="img/sprite.svg#app"></use>
                      </svg>
                      <span className="form-settings__switch-text">In-app</span>
                    </button>
                    <button type="button" className="form-settings__switch-btn form-settings__switch-btn--mail active">
                      <svg className="form-settings__switch-icon">
                        <use xlinkHref="img/sprite.svg#email"></use>
                      </svg>
                      <span className="form-settings__switch-text">Email</span>
                    </button>
                  </div>
                </div>
                <div className="form-settings__item-notification">
                  <div className="form-settings__label">
                    <span className="form-settings__label-text">Token rate has changed</span>
                    <img className="form-settings__label-icon" src="./img/info.png" alt=""/>
                  </div>
                  <div className="form-settings__switch">
                    <button type="button" className="form-settings__switch-btn form-settings__switch-btn--app active">
                      <svg className="form-settings__switch-icon">
                        <use xlinkHref="img/sprite.svg#app"></use>
                      </svg>
                      <span className="form-settings__switch-text">In-app</span>
                    </button>
                    <button type="button" className="form-settings__switch-btn form-settings__switch-btn--mail">
                      <svg className="form-settings__switch-icon">
                        <use xlinkHref="img/sprite.svg#email"></use>
                      </svg>
                      <span className="form-settings__switch-text">Email</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
  )
}

export default Notifications 