import moment from "moment";
import React, { forwardRef, useState } from "react";
import { useGetBrt, useGetTxs } from "../../services/dashboard.services";
import DatePicker from "react-datepicker";
import { ITX } from "../../types/types";

interface IProps {
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>;
}

const History: React.FC<IProps> = ({ setSelectedPage }) => {
  const [userHistoryMonth, setUserHistoryMonth] = useState<Date | null>(
    new Date()
  );
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set()); // Состояние для выбранных элементов

  const { brt } = useGetBrt();

  const getMonthRange = (date: Date | null) => {
    const start = moment(date).startOf("month").format("YYYY-MM-DD");
    const end = moment(date).endOf("month").format("YYYY-MM-DD");
    return { start, end };
  };

  const { start: historyStartDate, end: historyEndDate } =
    getMonthRange(userHistoryMonth);

  const { txs } = useGetTxs(historyStartDate, historyEndDate);

  const handleItemSelect = (id: string) => {
    const newSelectedItems = new Set(selectedItems);
    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }
    setSelectedItems(newSelectedItems);
  };

  const OneDateInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }, ref) => (
      <button
        onClick={onClick}
        ref={ref}
        className="transactions__calendar calendar"
      >
        <img className="calendar__icon" src="./img/svg/date.svg" alt="" />
        <span className="calendar__date">{value}</span>
        <svg className="calendar__arrow">
          <use xlinkHref="img/sprite.svg#arrow"></use>
        </svg>
      </button>
    )
  );

  const getTransactionType = (tx: ITX) => {
    // Определяем тип транзакции на основе данных
    return tx.type === "IN" ? "In" : "Out";
  };

  return (
    <main className="main dashboard">
      <div className="dashboard__balance-info balance-info">
        <div className="balance-info__item">
          <div className="balance-info__top">
            <svg className="balance-info__icon balance-info__icon--total">
              <use xlinkHref="img/sprite.svg#heart"></use>
            </svg>
            <label className="balance-info__label">Total Balance</label>
            {/* <button type="button" className="balance-info__btn">
              <svg className="balance-info__dots">
                <use xlinkHref="img/sprite.svg#dots"></use>
              </svg>
            </button> */}
          </div>
          <div className="balance-info__amount amount">
            <div className="amount__text">
              {brt?.total}
              <span className="amount__currency">BRT</span>
            </div>
            {brt?.total_change ? (
              <div
                className={`amount__change amount__change--${
                  brt.total_change > 0 ? "up" : "down"
                }`}
              >
                <span className="amount__change-amount">
                  {brt.total_change}%
                </span>
                <svg className="amount__change-icon">
                  <use xlinkHref="img/sprite.svg#arrow-change"></use>
                </svg>
              </div>
            ) : null}
          </div>
        </div>
        <div className="balance-info__item">
          <div className="balance-info__top">
            <svg className="balance-info__icon balance-info__icon--depo">
              <use xlinkHref="img/sprite.svg#depo"></use>
            </svg>
            <label className="balance-info__label">In Depo</label>
            {/* <button type="button" className="balance-info__btn">
              <svg className="balance-info__dots">
                <use xlinkHref="img/sprite.svg#dots"></use>
              </svg>
            </button> */}
          </div>
          <div className="balance-info__amount amount">
            <div className="amount__text">
              {brt?.in_depo}
              <span className="amount__currency">BRT</span>
            </div>
            {brt?.in_depo_change ? (
              <div
                className={`amount__change amount__change--${
                  brt.in_depo_change > 0 ? "up" : "down"
                }`}
              >
                <span className="amount__change-amount">
                  {brt.in_depo_change}%
                </span>
                <svg className="amount__change-icon">
                  <use xlinkHref="img/sprite.svg#arrow-change"></use>
                </svg>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="dashboard__history ">
        <div className="dashboard__transactions ">
          <div className="transactions transactions--history">
            <div className="transactions__header">
              <h2 className="transactions__title">Transactions History</h2>
              <DatePicker
                selected={userHistoryMonth}
                onChange={(date) => setUserHistoryMonth(date)}
                customInput={<OneDateInput />}
                dateFormat="MMM yyyy"
                showMonthYearPicker
              />
            </div>
            <div className="transactions__table transactions-table">
              <div className="table__row">
                <ul className="transactions-table__items table__items table__items--title">
                  <li className="table__item admin-table__item-order">Order</li>
                  <li className="table__item table__item-type">Type</li>
                  <li className="table__item table__item--address">Address</li>
                  <li className="table__item table__item--date">Date</li>
                  <li className="table__item table__item--status">Status</li>
                  <li className="table__item table__item--amount">Amount</li>
                  <li className="table__item table__item--gas">Gas</li>
                </ul>
              </div>
              {txs?.map((item) => (
                <div
                  key={item?.id}
                  className={`admin-table__row`}
                  onClick={() => handleItemSelect(item.id.toString())}
                >
                  <ul className="transactions-table__items table__items">
                    <li className="table__item">
                      <div className={`admin-table__item-order`}>
                        #{item?.id}
                      </div>
                    </li>
                    <li className="table__item">
                      <div className={`table__item-type`}>
                        {getTransactionType(item)}
                      </div>
                    </li>
                    <li className="table__item">
                      <span title={item.address}>{item.address}</span>
                    </li>
                    <li className="table__item">
                      {moment(new Date(item.created_at)).format(
                        "MMM D, h:mm A"
                      )}
                    </li>
                    <li className="table__item">
                      <div
                        className={`table__item-status table__item-status--${item?.status?.toLowerCase()}`}
                      >
                        {item?.status}
                      </div>
                    </li>
                    <li className="table__item">
                      <span>
                        {item?.amount} {item?.symbol}
                      </span>
                    </li>
                    <li className="table__item">
                      <span>${item.gas}</span>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="dashboard__dividends">
          <h3 className="dashboard__dividends-title">Crowdfunding dividends</h3>
          <div className="dashboard__dividends-amount amount-dividends">
            <div className="amount-dividends__text">
              {brt?.crowdfunfing_divs}{" "}
              <span className="amount-dividends__currency">BRT</span>
            </div>
            {brt?.crowdfunfing_divs_change ? (
              <div
                className={`amount__change amount__change--${
                  brt.crowdfunfing_divs_change > 0 ? "up" : "down"
                }`}
              >
                <span className="amount__change-amount">
                  {brt.crowdfunfing_divs_change}%
                </span>
                <svg className="amount__change-icon">
                  <use xlinkHref="img/sprite.svg#arrow-change"></use>
                </svg>
              </div>
            ) : null}
          </div>
          {/* <div className="dashboard__dividends-actions">
            <button
              onClick={() => setSelectedPage("recieve")}
              type="button"
              className="dashboard__dividends-btn button"
            >
              Buy Crypto
            </button>
            <button
              onClick={() => setSelectedPage("send")}
              type="button"
              className="dashboard__dividends-btn button button--blue"
            >
              Sell Crypto
            </button>
          </div> */}
        </div>
      </div>
    </main>
  );
};

export default History;
