/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Input from "../../components/Input/Input";
import { IInput } from "../../components/Input/input.types";
import { useGetTokenCredentials } from "../../services/receive.services";
import { useGetSwapAvailableAssets } from "../../services/swap.services";
import { IBalance } from "../../types/types";
import regions from "./regions.json";
import { useGetPartners } from "../../services/partners.services";

interface Inputs {
  input: IInput;
  group?: string;
}

const Exchange = () => {
  const [inputs, setInputs] = useState<Inputs[]>([
    {
      input: {
        type: "dropdown",
        id: "region",
        svg: "",
        value: "",
        values: regions,
        placeholder: "Region",
        error: undefined,
      },
      group: "Select Region",
    },
    {
      input: {
        type: "dropdown",
        id: "network",
        svg: "",
        value: "",
        values: [],
        placeholder: "Network",
        error: undefined,
      },
      group: "Select Network",
    },
    {
      input: {
        type: "dropdown",
        id: "asset",
        svg: "",
        value: "",
        values: [],
        placeholder: "Asset",
        error: undefined,
      },
      group: "Select Asset",
    },
  ]);

  const [assetValue, setAssetValue] = useState("");
  const [networkValue, setNetworkValue] = useState("");
  const [regionValue, setRegionValue] = useState("");

  const { refetch } = useGetTokenCredentials(assetValue, networkValue);
  const { availableAssets } = useGetSwapAvailableAssets();
  const { partners } = useGetPartners();

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs((prevInputs) =>
      prevInputs.map((v) => {
        if (v.input.type !== "multitext" && v.input.id === input_id) {
          v.input.value = value;
        }
        return v;
      })
    );

    if (input_id === "region" && typeof value === "string") {
      setRegionValue(value);
    }

    if (input_id === "network" && typeof value === "string") {
      setNetworkValue(value);
      const selectedAsset = inputs.find(
        (input) => (input.input as any).id === "network"
      )?.input;
      if (
        selectedAsset &&
        selectedAsset.type === "dropdown" &&
        availableAssets
      ) {
        const networks =
          availableAssets[value]?.map((item: IBalance) => item.symbol) || [];
        setInputValues("asset", networks);
        setInputValue("asset", "");
        setAssetValue("");
      }
    }

    if (input_id === "asset" && typeof value === "string") {
      setAssetValue(value);
    }
  };

  const setInputValues = (input_id: string, values: string[]) => {
    setInputs((prevInputs) =>
      prevInputs.map((v) => {
        if (v.input.type === "dropdown" && v.input.id === input_id) {
          v.input.values = values;
        }
        return v;
      })
    );
  };

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs((prevInputs) =>
      prevInputs.map((v) => {
        if (v.input.type !== "multitext" && v.input.id === input_id) {
          v.input.error = error.toString();
        }
        return v;
      })
    );
  };

  useEffect(() => {
    if (availableAssets) {
      const networks = Object.keys(availableAssets);
      setInputValues("network", networks);
    }
  }, [availableAssets]);

  useEffect(() => {
    if (assetValue && networkValue) {
      refetch();
    }
  }, [assetValue, networkValue]);

  useEffect(() => {
    if (regionValue) {
      setInputValue("network", "");
      setInputValue("asset", "");
      setNetworkValue("");
      setAssetValue("");
    }
  }, [regionValue]);

  const getFilteredPartners = () => {
    if (!regionValue || !networkValue || !assetValue) return [];

    return partners?.filter((partner: any) => {
      const matchesRegion = partner.country === regionValue;
      const matchesAsset = partner.symbols.includes(assetValue);
      const matchesAssetWithNetworks = partner.symbols.includes(
        `${assetValue}_${networkValue}`
      );

      return matchesRegion && (matchesAsset || matchesAssetWithNetworks);
    });
  };

  const renderFormBlock = (title: string, group: string) => (
    <div className="order-form__block">
      <h3 className="order-form__title">{title}</h3>
      <div className="order-form__items">
        {inputs
          .filter((v) => v.group === group)
          .map((v, i) => (
            <Input
              key={i}
              input={v.input}
              setInputValue={setInputValue}
              setInputError={setInputError}
            />
          ))}
      </div>
    </div>
  );

  const renderExchangesBlock = () => {
    if (!assetValue || !networkValue || !regionValue) return null;

    const filteredPartners = getFilteredPartners();

    return (
      <div className="order-form__block">
        <div className="order-form__list">
          {filteredPartners?.length > 0 ? (
            filteredPartners?.map((partner: any) => (
              <a
                key={partner.id}
                href={partner.url}
                className="order-form__exchange"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="order-form__exchange-left">
                  <img
                    width={24}
                    height={24}
                    src={`/img/exchanges/${partner.name.toLowerCase()}.svg`}
                    alt={partner.name}
                  />
                  <p>{partner.name}</p>
                </div>
                <img
                  width={24}
                  height={24}
                  src="/img/svg/chevron-right.svg"
                  alt={`go to ${partner.name}`}
                />
              </a>
            ))
          ) : (
            <p className="order-form__no-partners">
              No partners available for the selected criteria.
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <main className="main exchange">
      <div className="exchange__order">
        <h2 className="exchange__order-title">Exchanges</h2>
        <form action="" className="exchange__order-form form">
          <div className="order-form__body">
            {renderFormBlock("Select Region", "Select Region")}
            {renderFormBlock("Select Network", "Select Network")}
            {renderFormBlock("Select Asset", "Select Asset")}
          </div>
        </form>
      </div>

      {!(!assetValue || !networkValue || !regionValue) && (
        <div className="exchange__order">{renderExchangesBlock()}</div>
      )}
    </main>
  );
};

export default Exchange;
