import { useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";

const getTokenCredentials = async (
  assetValue: string,
  networkValue: string
) => {
  return authApi
    .get(
      `/dashboard/deposit-address?network=${networkValue}&asset=${assetValue}`
    )
    .then((res) => res.data);
};

export const useGetTokenCredentials = (
  assetValue: string,
  networkValue: string
) => {
  const {
    data: tokenCredentials,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["TokenCredentials"],
    queryFn: () => getTokenCredentials(assetValue, networkValue),
    enabled: !!assetValue && !!networkValue,
  });

  return {
    tokenCredentials,
    isLoading,
    isError,
    refetch,
  };
};
