import React, { FC, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Avalanche from "../Avalanche/Avalanche";
import Crowdfunding from "../Crowdfunding/Сrowdfunding";
import Dashboard from "../Dashboard/Dashboard";
import Exchange from "../Exchange/Exchange";
import Settings from "../Settings/Settings";
import { socials } from "../../const/socials";
import Swap from "../Swap/Swap";
import Send from "../Send/Send";
import Recieve from "../Recieve/Recieve";
import History from "../History";
import { useGetNotificationList } from "../../services/notifications.services";
import {
  useGetPersonalInfo,
  useGetUserAvatar,
} from "../../services/settings.services";

const User: FC = () => {
  const { user, logout } = useAuth();
  const { notifications } = useGetNotificationList();
  const [hideSidebar, setHideSidebar] = useState(false);
  const [selectedPage, setSelectedPage] = useState<string>("dashboard");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);

  const [avatar, setAvatar] = useState<string>("./img/person.png");

  const { personalInfo } = useGetPersonalInfo();
  const { userAvatar, refetch } = useGetUserAvatar(personalInfo?.photo_name!);

  const pageNames = [
    "dashboard",
    "crowdfunding",
    "avalanche",
    "exchange",
    "settings",
    "swap",
    "history",
  ];
  const pageDiscriptions: { [index: string]: string } = {
    dashboard: `We support: USDT TRON, USDT BSC, TRX, BNB, BTC, BRT`,
    crowdfunding: `This service is provided only in the BRT cryptocurrency. To participate in
            crowdfunding, the User must either already have BRT, or purchase it on the Exchange page, or exchange (SWAP)
            one of the other cryptocurrencies for BRT.`,
    avalanche: `A unique opportunity to earn with the Avalanche Club project by attracting new participants. You can withdraw your funds immediately after they are credited to your wallet. Start at the GS-I level and reinvest a portion of your earnings into advancing to higher levels.`,
    exchange: `On the Exchange page, you can find links to trusted exchangers where you can swap your selected currency. Choose the best option and exchange your funds securely and conveniently.`,
    settings: ``,
    history: "We support: USDT TRON, USDT BSC, TRX, BNB, BTC, BRT",
  };

  const pages: { [index: string]: React.ReactNode } = {
    dashboard: <Dashboard setSelectedPage={setSelectedPage} />,
    crowdfunding: <Crowdfunding />,
    avalanche: <Avalanche />,
    exchange: <Exchange />,
    settings: <Settings />,
    swap: <Swap />,
    send: <Send />,
    recieve: <Recieve />,
    history: <History setSelectedPage={setSelectedPage} />,
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
        setIsNotificationsOpen(false);
      }
    };

    document?.addEventListener("mousedown", handleClickOutside);
    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (personalInfo) {
      refetch();
    }
  }, [personalInfo, refetch]);

  useEffect(() => {
    if (personalInfo?.photo_name && personalInfo?.photo_name?.length > 0) {
      setAvatar(userAvatar!);
    }
  }, [personalInfo, userAvatar]);

  return (
    <div className="wrapper">
      <div className={`side js-side ${hideSidebar ? "minify" : ""}`}>
        <aside className="sidebar">
          <div className="sidebar__header">
            <div className="sidebar__logo-container">
              <div className="sidebar__logo">
                <img src="./img/logo.svg" alt="logo" />
              </div>
            </div>
            <button
              onClick={() => setHideSidebar((prev) => !prev)}
              type="button"
              className="sidebar__header-btn js-side-toggle"
            >
              <svg className="sidebar__header-btn-icon sidebar__header-btn-icon--left">
                <use xlinkHref="img/sprite.svg#arrow"></use>
              </svg>
              <svg className="sidebar__header-btn-icon">
                <use xlinkHref="img/sprite.svg#arrow"></use>
              </svg>
            </button>
          </div>
          <nav className="sidebar__nav nav">
            <ul className="nav__list">
              {pageNames?.map((v, i) => {
                if (
                  v !== "settings" &&
                  v !== "swap" &&
                  i !== pageNames.length - 1
                ) {
                  return (
                    <li className="nav__item" key={i}>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedPage(v)}
                        className={`nav__link ${
                          selectedPage === v && "active"
                        }`}
                      >
                        <svg className="nav__link-icon">
                          <use xlinkHref={`img/sprite.svg#${v}`}></use>
                        </svg>
                        <p className="nav__link-text">
                          {v[0].toUpperCase() +
                            v.substr(1).toLowerCase() +
                            (v === "avalanche" ? " Club" : "")}
                        </p>
                        <svg className="nav__link-arrow">
                          <use xlinkHref="img/sprite.svg#arrow"></use>
                        </svg>
                      </button>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
            <ul className="nav__list nav__list--settings">
              <li className="nav__item nav__item--account">
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedPage("settings")}
                  className={`nav__link ${
                    selectedPage === "settings" && "active"
                  }`}
                >
                  <svg className="nav__link-icon">
                    <use xlinkHref="img/sprite.svg#settings"></use>
                  </svg>
                  <p className="nav__link-text">Settings</p>
                  <svg className="nav__link-arrow">
                    <use xlinkHref="img/sprite.svg#arrow"></use>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
          <div className="sidebar__socials">
            <ul className="socials">
              {socials.map((icon) => (
                <li className="socials__item" key={icon.id}>
                  <a
                    href={icon.href}
                    rel="noreferrer"
                    target="_blank"
                    className="socials__link"
                  >
                    <svg className="socials__icon">
                      <use xlinkHref={`img/sprite.svg#${icon.id}`}></use>
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      </div>

      <div className={`page js-main ${hideSidebar ? "wide" : ""}`}>
        <header className="header">
          <div className="header__logo">
            <img src="./img/logo.svg" alt="logo" />
          </div>
          <div className="header__text-block">
            <h1 className="header__title">
              {selectedPage[0].toUpperCase() +
                selectedPage.substr(1).toLowerCase() +
                (selectedPage === "avalanche" ? " Club" : "")}
            </h1>
            <p className="header__text">{pageDiscriptions[selectedPage]}</p>
          </div>

          <div className="header__actions">
            <div
              className="header__notification-container"
              ref={notificationsRef}
            >
              <button
                type="button"
                className="header__notification notification"
                onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
              >
                <svg className="notification__icon">
                  <use xlinkHref="img/sprite.svg#notification"></use>
                </svg>
              </button>
              {isNotificationsOpen && (
                <div className="notifications-dropdown">
                  {notifications && notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <div key={notification.id} className="notification-item">
                        <div className="notification-item__title">
                          {notification.title}
                        </div>
                        <div className="notification-item__content">
                          {notification.content}
                        </div>
                        <div className="notification-item__date">
                          {new Date(
                            notification.created_at
                          ).toLocaleDateString()}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="notification-empty">No notifications.</div>
                  )}
                </div>
              )}
            </div>
            <div className="header__profile-container" ref={dropdownRef}>
              <button
                type="button"
                className="header__profile profile"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className="profile__user">
                  <div className="profile__ava">
                    <img className="profile__img" src={avatar} alt="ava" />
                  </div>
                </div>
                <div className="profile__info">
                  <div className="profile__name">{user?.full_name}</div>
                  <div className="profile__settings">Account settings</div>
                </div>
                <svg className="profile__icon">
                  <use xlinkHref="img/sprite.svg#arrow"></use>
                </svg>
              </button>
              {isDropdownOpen && (
                <div className="profile-dropdown">
                  <button
                    type="button"
                    className="profile-dropdown__item"
                    onClick={handleLogout}
                  >
                    <img src="/img/svg/logout.svg" alt="logout" />
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>

        {pages[selectedPage]}
      </div>
    </div>
  );
};

export default User;
