import React, { FC, useEffect } from "react";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


interface IHistogram  {
    data: Array<any>
}

const Histogram : FC<IHistogram> = ({ data }) => {

    useEffect(() => console.log(data), [])

  return (
    <ResponsiveContainer width="100%" height="100%">
        <BarChart margin={{left: -40}} data={data}>
            <XAxis dataKey="month" tickLine={false} fontSize={10} axisLine={false} stroke="#b8c2e2" />
            <YAxis scale={"point"} tickLine={false} fontSize={10} axisLine={false} stroke="#b8c2e2"/>
            <Bar  barSize={5} dataKey="count" fill="#09a5e9" />
        </BarChart>
    </ResponsiveContainer>
  );
};

export default Histogram;
