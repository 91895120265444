import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Area,
} from "recharts";

interface IProps {
  analytics: any;
}

const StatisticsChart: React.FC<IProps> = ({ analytics }) => {
  const hasRevenue = Object.keys(analytics?.revenue || {});
  const hasExpenses = Object.keys(analytics?.expenses || {});

  const data =
    hasRevenue || hasExpenses
      ? Object.keys(
          (hasExpenses ? analytics?.expenses : analytics?.revenue) || {}
        )?.map((timestamp) => ({
          date: new Date(timestamp).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
          }),
          revenue: hasRevenue ? analytics?.revenue[timestamp] : null,
          expenses: hasExpenses ? analytics?.expenses[timestamp] : null,
        }))
      : null;

  return (
    <div className="chart-section__graph">
      {data && (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ right: 0, left: -30, bottom: -10 }}>
            <XAxis
              dataKey="date"
              stroke="#AAAAAA"
              tick={{ fill: "#CCCCCC", fontSize: 12 }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              stroke="#AAAAAA"
              tick={{ fill: "#CCCCCC", fontSize: 12 }}
              axisLine={false}
              tickLine={false}
              domain={[0, "dataMax"]}
            />
            <CartesianGrid
              stroke="#FFFFFF"
              strokeOpacity={0.1}
              vertical={false}
            />
            <Tooltip
              filterNull={false}
              cursor={{ stroke: "#FFFFFF", strokeWidth: 1 }}
              content={({ active, payload, label, coordinate }) => {
                if (active && payload && payload.length) {
                  const { x, y } = coordinate as any;
                  return (
                    <div
                      className="chart-section__point amount-point"
                      style={{
                        position: "absolute",
                        left: x,
                        top: y - 60,
                        transform: "translate(-50%, 0)",
                      }}
                    >
                      <div className="amount-point__top">
                        {hasRevenue && (
                          <div className="amount-point__text">
                            {Number(payload[0].value).toFixed(2)}
                          </div>
                        )}
                        {hasExpenses && (
                          <div className="amount-point__text">
                            {Number(payload[1]?.value).toFixed(2)}
                          </div>
                        )}
                        {/* <div className="amount-point__change amount-point__change--up">
                          <span className="amount-point__change-amount">
                            12.5%
                          </span>
                          <svg className="amount-point__change-icon">
                            <use xlinkHref="img/sprite.svg#arrow-change"></use>
                          </svg>
                        </div> */}
                      </div>
                      <span className="amount-point__date">{label}</span>
                    </div>
                  );
                }
                return null;
              }}
            />
            {hasRevenue && (
              <>
                <Area
                  type="monotone"
                  dataKey="revenue"
                  stroke="#F7A400"
                  fill="#F7A400"
                  fillOpacity={0.2}
                  strokeWidth={2}
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="revenue"
                  stroke="#F7A400"
                  strokeWidth={2}
                  dot={false}
                  fillOpacity={0.2}
                  connectNulls={true}
                />
              </>
            )}
            {hasExpenses && (
              <>
                <Area
                  type="monotone"
                  dataKey="expenses"
                  stroke="#1E90FF"
                  fill="#1E90FF"
                  fillOpacity={0.2}
                  strokeWidth={2}
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="expenses"
                  stroke="#1E90FF"
                  strokeWidth={2}
                  dot={false}
                  fillOpacity={0.2}
                  connectNulls={true}
                />
              </>
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default StatisticsChart;
