import { FC, useState } from "react";
import Google from "./Google";
import KYC from "./KYC";
import { useAuth } from "../../../../hooks/useAuth";
import { useGetHasKycValue } from "../../../../services/settings.services";

const Verification: FC = () => {
  const [selectedPage, setSelectedPage] = useState<string>("Default");
  const { user } = useAuth();
  const { hasKyc } = useGetHasKycValue();

  const pages: { [index: string]: React.ReactNode } = {
    Default: (
      <div className="settings__form form-settings">
        <ul className="form-settings__verification-methods verification-method">
          <li className="verification-method__item">
            <button
              className="verification-method__link"
              onClick={() => setSelectedPage("KYC")}
              disabled={hasKyc?.result}
            >
              <div className="verification-method__icon">
                <img src="./img/svg/verification.svg" alt="icon" />
              </div>
              <span className="verification-method__text">KYC</span>
              {hasKyc?.result && (
                <img
                  className="verification-method__completed"
                  src="./img/svg/done-circle.svg"
                  alt="connected"
                />
              )}
            </button>
          </li>
          <li
            className="verification-method__item"
            onClick={() => setSelectedPage("Google")}
          >
            <button
              disabled={user?.is_2fa_enabled}
              className="verification-method__link"
            >
              <div className="verification-method__icon">
                <img src="./img/svg/google-logo.svg" alt="google-logo" />
              </div>
              <span className="verification-method__text">
                Google Authentication
              </span>
              {user?.is_2fa_enabled && (
                <img
                  className="verification-method__completed"
                  src="./img/svg/done-circle.svg"
                  alt="connected"
                />
              )}
            </button>
          </li>
        </ul>
      </div>
    ),
    Google: <Google setSelectedPage={setSelectedPage} />,
    KYC: <KYC setSelectedPage={setSelectedPage} />,
  };

  const text: { [index: string]: string } = {
    Default: "Choose verification method",
    Google: "Google Authentication",
    KYC: "KYC",
  };
  return (
    <div className="settings__body">
      <div className="settings__card">
        <div className="settings__header">
          <h2 className="settings__title">Verification</h2>
          <div className="settings__subtitle">{text[selectedPage]}</div>
        </div>
        {pages[selectedPage]}
      </div>
    </div>
  );
};

export default Verification;
