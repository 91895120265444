import React, { FC, useEffect, useState } from 'react'

const Support : FC = () => {

  return (
    <div className="users">
          <div className="users__table ">
            <div className="table">
              <div className="table__header">
                <h2 className="table__title">Users list</h2>
              </div>
              <div className="table__body">
                <div className="table__row">
                  <ul className="table__items table__items--title">
                    <li className="table__item table__item--id">ID</li>
                    <li className="table__item">Date</li>
                    <li className="table__item">N Ticket</li>
                    <li className="table__item">Type</li>
                    <li className="table__item">Status</li>
                    <li className="table__item"></li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id table__item-id--check">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Admin 1</li>
                    <li className="table__item">Order <a href="#" className="table__item-link">#30498567</a></li>
                    <li className="table__item">
                      <div className="table__item-status table__item-status--published">In Progress</div>
                    </li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Admin 1</li>
                    <li className="table__item">Security Problem</li>
                    <li className="table__item">
                      <div className="table__item-status table__item-status--canceled">Cancel</div>
                    </li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id table__item-id--check">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Admin 1</li>
                    <li className="table__item">Order <a href="#" className="table__item-link">#30498567</a>
                    </li>
                    <li className="table__item">
                      <div className="table__item-status table__item-status--action">Need Action</div>
                    </li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="table__row">
                  <ul className="table__items">
                    <li className="table__item">
                      <div className="table__item-id">#1532</div>
                    </li>
                    <li className="table__item">12.03.25/11.00</li>
                    <li className="table__item">Admin 1</li>
                    <li className="table__item">Transaction Problem</li>
                    <li className="table__item">
                      <div className="table__item-status table__item-status--canceled">Cancel</div>
                    </li>
                    <li className="table__item">
                      <div className="table__item-actions">
                        <button className="table__item-edit">
                          <svg className="table__item-edit-icon">
                            <use xlinkHref="img/sprite.svg#email"></use>
                          </svg>
                        </button>
                        <button className="table__item-basket">
                          <svg className="table__item-basket-icon">
                            <use xlinkHref="img/sprite.svg#clip"></use>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
  )
}

export default Support