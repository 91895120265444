/* eslint-disable array-callback-return */
import Cookies from "js-cookie";
import { useState } from "react";
import { toast } from "react-toastify";
import Input from "../../../../components/Input/Input";
import { IInput } from "../../../../components/Input/input.types";
import nationalities from "../../../Register/nationalities.json";

interface Inputs {
  firstStage?: boolean;
  secondStage?: boolean;
  thirdStage?: boolean;
  input: IInput;
  group?: string;
}

const KYC = ({
  setSelectedPage,
}: {
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [inputs, setInputs] = useState<Inputs[]>([
    {
      firstStage: true,
      input: {
        type: "settingsText",
        id: "residency",
        value: "",
        placeholder: "",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
    },
    {
      secondStage: true,
      input: {
        type: "settingsDropdown",
        id: "nationality",
        value: "",
        values: nationalities,
        placeholder: "Nationality",
        error: undefined,
      },
      group: "Nationality",
    },
    {
      secondStage: true,
      input: {
        type: "settingsText",
        id: "firstName",
        value: "",
        placeholder: "First Name",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
      group: "Legal Name",
    },
    {
      secondStage: true,
      input: {
        type: "settingsText",
        id: "middleName",
        value: "",
        placeholder: "Middle Name",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
      group: "Legal Name",
    },
    {
      secondStage: true,
      input: {
        type: "settingsText",
        id: "lastName",
        value: "",
        placeholder: "Last Name",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
      group: "Legal Name",
    },
    {
      secondStage: true,
      input: {
        type: "settingsText",
        id: "yyyy",
        value: "",
        placeholder: "YYYY",
        error: undefined,
        regex: /^\d{1,4}$/,
      },
      group: "Date of Birth",
    },
    {
      secondStage: true,
      input: {
        type: "settingsText",
        id: "mm",
        value: "",
        placeholder: "MM",
        error: undefined,
        regex: /^\d{1,2}$/,
      },
      group: "Date of Birth",
    },
    {
      secondStage: true,
      input: {
        type: "settingsText",
        id: "dd",
        value: "",
        placeholder: "DD",
        error: undefined,
        regex: /^\d{1,2}$/,
      },
      group: "Date of Birth",
    },
    {
      thirdStage: true,
      input: {
        type: "settingsText",
        id: "street",
        value: "",
        placeholder: "Street",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
      group: "Full Address",
    },
    {
      thirdStage: true,
      input: {
        type: "settingsText",
        id: "apt",
        value: "",
        placeholder: "Apt",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
      group: "Full Address",
    },
    {
      thirdStage: true,
      input: {
        type: "settingsText",
        id: "city",
        value: "",
        placeholder: "City",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
      group: "City",
    },
    {
      thirdStage: true,
      input: {
        type: "settingsText",
        id: "country",
        value: "",
        placeholder: "Country",
        error: undefined,
        regex: /^[a-zA-Z\s'.-]{2,}$/,
      },
      group: "Country",
    },
  ]);
  const [step, setStep] = useState(1);
  const [filesDrive, setFilesDrive] = useState("");
  const [filesPassport, setFilesPassport] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let curValues: { [index: string]: string | boolean } = {};
    if (step === 1) {
      let hasErrors = false;
      const newInputs = inputs.map((v) => {
        if (v.input.type !== "multitext" && v.firstStage) {
          if (v.input.value === "") {
            v.input.error = "Can't be empty";
            hasErrors = true;
          }
          if (v.input.error) {
            hasErrors = true;
          }
        }

        return v;
      });
      setInputs(newInputs);
      if (!hasErrors) {
        setStep(2);
      }
    } else if (step === 2) {
      let hasErrors = false;
      const newInputs = inputs.map((v) => {
        if (v.input.type !== "multitext") {
          if (v.secondStage) {
            if (v.input.value === "") {
              v.input.error = "Can't be empty";
              hasErrors = true;
            }
            if (v.input.error) {
              hasErrors = true;
            }
          }
        }

        return v;
      });
      setInputs(newInputs);
      if (!hasErrors) {
        setStep(3);
      }
    } else if (step === 3) {
      let hasErrors = false;
      const newInputs = inputs.map((v) => {
        if (v.input.type !== "multitext") {
          if (v.thirdStage) {
            if (v.input.value === "") {
              v.input.error = "Can't be empty";
              hasErrors = true;
            }
            if (v.input.error) {
              hasErrors = true;
            }
          }
        }

        return v;
      });
      setInputs(newInputs);
      if (!hasErrors) {
        newInputs.forEach((v) => {
          if (v.input.type !== "multitext")
            curValues[v.input.id] = v.input.value;
        });

        const body = {
          residency: curValues.residency,
          nationality: curValues.nationality,
          first_name: curValues.firstName,
          last_name: curValues.lastName,
          middle_name: curValues.middleName,
          birthdate: `${curValues.yyyy}-${curValues.mm}-${curValues.dd}`,
          address_street: curValues.street,
          address_apt: curValues.apt,
          address_city: curValues.city,
          address_region: curValues.country,
        };
        try {
          const accessToken = Cookies.get("access_token");

          if (!accessToken) {
            throw new Error("No accessToken token available");
          }

          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/users/kyc`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(body),
            }
          );
          const data = await res.json();

          if (res.status === 201 && data) {
            setStep(4);
          } else {
            toast.error(data["message"]);
          }
        } catch (error) {}
      }
    } else if (step === 4) {
      setStep(5);
    } else if (step === 5) {
      setSelectedPage("Default");
    }
  };

  const setInputValue = (input_id: string, value: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.input.type !== "multitext") {
          if (v.input.id === input_id) {
            v.input.value = value;
            if (v.input.type === "settingsText") {
              if (v.input.value === "") {
                v.input.error = "Can't be empty";
              } else {
                v.input.error = "";
              }
            }
          }
        }
        return v;
      })
    );
  };

  const setInputError = (input_id: string, error: string | boolean) => {
    setInputs((input) =>
      input.map((v) => {
        if (v.input.type !== "multitext") {
          if (v.input.id === input_id) {
            v.input.error = error.toString();
          }
        }
        return v;
      })
    );
  };

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const input = e.target as HTMLInputElement;

    if (input.files) {
      const filesArr = Array.from(input.files);

      try {
        const accessToken = Cookies.get("access_token");

        if (!accessToken) {
          throw new Error("No accessToken token available");
        }
        const formData = new FormData();

        formData.append("photo", filesArr[0]);

        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/users/kyc/${type}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          }
        );
        const data = await res.json();

        if (res.status === 201 && data) {
          if (filesArr[0] && filesArr[0].name) {
            if (type === "driver") setFilesDrive(filesArr[0].name);
            if (type === "passport") setFilesPassport(filesArr[0].name);
            toast.success("Success");
          }
        } else {
          toast.error(data["message"]);
        }
      } catch (error) {
        if (type === "driver") setFilesDrive("");
        if (type === "passport") setFilesPassport("");
      }
    }
  };

  return (
    <form className="settings__form form-settings" onSubmit={handleSubmit}>
      {step === 1 ? (
        <>
          <div className="form-settings__verification verification">
            <div className="verification__header">
              <h2 className="verification__title">KYC Verification</h2>
              <p className="verification__subtitle">Lets get you verified</p>
            </div>
            <div className="verification__body">
              <div className="verification__block">
                <h3 className="verification__block-title">
                  <img src="./img/svg/location.svg" alt="icon" />
                  Residency
                </h3>
                <div className="verification__items">
                  <div className="verification__item">
                    <div className="form-settings__input-wrapper">
                      {inputs.map((v) => {
                        if (v.firstStage) {
                          return (
                            <Input
                              input={v.input}
                              setInputValue={setInputValue}
                              setInputError={setInputError}
                            />
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="form-settings__button form-settings__button--verification button"
            type="submit"
          >
            Continue
          </button>
        </>
      ) : step === 2 ? (
        <>
          <div className="form-settings__verification verification">
            <div className="verification__header">
              <h2 className="verification__title">Personal Information</h2>
              <p className="verification__subtitle">
                Please provide the following information. <br />
                Your information will be only used for verification purpose
              </p>
            </div>
            <div className="verification__body">
              <div className="verification__block">
                <h3 className="verification__block-title">Nationality</h3>
                <div className="verification__items">
                  <div className="verification__item">
                    <div className="form-settings__input-wrapper">
                      {inputs.map((v) => {
                        if (v.secondStage && v.group === "Nationality") {
                          return (
                            <Input
                              input={v.input}
                              setInputValue={setInputValue}
                              setInputError={setInputError}
                            />
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="verification__block">
                <h3 className="verification__block-title">Legal Name</h3>
                <div className="verification__items">
                  {inputs.map((v) => {
                    if (v.secondStage && v.group === "Legal Name") {
                      return (
                        <Input
                          input={v.input}
                          setInputValue={setInputValue}
                          setInputError={setInputError}
                        />
                      );
                    }
                  })}
                </div>
              </div>
              <div className="verification__block">
                <h3 className="verification__block-title">Date of Birth</h3>
                <div className="verification__items verification__items--row">
                  {inputs.map((v) => {
                    if (v.secondStage && v.group === "Date of Birth") {
                      return (
                        <div className="verification__item">
                          <Input
                            input={v.input}
                            setInputValue={setInputValue}
                            setInputError={setInputError}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <button
            className="form-settings__button form-settings__button--verification button"
            type="submit"
          >
            Continue
          </button>
        </>
      ) : step === 3 ? (
        <>
          <div className="form-settings__verification verification">
            <div className="verification__header">
              <h2 className="verification__title">Home Address</h2>
              <p className="verification__subtitle">
                Ensure this is your current residential address
              </p>
            </div>
            <div className="verification__body">
              <div className="verification__block">
                <h3 className="verification__block-title">Full Address</h3>
                <div className="verification__items">
                  {inputs.map((v) => {
                    if (v.thirdStage && v.group === "Full Address") {
                      return (
                        <div className="verification__item">
                          <Input
                            input={v.input}
                            setInputValue={setInputValue}
                            setInputError={setInputError}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="verification__block">
                <h3 className="verification__block-title">City</h3>
                <div className="verification__items">
                  <div className="verification__item">
                    {inputs.map((v) => {
                      if (v.thirdStage && v.group === "City") {
                        return (
                          <div className="verification__item">
                            <Input
                              input={v.input}
                              setInputValue={setInputValue}
                              setInputError={setInputError}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="verification__block">
                <h3 className="verification__block-title">Country/Region</h3>
                <div className="verification__items">
                  <div className="verification__item">
                    {inputs.map((v) => {
                      if (v.thirdStage && v.group === "Country") {
                        return (
                          <div className="verification__item">
                            <Input
                              input={v.input}
                              setInputValue={setInputValue}
                              setInputError={setInputError}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="form-settings__button form-settings__button--verification button"
            type="submit"
          >
            Continue
          </button>
        </>
      ) : step === 4 ? (
        <>
          <div className="form-settings__verification verification">
            <div className="verification__header">
              <h2 className="verification__title">ID documents Verification</h2>
              <p className="verification__subtitle">
                Please upload photos of documents
              </p>
            </div>
            <div className="verification__body">
              <div className="verification__block">
                <h3 className="verification__block-title">ID</h3>
                <div className="verification__items">
                  <div className="verification__item">
                    <div className="form-settings__input-wrapper">
                      <input
                        type="text"
                        className="form-settings__input"
                        value={filesPassport}
                        placeholder="Your ID Passport"
                      />

                      <input
                        id="input-file-passport"
                        onChange={(e) => handleFileChange(e, "passport")}
                        multiple={false}
                        type="file"
                        className="hidden-input"
                        accept=".png, .jpeg"
                      />
                      <label
                        htmlFor="input-file-passport"
                        className="form-settings__input-btn form-settings__input-btn--upload"
                      >
                        <span className="form-settings__input-text">
                          Upload
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="verification__block">
                <h3 className="verification__block-title">Driver License</h3>
                <div className="verification__items">
                  <div className="verification__item">
                    <div className="form-settings__input-wrapper">
                      <input
                        type="text"
                        className="form-settings__input"
                        value={filesDrive}
                        placeholder="Your driver license"
                      />
                      <input
                        id="input-file-driver"
                        onChange={(e) => handleFileChange(e, "driver")}
                        multiple={false}
                        type="file"
                        className="hidden-input"
                        accept=".png, .jpeg"
                      />
                      <label
                        htmlFor="input-file-driver"
                        className="form-settings__input-btn form-settings__input-btn--upload"
                      >
                        <span className="form-settings__input-text">
                          Upload
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="form-settings__button form-settings__button--verification button"
            type="submit"
          >
            Complete
          </button>
        </>
      ) : (
        <>
          <div className="form-settings__verification verification">
            <div className="verification__header">
              <h2 className="verification__title">
                Verification completed successfully
              </h2>
              <p className="verification__subtitle">
                Now you can use the full range of functions of our service
              </p>
            </div>
          </div>
          <button
            className="form-settings__button form-settings__button--verification button"
            type="submit"
          >
            Done
          </button>
        </>
      )}
    </form>
  );
};

export default KYC;
