import { toast } from "react-toastify";
import * as yup from "yup";
import Modal from "../../components/Modal";
import NewPartner from "../../components/Modal/NewPartner";
import { FC, useState, useMemo, useRef, useEffect } from "react";
import { IPartnerForm } from "../../types/types";
import {
  useCreatePartners,
  useDeletePartner,
  useGetPartners,
  useUpdatePartner,
} from "../../services/partners.services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const NewPartnerFormSchema = yup.object().shape({
  name: yup.string().required("Name is a required field!"),
  symbols: yup.array(yup.string()).required("Currencies is a required field!"),
  country: yup.string().required("Region is a required field!"),
  url: yup.string().required("Link is a required field!"),
});

const Partners: FC = () => {
  const [isNewPartnerModalOpen, setIsNewPartnerModalOpen] = useState(false);
  const [partnerSearch, setPartnerSearch] = useState("");
  const [editingPartner, setEditingPartner] = useState<IPartnerForm | null>(
    null
  );

  const { partners = [], refetch } = useGetPartners();

  const timeoutRef = useRef<NodeJS.Timeout>();

  // Фильтрация партнеров на стороне клиента
  const filteredPartners = useMemo(() => {
    const searchTerm = partnerSearch.toLowerCase().trim();
    if (!searchTerm) return partners;

    return partners.filter(
      (partner: IPartnerForm) =>
        partner.name.toLowerCase().includes(searchTerm) ||
        partner.country.toLowerCase().includes(searchTerm)
    );
  }, [partners, partnerSearch]);

  const { mutate: createPartner } = useCreatePartners(
    () => {
      refetch();
      toast.success("Partner created!");
      setIsNewPartnerModalOpen(false);
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const { mutate: updatePartner } = useUpdatePartner(
    () => {
      refetch();
      toast.success("Partner updated!");
      setIsNewPartnerModalOpen(false);
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const { mutate: deletePartner } = useDeletePartner(
    () => {
      toast.success("Partner deleted!");
      refetch();
    },
    (data) => {
      toast.error(data.response.data.message);
    }
  );

  const useReturnForm = useForm({
    resolver: yupResolver(NewPartnerFormSchema),
  });

  const onSubmit = (data: IPartnerForm) => {
    if (editingPartner) {
      updatePartner({ ...data, id: editingPartner?.id } as any);
    } else {
      createPartner(data);
    }
  };

  const handleEditPartner = (partner: IPartnerForm) => {
    setEditingPartner(partner);
    setIsNewPartnerModalOpen(true);
    useReturnForm.reset(partner);
  };

  const handleDeletePartner = (id: string, name: string) => {
    if (window.confirm(`Are you sure you want to delete partner "${name}"?`)) {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        deletePartner(id);
      }, 100);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="users">
        <div className="users__table">
          <div className="table" style={{ minHeight: "80vh", height: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="table__header">
                <h2 className="table__title">Partners list</h2>
                <form
                  className="table__inputs"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="table__input-search input-search">
                    <svg className="input-search__icon">
                      <use xlinkHref="img/sprite.svg#search"></use>
                    </svg>
                    <input
                      type="text"
                      value={partnerSearch}
                      onChange={(e) => setPartnerSearch(e.target.value)}
                      placeholder="Search by name or region..."
                      className="input-search__control"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </form>
              </div>

              <button
                className="button"
                style={{
                  maxWidth: "max-content",
                  padding: 10,
                  marginRight: 20,
                }}
                onClick={() => {
                  setEditingPartner(null);
                  setIsNewPartnerModalOpen(true);
                }}
              >
                Add partner
              </button>
            </div>
            <div className="table__body">
              <div className="table__row">
                <ul className="table__items table__items--title">
                  <li className="table__item admin-table__item--id">ID</li>
                  <li className="table__item">Partner</li>
                  <li className="table__item">Currencies</li>
                  <li className="table__item">Region</li>
                  <li className="table__item">Link</li>
                  <li className="table__item"></li>
                </ul>
              </div>
              {filteredPartners.length > 0 ? (
                filteredPartners.map((v: any) => (
                  <div className="table__row" key={v.id}>
                    <ul className="table__items">
                      <li className="table__item">
                        <div
                          className={`admin-table__item--id ${
                            false ? "table__item-id--check" : ""
                          }`}
                        >
                          #{v.id}
                        </div>
                      </li>
                      <li className="table__item">{v.name}</li>
                      <li className="table__item">
                        {v?.symbols
                          ?.map(
                            (item: string) =>
                              `${item.split("_")[0]} ${
                                item.split("_")[1]
                                  ? `(${item.split("_")[1]})`
                                  : ""
                              }`
                          )
                          .join(", ")}
                      </li>
                      <li className="table__item">{v.country}</li>
                      <li className="table__item">{v.url}</li>
                      <li className="table__item">
                        <div className="table__item-actions">
                          <button
                            onClick={() => handleEditPartner(v)}
                            className="table__item-edit"
                          >
                            <svg className="table__item-edit-icon">
                              <use xlinkHref="img/sprite.svg#edit"></use>
                            </svg>
                          </button>
                          <button
                            onClick={() => handleDeletePartner(v.id, v.name)}
                            className="table__item-basket"
                          >
                            <svg className="table__item-basket-icon">
                              <use xlinkHref="img/sprite.svg#basket"></use>
                            </svg>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                ))
              ) : (
                <div className="table__empty">No partners found</div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isNewPartnerModalOpen}
        setIsOpen={setIsNewPartnerModalOpen}
        title={editingPartner ? "Edit partner" : "New partner"}
      >
        <NewPartner
          returnFormInstance={useReturnForm as any}
          onSubmit={onSubmit}
        />
      </Modal>
    </>
  );
};

export default Partners;
