const PrivacyPolicy = ({
  modalRef,
  isOpen,
  closeModal,
  showAnimateClass,
}: {
  modalRef: React.MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  closeModal: () => void;
  showAnimateClass: boolean;
}) => {
  return (
    <div
      ref={modalRef}
      className={`modal ${isOpen ? "is-open" : ""}`}
      onClick={closeModal}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`modal__container modal__container--privacy ${
          isOpen ? "modal-open fadeInUp" : ""
        } ${showAnimateClass ? "animate-open" : ""}`}
        role="dialog"
        aria-modal="true"
      >
        <button
          onClick={closeModal}
          type="button"
          className="modal__close js-modal-close"
          aria-label="close modal"
        >
          <svg className="modal__close-icon">
            <use xlinkHref="img/sprite.svg#close"></use>
          </svg>
        </button>
        <div className="modal__header">
          <h2 className="modal__title">Privacy Policy</h2>
        </div>
        <div className="privacy">
          <div className="privacy__block">
            <div className="privacy__text">
              <p>
                The following are the Privacy Policies of Brunotoken
                (hereinafter also referred to as "we"), applicable to all acts
                or registration processes, operation or in general any kind of
                movement that occurs or runs on the website www.brunotoken.com
                and its subdomains (hereinafter also referred to as "the
                Platform") by its Users (hereinafter also referred to as "you").
              </p>
              <p>
                "Personal information" or "personal data" will be understood as
                information related to a living, identified or identifiable
                person. The different pieces of information which may be
                collected to identify a certain person also consider personal
                data, including name, address, email, transaction information,
                and bank details. It doesn't include, on the contrary, anonymous
                information or that does not identify a specific user.
              </p>
              <p>
                In that sense, we understand and respect the due diligence
                standard in the protection of your personal data. For this
                reason, we have high-level IT security mechanisms, with servers
                that store information in an encrypted form and firmly protected
                which access is strongly controlled.
              </p>
              <p>
                It is precisely for these reasons, and those listed below, that
                in Bruno Kapital and Investment LLC we incorporate these Privacy
                Policies into the Terms of Service, so by registering on our
                website, you will also give your consent as if it were a single
                convention.
              </p>
              <p>
                Read carefully what is explained below, and refrain from
                accepting if you do not agree:
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              What information we request from Brunotoken Users?
            </h2>
            <div className="privacy__text">
              <p>
                Brunotoken considers five user groups or verification tiers:
                observer, basic, trader, trader plus and institutional. Upon
                this depends the amount of information we request in the "My
                Data" section at the time of registration or its subsequent
                phases, without prejudice to always reserve the right to request
                additional information later.
              </p>
              <p>
                Without being a restrictive list, among the data, we can
                request, collect, verify and store are: name and surname, number
                and copy of the identity document (valid and unexpired),
                nationality, profession, telephone number, address, email, bank
                account number, invoices or utility bills, among others.
              </p>

              <p>
                Brunotoken will always have the right to confirm the information
                provided verifying with public entities, public or private
                databases, specific companies, or requesting images or instant
                or digital photographs, for which the User expressly authorises
                us. The information obtained from these entities will be
                processed confidentially.
              </p>

              <p>
                Whenever you log in to the website through your account, you
                expressly agree that Brunotoken has access to all the
                information regarding with that account, including interests,
                likes, contacts, trends and any other content hosted on it.
              </p>

              <p>
                Also, when you use the Brunotoken services, we collect and store
                information that we automatically receive from your computer,
                phone and other devices. This information may include the URL
                from which the users come (whether or not they are on our
                website), what URL they access next (whether or not they are on
                our site), what browser they are using, and their IP addresses.
                Also, the visited pages, searches done, purchases or sales,
                among other information that may be stored and retained. This
                information is collected fundamentally to provide statistical
                data on the actions and search patterns of our users.
              </p>
              <p>
                If a person sends us correspondence, understood in a broad
                sense, that contains personal information, whether or not a
                user, we will always have the option to collect and store such
                information, including email, or to discard it.
              </p>
              <p>
                We will never request, by any means, passwords to access your
                bank accounts or your account on the Platform.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              How your information is used?
            </h2>
            <div className="privacy__text">
              <p>
                Our primary purpose is to collect information to provide a safe,
                efficient and personalised service. In general, we use personal
                data to create, develop, operate, deliver and improve our
                services, content and advertising.
              </p>
              <p>We may use personal information in the following ways:</p>

              <p>
                1. To maintain an adequate legal and regulatory compliance.{" "}
                <br /> a. Some of our main services are subject to legal and
                regulatory obligations, which require us to request and use some
                of your personal data, including identity, financial and
                employment information, your transactions and some data
                regarding the use of the platform. For instance, Brunotoken must
                identify and verify Users that use their platform to comply with
                anti-money laundering and terrorist financing regulations of
                different jurisdictions. Besides, we ask third parties to verify
                the user's identities, comparing the information they provide us
                and the information contained in those third parties databases,
                and in public records. For this reason, we can ask more details
                if you request to increase your limits of deposit and
                withdrawal, as well as if we believe that your transactions may
                constitute or be related to illegal activities. <br /> b.
                European Union (EU) residents: according to Article 6 of the
                General Data Protection Regulations (GDPR), in Brunotoken we
                process your information to comply with legal and regulatory
                requirements.
              </p>

              <p>
                2. In order to strengthen our Terms of Service and other
                agreements with third parties. <br /> a. Brunotoken receives
                sensitive information, such as identification data and financial
                data. Therefore, it is crucial for our clients and for us to be
                active regarding monitoring, research, prevention and mitigation
                of activities that may potentially be prohibited, illegal or may
                breach the Terms of Service. Additionally, for this reason, we
                may charge some fees based on the usage of our services.
                Brunotoken collects that information about your account and
                watches your interactions with our services. Likewise, we may
                use all or part of your information for these purposes. <br />{" "}
                b. Failure to provide information for the purposes mentioned
                above results in the suspension, termination or cancellation of
                your account. This is because we could not offer our Services
                but if it is not according to our Terms of Service.
              </p>

              <p>
                3. To provide the CryptoMKT Services. <br /> a. We process your
                personal data in order to provide our Services. For instance,
                when you want to buy a digital asset, we require some
                information such as your identification, contact information,
                and payment information if applicable. We could not provide
                these services without this kind of information.
              </p>
              <p>
                4. To provide communication services. <br /> a. We send you
                information about our administration and about your account to
                keep you updated with our Services, inform you about security
                updates, or to provide other information associated with your
                transactions. Without these communications, you may not be aware
                of relevant developments related to your account, which may
                affect how you use the service we provide.
              </p>
              <p>
                5. To provide customer service. <br />
                a. We use your personal data when you contact us to resolve
                doubts, disputes, to receive fees, or to solve other kinds of
                problems. We may also use your data to answer a question from
                another User if we consider relevant to do so. Without the
                processing of your information for these purposes, we might not
                respond to your requirements and encourage continued use of the
                Platform.
              </p>
              <p>
                6. To strengthen our quality control.
                <br /> a. We process your personal data in order to do a quality
                control and to train our Team, all to be sure to continue
                delivering the appropriate information. If we did not do this,
                you would be more exposed to experiencing problems in the
                Platform, such as interruptions or difficulties with your
                transactions. The need for this information processing lies in
                ensuring compliance with the contractual obligations that
                Brunotoken has with its Users.
              </p>
              <p>
                EU residents: For all the above points, excluding the first, we
                process your data based on our contract.
              </p>
              <p>
                7. For the security of the Platform and its information. <br />{" "}
                a. We process your personal information to strengthen the
                security of the Platform, to monitor and verify the identity of
                the Users, to access the Platform, to prevent fraudulent use of
                it, to fight spam and, in general, prevent risks, to comply with
                laws and applicable regulations. The internet threats are
                continually evolving, which makes it more critical than ever to
                update the information about your use of the Platform. If we do
                not do this, we may not be able to guarantee the security of the
                Platform.
              </p>
              <p>
                EU Residents: In accordance with the GDPR, we process your
                personal information to comply with our legal obligations.
              </p>
              <p>
                8. For research and development purposes. <br /> a. We process
                your personal information for a better understanding of the way
                in which the Users interact with the Brunotoken Platform. We
                also use this information to personalize, measure and improve
                the services of Brunotoken and the content of our website and
                apps, and to develop new services. Our justification for using
                the data for this purpose is the legitimate interest of
                Brunotoken in improving the service it provides.
              </p>
              <p>
                EU residents: we process this information to satisfy the
                legitimate interest of Brunotoken, as indicated above.
              </p>
              <p>
                9. To improve your experience in the Platform. <br /> a. We
                process your personal data to provide a personalized experience
                and implement the preferences that you choose. For example, you
                might agree to give us access to some information stored by
                third parties. If we do not process this data, we may not be
                able to ensure a good experience of one part or even the entire
                Platform.
              </p>
              <p>
                EU residents: we process this information to satisfy the
                legitimate interest of /, as indicated above.
              </p>
              <p>
                10.To facilitate acquisitions, mergers or corporate
                transactions. <br /> a. We may process any of your personal data
                related to your account if necessary in the context of
                acquisitions, mergers or corporate transactions of Brunotoken.
                You have the option to close your account if you do not want
                your personal information to be used for these purposes.
              </p>
              <p>
                EU Residents: According to the GDPR, we process this personal
                data to satisfy the legitimate interest of Brunotoken according
                to what has been indicated before.
              </p>
              <p>
                11.For marketing purposes <br /> a. We may send you
                communications to keep you informed about events of Brunotoken
                or other third parties associated with us; to send personalised
                advertising; to send you promotional offers. We can use the
                information about your user data from the Platform and your
                contact information to send you advertising. You can choose to
                stop receiving these communications at any time.
              </p>
              <p>
                EU Residents: According to the GDPR, we treat this personal data
                based on your consent.
              </p>
              <p>
                We will not use your personal information for other purposes
                than those we have indicated here without your permission. In
                the future, we may request your authorisation to share your
                personal information with third parties. You may refuse to share
                your information with those third parties or refuse to be used
                for purposes other than those you initially accepted. If you
                choose to limit the use of your personal information, some
                features of the Brunotoken Platform may not be available to you.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              Information about you that we obtain from third parties.
            </h2>
            <div className="privacy__text">
              <p>
                We may obtain information about you from third parties
                databases, as required or permitted by applicable laws. For
                instance, we could get it from public databases, from third
                parties that provide identity verification services, from
                marketing partners, and from social media platforms.
              </p>
              <p>
                Regarding the public databases and third parties that provide
                identity verification services, we obtain information from them
                to verify your identity. In turn, these third parties use a
                combination of government records and public information. This
                information may include your name, address, job or occupation,
                public employment profile, banking history, your status in any
                list of sanctions created by public authorities, and other
                relevant data. The sole purpose of this is to comply with our
                legal obligations, such as the prevention of money laundering.
              </p>

              <p>
                According to the GDPR, our legal basis for this is to comply
                with our legal obligations. In some instances, we may process
                additional information about you to verify that our Platform has
                not been used fraudulently or for other illegal activities. In
                those cases, the data processing is necessary for Brunotoken as
                it allows us to fulfil our contract with you and with other
                Users.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              How personal data is shared with third-party sites or services?
            </h2>
            <div className="privacy__text">
              <p>
                If you use your Brunotoken account to transfer digital assets
                for the purpose of trading or buying goods or services, you or
                we may also provide the seller with information such as your
                address, name and/or email to assist in your transaction with
                him. The seller, however, is not authorized to use this
                information to promote their services, unless you agree. If your
                transaction with the seller fails or later becomes invalid, we
                will inform you that it was not successful. To facilitate the
                resolution of disputes, we may provide the buyer with
                information about the seller's address, for the respective
                return of the goods.
              </p>
              <p>
                Regarding the transfer of digital assets between a User and a
                third party that is not a User, this third party may share
                information about you, such as your email address or your
                telephone number, which will be used to inform you that the
                transfer has been sent or been received by the third party. We
                may use this information to confirm that you are a Brunotoken
                User, to verify that digital asset transfers are enabled, and to
                notify you that you have already received the digital asset as a
                result of the transfer. If you ask us to certify that you are a
                Brunotoken customer before a third party, we will do so. If
                available, you can also choose between sending or requiring a
                transfer of digital assets from your email. In this case, your
                username will be used to notify the other user of your action,
                by email.
              </p>

              <p>
                Please keep in mind that other platforms with which you interact
                may have their privacy policies, and Brunotoken is not
                responsible for their operations, including, without this
                implying limitation, their practices regarding personal data.
                Its own privacy policy governs the information stored by third
                parties.
              </p>
              <p>
                If you authorise one or more third-party applications to access
                your Brunotoken account, the information you may have given us
                may be shared with those third parties. Unless you provide a
                broader authorisation than these Policies allow, it is not
                permitted for third parties to use this information for purposes
                other than facilitating your transactions using the Brunotoken
                Platform.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              How we protect and store your personal information?
            </h2>
            <div className="privacy__text">
              <p>
                We understand how important your privacy is, and that is why
                Brunotoken maintains (and requires its servers to keep)
                physical, technological and administrative safeguards to protect
                the security and confidentiality of the personal data that you
                provide to us.
              </p>
              <p>
                We may store and process all or part of your personal and
                transactional information, including certain payment
                information, such as your bank account number, in the countries
                of the world where our servers are located. We protect your
                personal data maintaining physical, electronic and procedural
                safeguards to comply with applicable laws and regulations.
              </p>

              <p>
                For example, we use computational safeguards such as firewalls
                and data encryption. Likewise, we control access to our offices
                and files, and we only authorise access to personal information
                to those who are part of the CrypoMarket Global Team that
                require it to comply with their working or contractual
                obligations.
              </p>
              <p>
                However, we do not guarantee that the loss, misuse, unauthorised
                acquisition or alteration of your information will not occur.
                Please consider that you play a fundamental role in the
                protection of your personal data. When you register on the
                Platform, it is essential that you choose a sufficiently long
                and complex password and please don't disclose it to third
                parties. You must notify us immediately if you notice any
                unauthorised access or use of your account has occurred.
              </p>
              <p>
                Brunotoken can not assure or guarantee the security or
                confidentiality of the information you give us or that you
                receive from us through wireless internet connections, including
                email, post mail or phone numbers.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              How can you access or change your personal information?
            </h2>
            <div className="privacy__text">
              <p>
                You are entitled to review, correct or amend your personal
                information, or to delete that information when it is not
                correct. You could do this at any time by entering in the "My
                data" section of your account. Without prejudice to this, for
                reasons of security and legal compliance, if we notice that you
                may have committed fraud or have committed an act that could be
                classified as illegal, we will keep the information you gave us
                initially.
              </p>
              <p>
                If you deactivate your account in Brunotoken, we will mark your
                account in our database as "Deactivated", but we will keep your
                account information in our database for 5 years. This is
                necessary to prevent fraud and prevent people who try to commit
                fraud from being able to avoid their detection by merely
                deactivating their account and opening another one. If you
                deactivate your account, your personal information will not be
                used by us for any other purpose, nor will it be sold or shared
                with third parties, except if necessary to prevent fraud or to
                comply with legal obligations, or following this Privacy Policy.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              Rights related to the use of your personal information
            </h2>
            <div className="privacy__text">
              <p>
                1. Access, correction and elimination. <br /> You have the right
                to access the personal information that you have given us
                according to the European Union GDPR and some other related
                data. You may also require wrong data to be corrected or
                deleted.Right to object
              </p>
              <p>
                2. Right to object. <br /> You may object to the use of your
                personal data for advertising purposes at any time, and you may
                object to the processing of all or part of your personal data,
                requiring that all or part of it be removed in some other
                circumstances.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              Custody of your personal information
            </h2>
            <div className="privacy__text">
              <p>
                We store our users' personal information securely during the
                life of your Brunotoken account. We only maintain your personal
                data as long as it is necessary for the purposes for which it
                was requested or obtained, including those to comply with legal
                requirements. Consider that the obligations of retention of
                information vary according to the jurisdiction in question.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              Personal information of underage users
            </h2>
            <div className="privacy__text">
              <p>
                If Brunotoken suspects or is certain that a registered user is
                under the age of 18, it will proceed to block the account and
                will not be allowed to buy or sell digital assets and will not
                be allowed to make deposits or withdrawals. Please let us know
                if you know of any individual under the age of 18 who is using
                our Platform so that we can take action regarding your case.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              International transfers of personal data
            </h2>
            <div className="privacy__text">
              <p>
                Our servers can store, transfer or perform other similar
                processes on your personal data in countries outside of your
                country of residence. For this reason, our contractual
                relationships with them always contain confidentiality clauses
                that allow us to safeguard your information.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              Use of cookies and similar technologies.
            </h2>
            <div className="privacy__text">
              <p>
                When you access our page, content, app or in general to the
                Brunotoken services, we or the servers with whom we work can
                install small files, called cookies or pixel tags, on your
                computer or device. We use these technologies to:
              </p>
              <p>
                1. Recognize you as a Brunotoken user. <br /> 2. Customize
                Brunotoken services, content and advertising. <br /> 3. Measure
                the effectiveness of our advertising; and <br /> 4. Collect
                information about your computer or other access devices, to
                mitigate risks, help prevent fraud, and promote trust and
                safety.
              </p>
              <p>
                We use three kinds of cookies. Some that are activated when you
                access the website or its content, which expire and have no
                effect once you close your session or close your browser. Others
                are permanent, which are installed in your browser until you
                delete them or they expire. The third type, called "Flash
                cookies", help protect the security of your account, detect
                irregularities within our duty to help prevent fraud, and
                endorse our page and services.
              </p>
              <p>
                We also encrypt our cookies so only we can interpret the
                information they store. You are free to reject them if your
                browser allows it, but this could interfere with the use of
                Brunotoken services. The help section of most browsers provide
                instructions on blocking, deleting or disabling cookies.
              </p>
              <p>
                You could find cookies or pixel tags from Brunotoken on websites
                that we do not control. For example, if you enter a web page
                created by a third party or use an app developed by a third
                party, there may be cookies or pixel tags localised by that
                website or app. Likewise, these third parties can locate cookies
                or pixel tags that are not subject to our control and whose use
                is not covered by the Brunotoken Privacy Policies.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              Users of the European Union
            </h2>
            <div className="privacy__text">
              <p>
                If you are a resident of the European Union, BRICS are
                responsible for the processing of your personal data. In this
                quality, these entities determine the purposes and means for the
                treatment of information
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">
              Legal Bases for the processing of personal data.
            </h2>
            <div className="privacy__text">
              <p>
                Our legal bases according to the EU GDPR are described above in
                the sections "how your information is used" and “Information
                about you that we obtain from third parties”. If you consent, we
                may process your information to comply with our legal
                obligations, obligations arising from the contracts registered
                with you, or to satisfy legitimate interests of Brunotoken
                Global, such as protecting our property, rights, security, our
                Users or others
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">Direct advertising</h2>
            <div className="privacy__text">
              <p>
                If you are a user residing in the EU, when it comes to
                advertising, we will contact you only by electronic channels. If
                you do not want us to use your information for this, please
                click "stop receiving our emails", located at the end of the
                email that we send you or contact us through
                info@brunotoken.com. The concept of advertising may include any
                communication addressed to you for the sole purpose of promoting
                products or services.
              </p>
            </div>
          </div>
          <div className="privacy__block">
            <h2 className="privacy__block-title">Individual rights</h2>
            <div className="privacy__text">
              <p>
                Residents of the EU have the following rights, which can be
                exerted through info@brunotoken.com com so that we can consider
                your application under the applicable law.
              </p>
              <p>
                1. Right to withdraw consent. You have the right to withdraw the
                consent for processing your personal data at any time. This
                withdrawal will not affect the legality of the previous data
                processing that Brunotoken has made. <br /> 2. Right to access
                and rectify your personal information. You have the right to
                request us to give you a copy of the personal information that
                we have about you. This information will be delivered as soon as
                possible, within a maximum period of one month, unless the
                delivery may affect the rights or the freedom of others. You can
                also request us to rectify or update your information if the one
                with Brunotoken is not updated. This right can only be limited
                in case your request is disproportionately expensive in the face
                of the risk of your privacy in the case in question, or when the
                rights of other people may be breached. <br /> 3. Right to be
                forgotten. You have the right to request the forgetting of your
                personal information that: (a) is no longer necessary for the
                purposes for which it was asked; (b) it was requested in
                relation to the consent that you already gave, but then you
                retracted; (c) it was used for activities that you objected to,
                because we had no basis to deal with it.
              </p>
              <p>
                If we have made your information public and we are forced to
                eliminate it, we will do so taking into consideration the
                available technology, the cost of implementation and what are
                the reasonable measures to inform third parties that you have
                requested to delete links, copies or reproductions of your
                personal information. Applicable data protection laws limit
                this.
              </p>
              <p>
                4. Right to data portability. If we process your personal
                information based on a contract with you or based on your
                consent, or the processing is programmed, you can request to
                receive your personal information in a structured format,
                commonly used and readable by machines, and ask us to transfer
                it directly to another entity responsible of the processing,
                unless portability affects the rights or the freedom of others.
                The person responsible for processing is the individual or legal
                entity that, alone or together with others, determines the
                purposes and means of processing your personal information.{" "}
                <br /> 5. Right to the restriction of processing of your
                personal data: you have the right to restrict or object to
                processing your personal information in the following cases:{" "}
                <br />
                a. You challenge the accuracy of the personal information we
                process. In this case, we will limit the processing during the
                period necessary to verify the accuracy of that data. <br /> b.
                The processing is against the law, but opposing the elimination
                of the information, you request the restriction of its use.{" "}
                <br /> c. Brunotoken no longer needs your personal information,
                but you request it to exert or defend your legal claims. <br />{" "}
                d. You have opposed the processing, while it lasts the
                verification of whether the legitimate reasons of Brunotoken
                invalidate or restrict your rights.
              </p>
              <p>
                6. Notification of erasure, rectification or restriction. We
                will notify you of any relevant modification, deletion or
                restriction of your information unless it is impossible or
                involves an incommensurate effort. <br /> 7. Right to object to
                processing. Because the processing of your data is based on your
                consent, in a contract or the legitimate interests of
                Brunotoken, you may restrict or object to it, at any time, as
                permitted by applicable law. We may continue to treat your
                information if necessary for arguments or legal claims, or for
                any other exception permitted by applicable law. <br /> 8.
                Automated decision-making, including profiling. You have the
                right not to be subject to decisions based solely on the
                automated processing of your personal data, including the
                elaboration of your profile, which produces legal or similar
                effects, excluding exceptions according to the applicable data
                protection laws.
              </p>
              <p>
                Right to claim. If you believe that we have breached your
                rights, you can contact us first through info@brunotoken.com
              </p>
              <p>
                9. , so that we try to solve the problem informally. You can
                also complain to the appropriate data protection authority.{" "}
                <br />
                10.The storage limitation of your personal information.
                Brunotoken will try to limit the storage of your personal
                information to whatever is necessary to achieve the purposes or
                aims for which the data is processed, to resolve disputes, to
                confirm our agreements, and as required or permitted by law.
              </p>
              <p>
                Data Protection Officer: You can contact the Data Protection
                Officer through info@brunotoken.com
              </p>
              <p>
                11. Finally, consider that your rights related to your personal
                data are not absolute. Access can be denied when: <br /> a.
                Denial of access is required or permitted by law; <br /> b.
                Allowing access has negative effects on the privacy of another
                person; <br /> c. Serve to protect our rights and property; and
                d. The request is excessive or unfounded.
              </p>
              <p>Contact us</p>
              <p>
                Should you have any queries, complaints, comments or suggestions
                regarding these Privacy Policies, your rights and obligations
                resulting from them, we invite you to contact us through
              </p>
              <p>info@brunotoken.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
