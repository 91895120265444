import { useMutation, useQuery } from "@tanstack/react-query";
import { authApi } from "../api/api";
import { IAvalanchePrices } from "../types/types";

const getAvalanchePrices = async () => {
  return authApi
    .get("/avalanche/prices")
    .then((res) => res.data as IAvalanchePrices);
};

const joinToAvalancheClub = async (data: { type: string; code: string }) => {
  return authApi.post("/avalanche", data).then((res) => res.data);
};

const getAvalancheCodes = async (level: string) => {
  return authApi.get(`/avalanche/${level}`).then((res) => res.data);
};

const getAvalancheParticipatedPlans = async () => {
  return authApi.get("/avalanche/user_list").then((res) => res.data);
};

export const useGetAvalanchePrices = () => {
  const {
    data: avalanchePrices,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["AvalanchePrices"],
    queryFn: getAvalanchePrices,
  });

  return {
    avalanchePrices,
    isLoading,
    isError,
  };
};

export const useJoinToAvalancheClub = (
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void
) => {
  return useMutation({
    mutationKey: ["JoinToAvalanche"],
    mutationFn: (data: { type: string; code: string }) =>
      joinToAvalancheClub(data),
    onSuccess,
    onError,
  });
};

export const useGetAvalancheCodes = (level: string) => {
  const {
    data: avalancheCode,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["AvalancheCode"],
    queryFn: () => getAvalancheCodes(level),
    enabled: !!level,
    retry: false,
  });

  return {
    avalancheCode,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetAvalancheParticipatedPlans = () => {
  const {
    data: avalancheParticipated,
    isLoading,
    isError,
    refetch: avalancheParticipatedRefetch,
  } = useQuery({
    queryKey: ["AvalancheParticipated"],
    queryFn: getAvalancheParticipatedPlans,
  });

  return {
    avalancheParticipated,
    isLoading,
    isError,
    avalancheParticipatedRefetch,
  };
};
