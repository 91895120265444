import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard'
import Avalanche from '../Avalanche/Avalanche '
import Exchange from '../Exchange/Exchange'
import Settings from '../Settings/Settings'
import Crowdfunding from '../Crowdfunding/Сrowdfunding'
import { useAuth } from '../../hooks/useAuth'

const User: FC = () => {

    const { user } = useAuth()
    const [hideSidebar, setHideSidebar] = useState(false)
    const [selectedPage, setSelectedPage] = useState<string>("dashboard")
    const pageNames = [
        "dashboard",
        "crowdfunding",
        "avalanche",
        "exchange",
        "settings",
    ]
    const pageDiscriptions : {[index: string] : string} = {
      dashboard: `We support: USDT TRON, USDT BSC, TRX, BNB, BTC, BRT`,
      crowdfunding: `This service is provided only in the BRT cryptocurrency. To participate in
            crowdfunding, the User must either already have BRT, or purchase it on the Exchange page, or exchange (SWAP)
            one of the other cryptocurrencies for BRT.`,
      avalanche: `Sapien elit malesuada nibh enim amet nisi. Diam sagittis nec odio mauris nisl rhoncus
            dis. Habitasse duis venenatis nisl viverra eu. Et id nunc mattis quam sapien egestas arcu. Tellus sed at
            eget cursus.`,
      exchange: `Sapien elit malesuada nibh enim amet nisi. Diam sagittis nec odio mauris nisl rhoncus
            dis. Habitasse duis venenatis nisl viverra eu. Et id nunc mattis quam sapien egestas arcu. Tellus sed at
            eget cursus.`,
      settings: ``,
    }

    const pages : {[index: string] : React.ReactNode} = {
      dashboard: <Dashboard/>,
      crowdfunding: <Crowdfunding/>,
      avalanche: <Avalanche/>,
      exchange: <Exchange/>,
      settings: <Settings/>,
    }
    

    const navigate = useNavigate()

    return (
        <div className="wrapper">

        <div className={`side js-side ${(hideSidebar) ? "minify" : ""}`}>
            <aside className="sidebar">
        <div className="sidebar__header">
        <div className="sidebar__logo-container">
            <a href="#" className="sidebar__logo"><img src="./img/logo.svg" alt="logo"/></a>
        </div>
        <button onClick={() => setHideSidebar(prev => !prev)} type="button" className="sidebar__header-btn js-side-toggle">
            <svg className="sidebar__header-btn-icon sidebar__header-btn-icon--left">
            <use xlinkHref="img/sprite.svg#arrow"></use>
            </svg>
            <svg className="sidebar__header-btn-icon">
            <use xlinkHref="img/sprite.svg#arrow"></use>
            </svg>
        </button>
        </div>
        <nav className="sidebar__nav nav">
        <ul className="nav__list">
            {
                pageNames.map(v => {
                  if (v !== "settings")
                    return (
                        <li className="nav__item">
                            <a style={{cursor: "pointer"}} onClick={() => setSelectedPage(v)} className={`nav__link ${selectedPage === v && "active"}`}>
                                <svg className="nav__link-icon">
                                <use xlinkHref={`img/sprite.svg#${v}`}></use>
                                </svg>
                                <p className="nav__link-text">{v[0].toUpperCase() + v.substr(1).toLowerCase() + ((v === "avalanche") ? " Club": "")}</p>
                                <svg className="nav__link-arrow">
                                <use xlinkHref="img/sprite.svg#arrow"></use>
                                </svg>
                            </a>
                        </li>
                    )
                })
            }
        </ul>
        <ul className="nav__list nav__list--settings">
            <li className="nav__item nav__item--account">
            <a style={{cursor: "pointer"}} onClick={() => setSelectedPage('settings')} className={`nav__link ${selectedPage === 'settings' && "active"}`}>
                <svg className="nav__link-icon">
                <use xlinkHref="img/sprite.svg#settings"></use>
                </svg>
                <p className="nav__link-text">Settings</p>
                <svg className="nav__link-arrow">
                <use xlinkHref="img/sprite.svg#arrow"></use>
                </svg>
            </a>
            </li>
        </ul>
        </nav>
        <div className="sidebar__socials">
                <ul className="socials">
            <li className="socials__item">
                <a href="#" className="socials__link">
                <svg className="socials__icon">
                    <use xlinkHref="img/sprite.svg#telegram"></use>
                </svg>
                </a>
            </li>
            <li className="socials__item">
                <a href="#" className="socials__link">
                <svg className="socials__icon">
                    <use xlinkHref="img/sprite.svg#medium"></use>
                </svg>
                </a>
            </li>
            <li className="socials__item">
                <a href="#" className="socials__link">
                <svg className="socials__icon">
                    <use xlinkHref="img/sprite.svg#reddit"></use>
                </svg>
                </a>
            </li>
            <li className="socials__item">
                <a href="#" className="socials__link">
                <svg className="socials__icon">
                    <use xlinkHref="img/sprite.svg#linkedin"></use>
                </svg>
                </a>
            </li>
            <li className="socials__item">
                <a href="#" className="socials__link">
                <svg className="socials__icon">
                    <use xlinkHref="img/sprite.svg#twitter"></use>
                </svg>
                </a>
            </li>
            <li className="socials__item">
                <a href="#" className="socials__link">
                <svg className="socials__icon">
                    <use xlinkHref="img/sprite.svg#crunchbase"></use>
                </svg>
                </a>
            </li>
            <li className="socials__item">
                <a href="#" className="socials__link">
                <svg className="socials__icon">
                    <use xlinkHref="img/sprite.svg#discord"></use>
                </svg>
                </a>
            </li>
            </ul>

        </div>
            </aside>
        </div>

        <div className={`page js-main ${hideSidebar ? "wide" : ""}`}>
        <header className="header">
            <a href="#" className="header__logo"><img src="./img/logo.svg" alt="logo"/></a>
            <div className="header__text-block">
            <h1 className="header__title">{selectedPage[0].toUpperCase() + selectedPage.substr(1).toLowerCase() + ((selectedPage === "avalanche") ? " Club": "")}</h1>
            <p className="header__text">{pageDiscriptions[selectedPage]}</p>
            </div>

            <div className="header__actions">
            <button type="button" className="header__notification notification">
                <svg className="notification__icon">
                <use xlinkHref="img/sprite.svg#notification"></use>
                </svg>
            </button>
            <button type="button" className="header__currency-selector currency-selector">
                <span className="currency-selector__text">
                BRT
                </span>
                <svg className="currency-selector__icon">
                <use xlinkHref="img/sprite.svg#arrow"></use>
                </svg>
            </button>
            <button type="button" className="header__profile profile">
                <div className="profile__user">
                <div className="profile__ava">
                    <img className="profile__img" src="./img/person.png" alt="ava"/>
                </div>
                </div>
                <div className="profile__info">
                <div className="profile__name">{user?.full_name}</div>
                <div className="profile__settings">Account settings</div>
                </div>
                <svg className="profile__icon">
                <use xlinkHref="img/sprite.svg#arrow"></use>
                </svg>
            </button>
            </div>
        </header>

        {pages[selectedPage]}
         
        
        </div>
    </div>
    )
}

export default User