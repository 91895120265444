import { authApi } from "../api/api";
import { useQuery } from "@tanstack/react-query";

interface SecurityLogParams {
  filter?:
    | "UNUSUAL_IP_LOGIN"
    | "MANY_LOGIN_ATTEMPTS"
    | "REQUEST_LIMIT_EXCEED"
    | "DDOS"
    | "XSS"
    | "SQL_INJECTION"
    | "SYSTEM_ERRORS";
  limit?: number;
  offset?: string;
  search?: string;
}

const getSecurityLogs = async (params: SecurityLogParams) => {
  const response = await authApi.get("/security", { params });
  return response.data;
};

export const useGetSecurityLogs = (params: SecurityLogParams) => {
  return useQuery({
    queryKey: ["securityLogs", params],
    queryFn: () => getSecurityLogs(params),
  });
};
