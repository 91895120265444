import React, { FC, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard'
import Avalanche from '../Avalanche/Avalanche '
import Exchange from '../Exchange/Exchange'
import Settings from '../Settings/Settings'
import Crowdfunding from '../Crowdfunding/Сrowdfunding'
import { useAuth } from '../../hooks/useAuth'
import User from './User'
import Admin from './Admin'

const Main: FC = () => {

    const { user } = useAuth()
    
    if (user?.role === "SUPERADMIN" || user?.role === "ADIMIN") {
        return <Admin/>
    } else if (user?.role === "USER")  {
        return <User/>
    }

    return <></>
}

export default Main