import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { IBalances, IStatistics } from "../../../types/types";
import StatisticsChart from "../../../components/StatisticsChart";

interface IProps {
  balances: IBalances;
  statistics: IStatistics;
  statisticsRange: [Date | null, Date | null];
  setStatisticsRange: React.Dispatch<
    React.SetStateAction<[Date | null, Date | null]>
  >;
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>;
}

const Analytics: React.FC<IProps> = ({
  balances,
  statistics,
  statisticsRange,
  setStatisticsRange,
  setSelectedPage,
}) => {
  const RangeDateInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button
      onClick={onClick}
      ref={ref}
      className="chart-section__calendar calendar"
    >
      <img className="calendar__icon" src="./img/svg/date.svg" alt="" />
      <span className="calendar__date">{value}</span>
      <svg className="calendar__arrow">
        <use xlinkHref="img/sprite.svg#arrow"></use>
      </svg>
    </button>
  ));

  return (
    <div className="dashboard-analytics__main">
      <div className="dashboard-analytics__chart chart-section">
        <div className="chart-section__header">
          <div className="chart-section__total">
            <h2 className="chart-section__title">
              <svg className="chart-section__title-icon">
                <use xlinkHref="img/sprite.svg#chart"></use>
              </svg>
              Total revenue
            </h2>
            <div className="chart-section__amount amount">
              <div className="amount__text">${statistics?.total_revenue}</div>
              {/* <div className="amount__change amount__change--up">
                <span className="amount__change-amount">28.4%</span>
                <svg className="amount__change-icon">
                  <use xlinkHref="img/sprite.svg#arrow-change"></use>
                </svg>
              </div> */}
            </div>
          </div>
          <div className="chart-section__legend">
            <span className="chart-section__legend-item chart-section__legend-item--revenue">
              Revenue
            </span>
            <span className="chart-section__legend-item chart-section__legend-item--expenses">
              Expenses
            </span>
            <DatePicker
              selected={statisticsRange[0]}
              onChange={(update) => setStatisticsRange(update)}
              startDate={statisticsRange[0]!}
              endDate={statisticsRange[1]!}
              selectsRange
              dateFormat="MMM yyyy"
              showMonthYearPicker
              customInput={<RangeDateInput />}
            />
          </div>
        </div>
        <div className="chart-section__graph">
          {/* <div className="chart-section__point amount-point">
            <div className="amount-point__top">
              <div className="amount-point__text">$240.8K</div>
              <div className="amount-point__change amount-point__change--up">
                <span className="amount-point__change-amount">12.5%</span>
                <svg className="amount-point__change-icon">
                  <use xlinkHref="img/sprite.svg#arrow-change"></use>
                </svg>
              </div>
            </div>
            <span className="amount-point__date">Oct 29, 2024</span>
          </div> */}
          {/* <img src="./img/graph.png" alt="" /> */}
          {Object.keys(statistics?.expenses || {}) ||
          Object.keys(statistics?.revenue || {}) ? (
            <StatisticsChart analytics={statistics} />
          ) : (
            <div className="chart-section__graph-empty"></div>
          )}
        </div>
      </div>
      <div className="dashboard-analytics__wallet wallet-actions">
        <div className="wallet-actions__buttons">
          <button
            type="button"
            className="wallet-actions__button button"
            onClick={() => setSelectedPage("send")}
          >
            Send
          </button>
          <button
            type="button"
            className="wallet-actions__button button button--blue"
            onClick={() => setSelectedPage("recieve")}
          >
            Receive
          </button>
          <button
            type="button"
            className="wallet-actions__button button button--blue"
            onClick={() => setSelectedPage("swap")}
          >
            Swap
          </button>
        </div>
        <div className="wallet-actions__assets assets">
          <h2 className="assets__title">My actives</h2>
          <ul className="assets__items">
            {balances &&
              Object.entries(balances).map(([network, tokens]) => {
                return tokens?.map((v, i) => {
                  let svg = v.full_name.toLowerCase();
                  if (svg.includes("tether")) svg = svg.replace(" usd", "");

                  // Count how many networks have this token symbol
                  const networksWithToken = Object.entries(balances).filter(
                    ([_, networkTokens]) =>
                      networkTokens?.some((token) => token.symbol === v.symbol)
                  ).length;

                  return (
                    <li className="assets__item" key={i}>
                      <div className="assets__item-crypto">
                        <div className="assets__item-crypto-logo">
                          <img
                            src={`./img/cryptocurrency/${svg}.svg`}
                            alt=""
                            className="assets__item-crypto-img"
                          />
                        </div>
                        <div className="assets__item-crypto-info">
                          <span className="assets__item-crypto-name">
                            {v.full_name}
                            {networksWithToken > 1 &&
                              ` (${network.toUpperCase()})`}
                          </span>
                          <span className="assets__item-crypto-amount">
                            ${v.price}
                          </span>
                        </div>
                      </div>
                      <div className="assets__item-amount amount-assets">
                        <div className="amount-assets__text">
                          {v.balance % 1 === 0
                            ? v.balance
                            : v.balance.toFixed(
                                Math.min(
                                  6,
                                  (v.balance.toString().split(".")[1] || "")
                                    .length
                                )
                              )}{" "}
                          <span className="amount-assets__currency">
                            {v.symbol}
                          </span>
                        </div>
                        {v.symbol !== "USDT" && v.symbol !== "BRT" && (
                          <div
                            className={`amount-assets__change amount-assets__change--${
                              v.price_24h_pcnt > 0 ? "up" : "down"
                            }`}
                          >
                            <span className="amount-assets__change-amount">
                              {v.price_24h_pcnt}
                            </span>
                            <svg className="amount-assets__change-icon">
                              <use xlinkHref="img/sprite.svg#arrow-change"></use>
                            </svg>
                          </div>
                        )}
                      </div>
                    </li>
                  );
                });
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
