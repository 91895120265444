/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
// import Content from "../Content/Content";
import Fees from "../Fees/Fees";
import SecurityLogs from "../SecurityLogs/SecurityLogs";
// import Support from "../Support/Support";
import Users from "../Users/Users";
import Partners from "../Partners";
import { useNavigate } from "react-router-dom";

const Admin: FC = () => {
  const { logout } = useAuth();
  const [hideSidebar, setHideSidebar] = useState(false);
  const [selectedPage, setSelectedPage] = useState<string>("users");
  const navigate = useNavigate();
  const pageNames = [
    "users",
    // "content",
    // "support",
    "security logs",
    "fees",
    "partners",
  ];

  const pages: { [index: string]: React.ReactNode } = {
    users: <Users />,
    // content: <Content />,
    // support: <Support />,
    "security logs": <SecurityLogs />,
    fees: <Fees />,
    partners: <Partners />,
  };

  const pageSvg: { [index: string]: React.ReactNode } = {
    users: "avalanche",
    // content: "crowdfunding",
    // support: "support",
    "security logs": "password",
    fees: "exchange",
    partners: "partners",
  };

  const pageTitle: { [index: string]: React.ReactNode } = {
    users: "Users",
    // content: "Content",
    // support: "Help & supporting",
    "security logs": "Security Logs",
    fees: "Fees",
    partners: "Partners",
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="wrapper">
      <div className={`side js-side ${hideSidebar ? "minify" : ""}`}>
        <aside className="sidebar">
          <div className="sidebar__header">
            <div className="sidebar__logo-container">
              <div className="sidebar__logo">
                <img src="./img/logo.svg" alt="logo" />
              </div>
            </div>
            <button
              onClick={() => setHideSidebar((prev) => !prev)}
              type="button"
              className="sidebar__header-btn js-side-toggle"
            >
              <svg className="sidebar__header-btn-icon sidebar__header-btn-icon--left">
                <use xlinkHref="img/sprite.svg#arrow"></use>
              </svg>
              <svg className="sidebar__header-btn-icon">
                <use xlinkHref="img/sprite.svg#arrow"></use>
              </svg>
            </button>
          </div>

          <nav className="sidebar__nav nav">
            <ul className="nav__list">
              {pageNames.map((v, i) => {
                return (
                  <li className="nav__item" key={i}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedPage(v)}
                      className={`nav__link ${selectedPage === v && "active"}`}
                    >
                      <svg className="nav__link-icon">
                        <use xlinkHref={`img/sprite.svg#${pageSvg[v]}`}></use>
                      </svg>
                      <p className="nav__link-text">
                        {v[0].toUpperCase() + v.substr(1).toLowerCase()}
                      </p>
                      <svg className="nav__link-arrow">
                        <use xlinkHref="img/sprite.svg#arrow"></use>
                      </svg>
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </aside>
      </div>

      <div className={`page js-main ${hideSidebar ? "wide" : ""}`}>
        <header className="header">
          <h1 className="header__title">{pageTitle[selectedPage]}</h1>

          <button onClick={handleLogout} className="admin-logout">
            Logout
          </button>
        </header>
        <main className="main ">{pages[selectedPage]}</main>
      </div>
    </div>
  );
};

export default Admin;
